export const getBillingPeriod = (date = new Date()) => {
    const month = `${date.getMonth() + 1}`;
    const formattedMonth = month.length > 1 ? month : `0${month}`;
    return Number(`${date.getFullYear()}${formattedMonth}`);
};

export const getBillingPeriods = (date = new Date()) => {
    const billingPeriods: number[] = [];
    for (let i = 0; i > -12; i--) {
        const past = new Date(date.getFullYear(), date.getMonth() + i, 1);

        const month = `${past.getMonth() + 1}`;
        const formattedMonth = month.length > 1 ? month : `0${month}`;

        const period = Number(`${past.getFullYear()}${formattedMonth}`);
        billingPeriods.unshift(period);
    }
    return billingPeriods;
};

export const formatBillingPeriod = (billingPeriod: number) => {
    const period = billingPeriod.toString();
    const year = period.substring(0, period.length - 2);
    const month = period.substring(period.length - 2);
    const monthNames: Record<string, string> = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December",
    };

    return monthNames[month] + " " + year;
};
