import { useMemo } from "react";
import LightSection from "../page-sections/LightSection";
import { Col, Row } from "react-bootstrap";
import EnrollmentStepper from "./EnrollmentStepper";
import DarkSection from "../page-sections/DarkSection";
import { useMemberEnrollments } from "../../contexts/memberEnrollments/MemberEnrollmentProvider";
import PersonalInformation from "./PersonalInformation";
import Dependent from "./Dependent";
import { Coverage } from "./Coverage";
import Review from "./Review";
import React from "react";
import Confirmation from "./Confirmation";

export default function MemberEnrollment() {
    const { activeStep } = useMemberEnrollments();

    const currentStage = useMemo(() => {
        switch (activeStep) {
            case 0:
                return <PersonalInformation />;
            case 1:
                return <Dependent />;
            case 2:
                return <Coverage />;
            case 3:
                return <Review />;
            case 4:
                return <Confirmation />;
            default:
                return <></>;
        }
    }, [activeStep]);

    return (
        <>
            <LightSection>
                <Row>
                    <Col xs="10">
                        <EnrollmentStepper />
                    </Col>
                </Row>
            </LightSection>
            <DarkSection>
                <Row xs="10">{currentStage}</Row>
            </DarkSection>
        </>
    );
}
