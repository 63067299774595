export type Accumulator = {
    groupNumber: string;
    participantIDNumber: string;
    dependentSequenceNumber: number;
    accumulatorDateYear: number;
    accumulatorType: string;
    accumulatorNumber: number;
    codeDescription: string;
    accumulatorAmt: number;
    accumulationType: string;
    accumulatorProduct: string;
    lastServiceDate: string | null;
    refreshDate: string;
};

export type AccumulatorRequest = {
    accountNumber: number;
    groupNumber: string;
    participantIDNumber: string;
};

export type AccumulatorRef = Omit<Accumulator, "groupNumber" | "participantIDNumber" | "dependentSequenceNumber">;

export type MemberAccumulator = {
    dependentSequenceNumber: number;
    firstName: string;
    groupNumber: string;
    lastName: string;
    middleName: string;
    participantIDNumber: string;
    accumulators: AccumulatorRef[];
};

export enum AccumulatorNumbers {
    PlanYearAllowance = 10,
    PlanYearDeductible = 20,
    DentalExams = 30,
    Cleanings = 40,
    XRayBitewing = 50,
    XRayPano = 60,
    Fluoride = 70,
    VisionExams = 150,
    Frames = 160,
    Lenses = 170,
    Contacts = 180,
    OrthoLifetimeLimit = 300,
}
