/**
 * The different types of permissions that can be assigned to a group admin user.
 * This should match the values in the database.
 * @enum {number}
 * Reference the .HasData() in the UsersContext.cs file for the database seed data.
 */
export enum Permission {
    Access = 1,
    Enrollment = 2,
}
