import { inject, injectable } from "inversify";
import { Store } from "redux";
import { createPatch } from "rfc6902";
import { showToast } from "../../actions/ToastActionCreators";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { EDIT_SUB_REPORT_ATTRIBUTE_SUCCESS } from "../../constants/ToastConstants";
import { TYPES } from "../../dependencyInjection/Types";
import { SubReportAttribute } from "../../models/SubReportAttribute";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ISubReportAttributeService } from "./ISubReportAttributeService";

@injectable()
export class SubReportAttributeService implements ISubReportAttributeService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getSubReportAttributes(subReportId: number): Promise<void | SubReportAttribute[]> {
        return this.httpClient
            .get<SubReportAttribute[]>(`/sub-reports/${subReportId}/sub-report-attributes`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving subReport attributes. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    createSubReportAttribute(
        subReportId: number,
        subReportAttribute: SubReportAttribute
    ): Promise<void | SubReportAttribute> {
        return this.httpClient
            .post<SubReportAttribute>(`/sub-reports/${subReportId}/sub-report-attributes`, subReportAttribute)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a subReport attribute. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editSubReportAttribute(
        subReportId: number,
        attribute: SubReportAttribute,
        newAttribute: SubReportAttribute
    ): Promise<void | SubReportAttribute> {
        const operations = createPatch(attribute, newAttribute);
        return this.httpClient
            .patch<SubReportAttribute>(`/sub-reports/${subReportId}/sub-report-attributes/${attribute.id}`, operations)
            .then((patchedAttribute) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_SUB_REPORT_ATTRIBUTE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Sub-Report Attribute "${patchedAttribute.id}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedAttribute;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing sub-report attribute "${attribute.id}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteSubReportAttribute(
        subReportId: number,
        subReportAttribute: SubReportAttribute
    ): Promise<void | SubReportAttribute> {
        return this.httpClient
            .delete(`/sub-reports/${subReportId}/sub-report-attributes/${subReportAttribute.id}`)
            .then(() => {
                const returnSubReportAttribute = { ...subReportAttribute, isActive: !subReportAttribute.isActive };
                return returnSubReportAttribute;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the subReport. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
