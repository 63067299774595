import { SubReportAttribute } from "../../models/SubReportAttribute";

export const SUB_REPORT_ATTRIBUTES_FETCHED = "SUB_REPORT_ATTRIBUTES_FETCHED";
export const CREATE_SUB_REPORT_ATTRIBUTE = "CREATE_SUB_REPORT_ATTRIBUTE";
export const DELETE_SUB_REPORT_ATTRIBUTE = "DELETE_SUB_REPORT_ATTRIBUTE";
export const EDIT_SUB_REPORT_ATTRIBUTE = "EDIT_SUB_REPORT_ATTRIBUTE";

export interface SubReportAttributesFetchedAction {
    type: typeof SUB_REPORT_ATTRIBUTES_FETCHED;
    subReportAttributes: SubReportAttribute[];
}

export interface CreateSubReportAttributeAction {
    type: typeof CREATE_SUB_REPORT_ATTRIBUTE;
    subReportAttribute: SubReportAttribute;
}

export interface DeleteSubReportAttributeAction {
    type: typeof DELETE_SUB_REPORT_ATTRIBUTE;
    subReportAttribute: SubReportAttribute;
}

export interface EditSubReportAttributeAction {
    type: typeof EDIT_SUB_REPORT_ATTRIBUTE;
    originalAttribute: SubReportAttribute;
    newAttribute: SubReportAttribute;
}

export type SubReportAttributeActions =
    | SubReportAttributesFetchedAction
    | CreateSubReportAttributeAction
    | DeleteSubReportAttributeAction
    | EditSubReportAttributeAction;
