import { inject, injectable } from "inversify";
import { ICustomerReportService } from "./ICustomerReportService";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Store } from "redux";
import { CustomerReport } from "../../models/CustomerReport";
import {
    ICustomerReportReview,
    CustomerReportReviewCreate,
    CustomerReportReviewCreateBulk,
} from "../../models/CustomerReportReview";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ShowToastAction } from "../../actions/ToastActions";
import { showToast } from "../../actions/ToastActionCreators";
import { createPatch } from "rfc6902";
import {
    ADD_CUSTOMER_REPORT_REVIEW_SUCCESS,
    EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS,
    EDIT_CUSTOMER_REPORT_SUCCESS,
} from "../../actions/customerReport/CustomerReportAction";

@injectable()
export class CustomerReportService implements ICustomerReportService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getCustomerReportsByCustomerImportId(customerImportId: number): Promise<void | CustomerReport[]> {
        return this.httpClient
            .get<CustomerReport[]>(`/customer-report/customer-import/${customerImportId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customer reports. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editCustomerReport(customerReport: CustomerReport, newCustomerReport: CustomerReport): Promise<any> {
        const operations = createPatch(customerReport, newCustomerReport);
        return this.httpClient
            .patch<CustomerReport>(`/customer-report/${customerReport.id}`, operations)
            .then((patchedCustomerReport) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_CUSTOMER_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Customer Report "${patchedCustomerReport.id}" completed successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedCustomerReport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing Customer Report "${customerReport.id}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    getCustomerReportReviews(reportId: number): Promise<void | ICustomerReportReview[]> {
        return this.httpClient
            .get<ICustomerReportReview[]>(`/customer-report-review/customer-report/${reportId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customer reports. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getAllCustomerReportReviews(): Promise<void | ICustomerReportReview[]> {
        return this.httpClient
            .get<ICustomerReportReview[]>(`/customer-report-review`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customer report reviews. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    createCustomerReportReview(customerReportReview: CustomerReportReviewCreate): Promise<any> {
        return this.httpClient
            .post<CustomerReportReviewCreate>("/customer-report-review", customerReportReview)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_CUSTOMER_REPORT_REVIEW_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Report Review "${t.id}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a Report Review. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    bulkCreateCustomerReportReviews(bulkRequest: CustomerReportReviewCreateBulk): Promise<void> {
        return this.httpClient
            .post<CustomerReportReviewCreateBulk>("/customer-report-review/bulk", bulkRequest)
            .then(() => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Report Reviews updated successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while updating the Report Reviews. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editCustomerReportReview(
        customerReportReview: ICustomerReportReview,
        newCustomerReportReview: ICustomerReportReview
    ): Promise<any> {
        const operations = createPatch(customerReportReview, newCustomerReportReview);
        return this.httpClient
            .patch<ICustomerReportReview>(`/customer-report-review/${customerReportReview.id}`, operations)
            .then((patchedCustomerImport) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Customer Report Review "${patchedCustomerImport.id}" completed successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedCustomerImport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing customerReportReview "${customerReportReview.id}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }
}
