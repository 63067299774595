import { PropsWithChildren, createContext, useCallback, useContext, useState } from "react";
import { KeysysToastProps } from "../components/toast/KeysysToastProps";

interface IToastContext {
    activeToasts: KeysysToastProps[];
    toast(toast: KeysysToastProps): void;
    hide(name: string): void;
}

const ToastContext = createContext<IToastContext>({ activeToasts: [], toast: () => {}, hide: () => {} });

export const ToastProvider = ({ children }: PropsWithChildren<any>) => {
    const [activeToasts, setActiveToasts] = useState<KeysysToastProps[]>([]);

    const toast = useCallback((toast: KeysysToastProps) => {
        setActiveToasts((prev) => [...prev, toast]);
    }, []);

    const hide = useCallback((name: string) => {
        setActiveToasts((prev) => [...prev.filter((t) => t.name !== name)]);
    }, []);

    return <ToastContext.Provider value={{ activeToasts, toast, hide }}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
    return useContext(ToastContext);
};
