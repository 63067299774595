import { useState, useEffect } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { LoadingSpinner } from "../loading/Loading";
import LightSection from "../page-sections/LightSection";
import DarkSection from "../page-sections/DarkSection";
import EnrollmentInformationView from "./EnrollmentInformation";
import CoverageInformationView from "./CoverageInformation";
import { useUsers } from "../../contexts/users";
import { useMembers } from "../../contexts/members";
import { useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { PretreatmentEstimate } from "./PretreatmentEstimate";
import { AccumulatorInformation } from "./AccumulatorInformation";

export default function MyInsurance() {
    const {
        userState: { loggedInUser },
    } = useUsers();

    const { isLoadingMemberInfo, isMember, member, searchById } = useMembers();

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant } = useCurrentTenant(decodedAccessToken.tenant_id);

    const [groupInput, setGroupInput] = useState<string>("");
    const [accountInput, setAccountInput] = useState<string>("");
    const [memberIdInput, setMemberIdInput] = useState<string>("");

    const [bookletLink, setBookletLink] = useState<string | null>(null);

    const updatedSelectedMember = () => {
        searchById({
            groupNumber: groupInput,
            participantIdNumber: memberIdInput,
            providerType: currentTenant?.providerType,
        });
    };

    useEffect(() => {
        if (loggedInUser && isMember) {
            setGroupInput(loggedInUser.groupNumber || "");
            setAccountInput(loggedInUser.accountNumber?.toString() || "");
            setMemberIdInput(loggedInUser.participantIdNumber || "");
            if (loggedInUser.groupNumber === "1000" || loggedInUser.groupNumber === "0000001000") {
                setBookletLink(
                    "https://southland-keysys.azurewebsites.net/wp-content/uploads/2022/04/PEEHIP-Plans-Booklet.pdf"
                );
            }

            if (loggedInUser.groupNumber === "2000" || loggedInUser.groupNumber === "0000002000") {
                setBookletLink(
                    "https://southland-keysys.azurewebsites.net/wp-content/uploads/2022/04/SEIB-Optional-Plans.pdf"
                );
            }

            if (loggedInUser.groupNumber === "2500" || loggedInUser.groupNumber === "0000002500") {
                setBookletLink(
                    "https://southland-keysys.azurewebsites.net/wp-content/uploads/2022/04/LGHIP-Plans-Booklet.pdf"
                );
            }
        }
    }, [loggedInUser, isMember]);

    useEffect(() => {
        if (member && !isMember) {
            setGroupInput(member.groupNumber || "");
            setAccountInput(member.accountNumber?.toString() || "");
            setMemberIdInput(member.participantIDNumber || "");
        }
    }, [member, isMember]);

    if (isLoadingMemberInfo) {
        return <LoadingSpinner />;
    }
    if (loggedInUser) {
        return (
            <>
                <DarkSection>
                    <Form>
                        <Row className="justify-content-md-center">
                            <Col xl={3} md={3}>
                                <Form.Group controlId="group">
                                    <Form.Label>Group</Form.Label>
                                    <Form.Control
                                        readOnly={isMember}
                                        value={groupInput}
                                        onChange={(e) => setGroupInput(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl={3} md={3}>
                                <Form.Group controlId="account">
                                    <Form.Label>Account</Form.Label>
                                    <Form.Control
                                        readOnly={isMember}
                                        value={accountInput}
                                        onChange={(e) => setAccountInput(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl={3} md={3}>
                                <Form.Group controlId="memberId">
                                    <Form.Label>Contract Number</Form.Label>
                                    <Form.Control
                                        readOnly={isMember}
                                        value={memberIdInput}
                                        onChange={(e) => setMemberIdInput(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            {bookletLink && (
                                <Col xl={3} md={3} style={{ alignSelf: "flex-end" }}>
                                    <a href={bookletLink} target="_blank" rel="noreferrer">
                                        <Button>Download Plan Booklet</Button>
                                    </a>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            {!isMember && (
                                <Col className="text-end mt-3">
                                    <Button variant="primary" onClick={updatedSelectedMember}>
                                        Retrieve
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </DarkSection>
                <LightSection>
                    <EnrollmentInformationView />
                    {/* <PolicyInformation /> */}
                    <CoverageInformationView />
                    <PretreatmentEstimate />
                    <AccumulatorInformation />
                </LightSection>
            </>
        );
    }

    return null;
}
