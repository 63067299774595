import { CSSProperties, useCallback, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { SSN_MAX_LENGTH } from "../../../constants/ssn";
import { formatSSN, maskSSN } from "../../../helper-functions/ssn";
import { ErrorMessage, Field, FieldProps, FieldInputProps } from "formik";
import ErrorMessageText from "../error-message/ErrorMessageText";
import { KeysysInputProps } from "./KeysysInputProps";
import { nullProperties, redBorder } from "../../../cssProperties/CssProperties";

export interface SSNInputProps {
    id: string;
    placeholder?: string;
    label: string;
    type?: string;
    disabled?: boolean;
    required?: boolean;
    masked?: boolean;
    style?: CSSProperties;
    fieldProps?: FieldInputProps<any>;
    onChange(value: string): void;
}

export const SSNInputGroup = (props: SSNInputProps) => {
    const { fieldProps, masked, onChange, ...inputProps } = props;
    const { id, label, required } = inputProps;

    const [isMasked, setIsMasked] = useState(masked ?? true);
    const [rawValue, setRawValue] = useState(fieldProps?.value ?? "");

    const handleChange = useCallback(
        (value: string) => {
            const numValue = value.replace(/\D/g, "");
            setRawValue(numValue);
            onChange(numValue);
        },
        [onChange]
    );

    return (
        <Form.Group>
            <Form.Label htmlFor={id}>
                {label}
                {required && <span className="text-danger"> *</span>}
            </Form.Label>
            <InputGroup>
                <Form.Control
                    {...fieldProps}
                    {...inputProps}
                    value={isMasked ? maskSSN(rawValue) : formatSSN(rawValue)}
                    onChange={(e) => handleChange(e.target.value)}
                    onFocus={() => setIsMasked(false)}
                    onBlur={() => setIsMasked(true)}
                    autoComplete="off"
                    maxLength={SSN_MAX_LENGTH}
                />
                <Button
                    title={isMasked ? "Show" : "Hide"}
                    onClick={() => setIsMasked((prev) => !prev)}
                    variant="outline-secondary"
                >
                    <i className={isMasked ? "far fa-eye" : "far fa-eye-slash"} />
                </Button>
            </InputGroup>
        </Form.Group>
    );
};

export const SSNField = (props: KeysysInputProps) => {
    const { fieldName, formProps, ...rest } = props;
    return (
        <>
            <Field name={fieldName}>
                {({ field }: FieldProps<any>) => (
                    <SSNInputGroup
                        fieldProps={field}
                        {...rest}
                        style={formProps.errors[fieldName] && formProps.touched[fieldName] ? redBorder : nullProperties}
                        onChange={(value) => formProps.setFieldValue(fieldName, value)}
                    />
                )}
            </Field>
            <ErrorMessage name={props.fieldName}>{(msg) => <ErrorMessageText message={msg} />}</ErrorMessage>
        </>
    );
};
