import { inject, injectable } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { FinosecSystemStatistic } from "../../models/FinosecSystemStatistic";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IFinosecSystemStatisticService } from "./IFinosecSystemStatisticService";

@injectable()
export class FinosecSystemStatisticService implements IFinosecSystemStatisticService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getFinosecSystemStatisticsByTenantId(tenantId: Number): Promise<void | FinosecSystemStatistic> {
        return this.httpClient
            .get<FinosecSystemStatistic>(`/finosec-system-statistics/tenant/` + tenantId)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving system statistics. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getFinosecSystemStatisticsByImportId(importId: Number): Promise<void | FinosecSystemStatistic> {
        return this.httpClient
            .get<FinosecSystemStatistic>(`/finosec-system-statistics/customer-Import/` + importId)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving system statistics. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
