import { Table as ReactTable } from "@tanstack/react-table";
import React from "react";
import { Button, Form } from "react-bootstrap";

interface DataTablePaginationProps {
    table: ReactTable<any>;
}

export default function DataTablePagination({ table }: DataTablePaginationProps) {
    const {
        setPageSize,
        setPageIndex,
        getCanPreviousPage,
        previousPage,
        nextPage,
        getState,
        getPageCount,
        getCanNextPage,
    } = table;

    return (
        <div className="d-flex justify-content-end align-items-center">
            {getPageCount() > 1 && (
                <>
                    <Button
                        variant="outline-secondary"
                        className="mx-1"
                        onClick={() => setPageIndex(0)}
                        disabled={!getCanPreviousPage()}
                    >
                        <i className="fas fa-angle-double-left" />
                    </Button>
                    <Button
                        variant="outline-secondary"
                        className="mx-1"
                        onClick={() => previousPage()}
                        disabled={!getCanPreviousPage()}
                    >
                        <i className="fas fa-angle-left" />
                    </Button>
                    <Button
                        variant="outline-secondary"
                        className="mx-1"
                        onClick={() => nextPage()}
                        disabled={!getCanNextPage()}
                    >
                        <i className="fas fa-angle-right" />
                    </Button>
                    <Button
                        variant="outline-secondary"
                        className="mx-1"
                        onClick={() => setPageIndex(getPageCount() - 1)}
                        disabled={!getCanNextPage()}
                    >
                        <i className="fas fa-angle-double-right" />
                    </Button>
                    <span>
                        {getState().pagination.pageIndex + 1}/{getPageCount()}
                    </span>
                    {/* <span>
                    | Go to page:
                    <input
                        type="number"
                        defaultValue={getState().pagination.pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            setPageIndex(page);
                        }}
                    />
                </span> */}
                </>
            )}
            <Form.Select
                value={getState().pagination.pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
                style={{ maxWidth: "120px" }}
                className="ms-2"
            >
                {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
}
