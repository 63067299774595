import { Identifiable } from "./Identifiable";

export interface Role extends Identifiable {
    name: string;
    isActive: boolean;
}

export enum Roles {
    GlobalAdmin = 1,
    PartnerAdmin = 2,
    CustomerAdmin = 3,
    User = 4,
    SuperAdmin = 5,
    GroupAdmin = 6,
}
