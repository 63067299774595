import {
    FinosecSystemStatisticAction,
    FINOSEC_SYSTEM_STATISTIC_FETCHED,
} from "../../actions/finosecSystemStatistic/FinosecSystemStatisticAction";
import { FinosecSystemStatisticState } from "../../state/FinosecSystemStatisticState";

const initialState: FinosecSystemStatisticState = {
    finosecSystemStatistics: {
        finosecSystemId: null,
        tenantId: null,
        importId: null,
        totalUserChanges: 0,
        totalSubReports: 0,
        totalReviewedReports: 0,
        totalNotReviewedReports: 0,
        statiticsDate: null,
    },
};

export function finosecSystemStatisticReducer(
    state: FinosecSystemStatisticState = initialState,
    action: FinosecSystemStatisticAction
): FinosecSystemStatisticState {
    switch (action.type) {
        case FINOSEC_SYSTEM_STATISTIC_FETCHED:
            return {
                ...state,
                finosecSystemStatistics: action.finosecSystemStatistic,
            };

        default:
            return state;
    }
}
