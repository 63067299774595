import "core-js/stable";
import "core-js/features/map";
import "reflect-metadata";
import "regenerator-runtime/runtime";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import "./scss/main.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import dotenv from "dotenv";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { HttpClient } from "./services/HttpClient/HttpClient";
import request from "request-promise-native";
import { DependencyInjectionProvider } from "./dependencyInjection/DependencyInjectionProvider";
import { Store } from "redux";
import { TYPES } from "./dependencyInjection/Types";
import { servicesContainer } from "./services/IOC/ServicesContainer";
import { DefaultExceptionHandler } from "./services/exceptionHandlers/DefaultExceptionHandler";
import { IExceptionHandler } from "./services/exceptionHandlers/IExceptionHandler";
import { TabService } from "./services/TabService";
import { SubtabService } from "./services/SubtabService";
import { createBrowserHistory } from "history";
import SignInOidc from "./components/sign-in/SignInOidc";
import { TenantService } from "./services/tenant/TenantService";
import { ReportAttributeService } from "./services/reportAttribute/ReportAttributeService";
import { ReportService } from "./services/report/ReportService";
import { SubReportService } from "./services/subReport/SubReportService";
import { SubReportAttributeService } from "./services/subReportAttribute/SubReportAttributeService";
import { AwsFileService } from "./services/aws/AwsFileService";
import { FinosecSystemService } from "./services/finosecSystemService/FinosecSystemService";
import { FinosecSystemStatisticService } from "./services/finosecSystemStatisticService/FinosecSystemStatisticService";
import { CustomerImportService } from "./services/customerImport/CustomerImportService";
import { CustomerImportFileService } from "./services/customerImportFile/CustomerImportFileService";
import { CustomerReportService } from "./services/customerReport/CustomerReportService";
import { NoteService } from "./services/note/NoteService";
import { CustomerDocumentService } from "./services/customerDocument/CustomerDocumentService";
import { CustomerDocumentsStatisticsService } from "./services/customerDocument/CustomerDocumentsStatisticsService";
import { FileStatusService } from "./services/fileStatus/FileStatusService";
import { LicenseManager } from "ag-grid-enterprise";
import { FinosecSystemStatisticReportsService } from "./services/finosecSystemStatisticReportsService/FinosecSystemStatisticReportsService";
import { UserProvider } from "./contexts/users";
import { RoleProvider } from "./contexts/roles";
import { AppProvider } from "./contexts/app";
import { MFAService } from "./services/mfa/MFAService";
import ProviderLookup from "./components/provider-lookup/ProviderLookup";
import { MemberProvider } from "./contexts/members";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { ClaimsProvider } from "./contexts/claims";
import { AccumulatorProvider } from "./contexts/accumulators";
import { PlanAllotmentProvider } from "./contexts/planAllotments";
import { MemberEnrollmentProvider } from "./contexts/memberEnrollments";
import { ToastProvider } from "./contexts/toasts";

//TODO: Need to move this to an enviromnet
LicenseManager.setLicenseKey(
    "CompanyName=KEY SYSTEMS CONSULTING INC,LicensedGroup=KEYSYS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-010451,ExpiryDate=11_September_2021_[v2]_MTYzMTMxNDgwMDAwMA==56d0b93898a8e2d846c40b184cb1b2d8"
);

dotenv.config(); // Load environment variables
registerServices();

const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

ReactDOM.render(
    <Provider store={store}>
        <DependencyInjectionProvider container={servicesContainer}>
            <PersistGate loading={undefined} persistor={persistor}>
                <AppProvider>
                    <RoleProvider>
                        <UserProvider>
                            <MemberProvider>
                                <ClaimsProvider>
                                    <AccumulatorProvider>
                                        <PlanAllotmentProvider>
                                            <MemberEnrollmentProvider>
                                                <ToastProvider>
                                                    <BrowserRouter>
                                                        <Header />
                                                        <div className="main-content-and-footer">
                                                            <Switch>
                                                                <Route
                                                                    path="/signin-oidc"
                                                                    render={() => React.createElement(SignInOidc)}
                                                                />
                                                                <Route
                                                                    path="/providers/locate/:type"
                                                                    render={() => React.createElement(ProviderLookup)}
                                                                />
                                                                <Route
                                                                    path="/providers/locate"
                                                                    render={() => React.createElement(ProviderLookup)}
                                                                />
                                                                <Route
                                                                    path="/"
                                                                    render={() => React.createElement(App)}
                                                                />
                                                            </Switch>
                                                            <Footer />
                                                        </div>
                                                    </BrowserRouter>
                                                </ToastProvider>
                                            </MemberEnrollmentProvider>
                                        </PlanAllotmentProvider>
                                    </AccumulatorProvider>
                                </ClaimsProvider>
                            </MemberProvider>
                        </UserProvider>
                    </RoleProvider>
                </AppProvider>
            </PersistGate>
        </DependencyInjectionProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function registerServices() {
    const httpClient = new HttpClient(store, request);
    const exceptionHandler: IExceptionHandler = new DefaultExceptionHandler(store);
    servicesContainer.bind<Store>(TYPES.Store).toConstantValue(store as Store);
    servicesContainer.bind(TYPES.HttpClient).toConstantValue(httpClient);
    servicesContainer.bind(TYPES.RequestPromiseApi).toConstantValue(request);
    servicesContainer.bind(TYPES.SubtabService).toConstantValue(new SubtabService(store, httpClient));
    servicesContainer.bind(TYPES.TabService).toConstantValue(new TabService(httpClient, store));
    servicesContainer.bind(TYPES.TenantService).toConstantValue(new TenantService(store, httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.AwsFileService).toConstantValue(new AwsFileService(httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.ReportService).toConstantValue(new ReportService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.ReportAttributeService)
        .toConstantValue(new ReportAttributeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.SubReportService)
        .toConstantValue(new SubReportService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.SubReportAttributeService)
        .toConstantValue(new SubReportAttributeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FinosecSystemService)
        .toConstantValue(new FinosecSystemService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FinosecSystemStatisticService)
        .toConstantValue(new FinosecSystemStatisticService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerImportService)
        .toConstantValue(new CustomerImportService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerImportFileService)
        .toConstantValue(new CustomerImportFileService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerReportService)
        .toConstantValue(new CustomerReportService(store, httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.NoteService).toConstantValue(new NoteService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FinosecSystemStatisticsReportsService)
        .toConstantValue(new FinosecSystemStatisticReportsService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerDocumentsStatisticsService)
        .toConstantValue(new CustomerDocumentsStatisticsService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerDocumentService)
        .toConstantValue(new CustomerDocumentService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FileStatusService)
        .toConstantValue(new FileStatusService(store, httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.MFAService).toConstantValue(new MFAService(store, httpClient, exceptionHandler));
}
