import { useState, useCallback, useEffect } from "react";
import { IUserForm } from "./IUserForm";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { AddUserFormModel } from "./AddUserFormModel";
import * as Yup from "yup";
import { User } from "../../../models/User";
import { Operable } from "../../../models/Operable";
import { useSelector } from "react-redux";
import VerticallyCenteredModal from "../../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { AppState } from "../../../state/AppState";
import { Col, Row, Form as BootstrapForm } from "react-bootstrap";
import KeysysInput from "../../form/input/KeysysInput";
import { KeysysSelectOption } from "../../form/select/KeysysSelectOption";
import ErrorMessageText from "../../form/error-message/ErrorMessageText";
import { Tenant } from "../../../models/Tenant";
import KeysysSelect from "../../form/select/KeysysSelect";
import ConfirmationModal from "../../modal/ConfirmationModal/ConfirmationModal";
import { useUsers } from "../../../contexts/users";
import { useRoles } from "../../../contexts/roles";
import { Roles } from "../../../models/Role";

export default function UserForm(props: IUserForm) {
    const { deleteUser, restoreUser, createUser, editUser } = useUsers();
    const { roleState } = useRoles();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const claimTenantName = decodedAccessToken.tenant_name;
    const loggedInUserRole = decodedAccessToken.roles;
    const qrCodeImage = decodedAccessToken.qr_code_image;

    const tenants = useSelector<AppState, Tenant[]>((state) => state.tenantState.tenants);
    function getInitialValues() {
        return {
            firstName: props.user?.firstName ?? "",
            lastName: props.user?.lastName ?? "",
            emailAddress: props.user?.emailAddress ?? "",
            phoneNumber: props.user?.phoneNumber ?? "",
            isActive: props.user?.isActive ?? true,
            tenantName: props.user?.tenantName ?? "",
            tenantId: props.user?.tenantId ?? 0,
            roleId: props.user?.roleId ?? Roles.User,
            mfaOptionId: props.user?.mfaOptionId ?? 1,
            mfaEnabled: false,
        };
    }
    const userSchema = Yup.object<AddUserFormModel>().shape({
        tenantId: Yup.number().min(1, "Tenant is required").required("Tenant is required"),
        firstName: Yup.string().max(50, "First Name must be 50 characters or less").required("First Name is required"),
        lastName: Yup.string().max(50, "Last Name must be 50 characters or less").required("Last Name is required"),
        emailAddress: Yup.string().max(100, "Email must be 100 characters or less").required("Email is required"),
        roleId: Yup.string().nullable().required("Role is required"),
        //mfaOptionId: Yup.number().min(1, "MFA Option is required").required("MFA Option is required"),
        // phoneNumber: Yup.number()
        //     .min(10, "Phone Number must be 10 characters")
        //     .when("mfaOptionId", {
        //         is: (mfaOptionId) => mfaOptionId === 2,
        //         then: Yup.number().required("Phone Number is required for SMS MFA Type"),
        //     }),
    });
    const clearFormAndHide = useCallback(
        (formProps: FormikProps<AddUserFormModel> | FormikHelpers<AddUserFormModel>): void => {
            formProps.resetForm();
            props.onClose();
        },
        [props]
    );

    useEffect(() => {
        if (props.user?.roleId === Roles.SuperAdmin) {
            setIsSuperAdmin(true);
        }
    }, [props]);

    const handleDeleteUser = useCallback(
        (user: User) => {
            deleteUser(user);
            setShowConfirmDeleteModal(false);
            props.onClose();
        },
        [deleteUser, props]
    );

    const handleRestoreUser = useCallback(
        (user: User) => {
            restoreUser(user);
            setShowConfirmRestoreModal(false);
            props.onClose();
        },
        [props, restoreUser]
    );

    function getTitle() {
        if (props.type === "add") {
            return "Add User";
        }
        if (props.type === "edit") {
            return "Edit User";
        }
        return "User";
    }

    // const getRoleOptions = (): KeysysSelectOption[] => {
    //     const roleOptions =
    //         loggedInUserRole === "CustomerAdmin"
    //             ? roles.filter((r) => r.name === "CustomerAdmin")
    //             : roles.filter((r) => r.name === "CustomerAdmin" || r.name === "PartnerAdmin");
    //     return roleOptions.map((r) => ({ value: r.id, label: r.name } as KeysysSelectOption));
    // };

    const getTenantOptions = (): KeysysSelectOption[] => {
        return [
            { value: 1, label: "Member (RootTenant)" } as KeysysSelectOption,
            ...tenants
                .filter((t) => t.name !== "RootTenant")
                .map((t) => ({ value: t.id, label: t.name } as KeysysSelectOption)),
        ];
    };

    const getMFAOptions = (): KeysysSelectOption[] => {
        return [
            { value: 1, label: "Email" } as KeysysSelectOption,
            { value: 2, label: "SMS" } as KeysysSelectOption,
            { value: 3, label: "Authenticator App" } as KeysysSelectOption,
        ];
    };

    const getMFAEnabledOptions = (): KeysysSelectOption[] => {
        return [
            { value: true, label: "Yes" } as KeysysSelectOption,
            { value: false, label: "No" } as KeysysSelectOption,
        ];
    };

    const getRoleIdToSave = useCallback(
        (selectedTenantId: number): number => {
            if (selectedTenantId === 1) return Roles.User;

            const tenantSelected = selectedTenantId
                ? tenants.filter((t) => t.id === selectedTenantId)[0]
                : tenants.filter((t) => t.name === claimTenantName)[0];

            let roleToSave = tenantSelected.parentTenantId ? "CustomerAdmin" : "PartnerAdmin";

            return roleState.roles.filter((r) => r.name === roleToSave)[0].id;
        },
        [claimTenantName, roleState.roles, tenants]
    );

    const getTenantNameToSave = useCallback(
        (selectedTenantId: number): string => {
            return selectedTenantId ? tenants.filter((t) => t.id === selectedTenantId)[0].name : claimTenantName;
        },
        [claimTenantName, tenants]
    );

    const getTenantIdToSave = useCallback((): number => {
        return tenants.filter((t) => t.name === claimTenantName)[0].id;
    }, [claimTenantName, tenants]);

    const handleSubmit = useCallback(
        (values: AddUserFormModel, FormikHelpers: FormikHelpers<AddUserFormModel>) => {
            if (props.type === "add") {
                const userToCreate: User = {
                    id: 0,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    emailAddress: values.emailAddress,
                    phoneNumber: values.phoneNumber,
                    isActive: true,
                    roleId: isSuperAdmin ? Roles.SuperAdmin : getRoleIdToSave(values.tenantId),
                    tenantId: loggedInUserRole === "CustomerAdmin" ? getTenantIdToSave() : values.tenantId,
                    tenantName: getTenantNameToSave(values.tenantId),
                    mfaOptionId: values.mfaOptionId,
                    mfaEnabled: values.mfaEnabled,
                    mfaSetupComplete: false,
                };

                createUser(userToCreate);
            } else {
                const editedUser: User = {
                    ...props.user!,
                    id: props.user!.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    emailAddress: values.emailAddress,
                    phoneNumber: values.phoneNumber,
                    roleId: isSuperAdmin ? Roles.SuperAdmin : getRoleIdToSave(values.tenantId),
                    tenantId: loggedInUserRole === "CustomerAdmin" ? getTenantIdToSave() : values.tenantId,
                    tenantName: getTenantNameToSave(values.tenantId),
                    mfaOptionId: values.mfaOptionId,
                    mfaEnabled: values.mfaEnabled,
                };
                const users: Operable<User> = {
                    original: props.user!,
                    updated: editedUser,
                };
                editUser(users);
            }
            clearFormAndHide(FormikHelpers);
        },
        [
            clearFormAndHide,
            createUser,
            editUser,
            getRoleIdToSave,
            getTenantIdToSave,
            getTenantNameToSave,
            isSuperAdmin,
            loggedInUserRole,
            props.type,
            props.user,
        ]
    );

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={userSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<AddUserFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditPartnerModal"
                            show={true}
                            title={getTitle()}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={!props.user || props.user?.isActive}
                            showDeleteButton={props.type === "edit" && props.user?.isActive}
                            deleteButtonText={"Deactivate"}
                            showRestoreButton={props.type === "edit" && !props.user?.isActive}
                            restoreButtonText={"Activate"}
                            onDeleteButtonClick={() => setShowConfirmDeleteModal(true)}
                            onRestoreButtonClick={() => setShowConfirmRestoreModal(true)}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="userForm">
                                {loggedInUserRole === "CustomerAdmin" ? (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <h3>{claimTenantName}</h3>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id={"tenant"}
                                                placeholder={"Select Tenant"}
                                                fieldName={"tenantId"}
                                                label={"Tenant"}
                                                options={getTenantOptions()}
                                                multiple={false}
                                                disabled={props.type === "edit" ? true : false}
                                            />
                                            <ErrorMessage name={"roleId"}>
                                                {(msg) => <ErrorMessageText message={msg} />}
                                            </ErrorMessage>
                                        </Col>
                                    </Row>
                                )}

                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"emailAddress"}
                                            placeholder={"Email"}
                                            fieldName={"emailAddress"}
                                            label={"Email"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"firstName"}
                                            placeholder={""}
                                            fieldName={"firstName"}
                                            label={"First Name"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"lastName"}
                                            placeholder={""}
                                            fieldName={"lastName"}
                                            label={"Last Name"}
                                        />
                                    </Col>
                                </Row>
                                {/* <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"phoneNumber"}
                                            placeholder={""}
                                            fieldName={"phoneNumber"}
                                            label={"phoneNumber"}
                                        />
                                    </Col>
                                </Row> */}
                                {/* {loggedInUserRole === "GlobalAdmin" && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id={"mfaEnabled"}
                                                placeholder={"Enable MFA"}
                                                fieldName={"mfaEnabled"}
                                                label={"Enable MFA"}
                                                options={getMFAEnabledOptions()}
                                                multiple={false}
                                            />
                                        </Col>
                                    </Row>
                                )} */}
                                {(loggedInUserRole === "GlobalAdmin" || loggedInUserRole === "SuperAdmin") && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <BootstrapForm.Check
                                                id="isSuperAdmin"
                                                type="checkbox"
                                                checked={isSuperAdmin}
                                                onChange={(e) => setIsSuperAdmin(e.target.checked)}
                                                label="Is Super Admin?"
                                                disabled={props.type === "edit"}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {(props.user?.mfaEnabled || formProps.values.mfaEnabled) &&
                                    props.user?.id === decodedAccessToken.userId && (
                                        <>
                                            <Row className={"mb-4"}>
                                                <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                    <KeysysSelect
                                                        formProps={formProps}
                                                        id={"mfaOption"}
                                                        placeholder={"Select MFA Option"}
                                                        fieldName={"mfaOptionId"}
                                                        label={"MFA (2FA) Options"}
                                                        options={getMFAOptions()}
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            {formProps.values.mfaOptionId === 2 && (
                                                <Row className={"mb-4"}>
                                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                        <strong>
                                                            By clicking Save, you are opting in to receive MFA one-time
                                                            passcode text message from Finosec UAR App. The Finosec UAR
                                                            App cannot be accessed without this code. Carrier Message
                                                            and Data rates may apply.
                                                        </strong>
                                                    </Col>
                                                </Row>
                                            )}
                                            {formProps.values.mfaOptionId === 3 && (
                                                <>
                                                    <Row className={"mb-4"}>
                                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                            <strong>
                                                                Please download either Goolge Authenticator or Microsoft
                                                                Authenticator. Open the app and scan the code below to
                                                                set up MFA.
                                                            </strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className={"mb-4"}>
                                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                            <img src={qrCodeImage} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </>
                                    )}
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
            <ConfirmationModal
                id="confirmDeleteUserModal"
                show={showConfirmDeleteModal}
                title="Deactivate User?"
                message={"Do you really want to deactivate this user?"}
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={() => handleDeleteUser(props.user!)}
                theme={"danger"}
            />
            <ConfirmationModal
                id="confirmRestoreUserModal"
                show={showConfirmRestoreModal}
                title="Activate User?"
                message={"Do you really want to activate this user?"}
                onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                onOkButtonClick={() => handleRestoreUser(props.user!)}
                theme={"danger"}
            />
        </>
    );
}
