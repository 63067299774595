import { useCallback, useEffect, useState } from "react";
import { getBillingPeriod } from "../helper-functions/billingPeriod";
import { BillingItem } from "../models/Billing";
import { useFetch } from "./useFetch";

export const useBillingPeriods = () => {
    const [billingPeriods, setBillingPeriods] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);

    const { get } = useFetch();

    useEffect(() => {
        const loadBillingPeriods = async () => {
            const data = await get<number[]>("billing/periods");
            if (data) {
                setBillingPeriods(data);
            }

            setLoading(false);
        };

        loadBillingPeriods();
    }, []);

    return {
        billingPeriods: billingPeriods.includes(getBillingPeriod())
            ? billingPeriods
            : [...billingPeriods, getBillingPeriod()],
        loading,
    };
};

export const useBillingItems = (billingPeriod: number) => {
    const [billingItems, setBillingItems] = useState<BillingItem[]>([]);
    const [loading, setLoading] = useState(true);

    const { get } = useFetch();

    useEffect(() => {
        const loadBillingItems = async () => {
            setLoading(true);
            const items = await get<BillingItem[]>(`billing/items/${billingPeriod}`);
            if (items) {
                setBillingItems(items);
            }

            setLoading(false);
        };

        loadBillingItems();
    }, [billingPeriod]);

    return { billingItems, loading };
};

export const useBillingStatement = () => {
    const { downloadFile, getFile } = useFetch();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const getParams = (billingItem: BillingItem) => {
        const { groupNumber, invoiceNumber, id } = billingItem;
        return new URLSearchParams({ groupNumber, invoiceNumber, billingItemId: id.toString() });
    };

    const getFilename = (billingItem: BillingItem, billingPeriod: number) => {
        return `${billingItem.statementName}_${billingPeriod}_BillingStatement`;
    };

    const getBillingStatement = useCallback(
        async (billingItem: BillingItem) => {
            setLoading(true);
            const data = await getFile({
                path: "billing/statement",
                queryParams: getParams(billingItem),
            });
            setLoading(false);
            return data;
        },
        [getFile]
    );

    const downloadBillingStatement = useCallback(
        async (billingItem: BillingItem, billingPeriod: number) => {
            setDownloading(true);
            await downloadFile({
                path: "billing/statement",
                queryParams: getParams(billingItem),
                fileName: getFilename(billingItem, billingPeriod),
                contentType: "application/pdf",
            });
            setDownloading(false);
        },
        [downloadFile]
    );

    return { downloadBillingStatement, getBillingStatement, loading, downloading };
};
