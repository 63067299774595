import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { User } from "../../models/User";
import { UserState, reducer } from "./reducer";
import { useFetch } from "../../hooks/useFetch";
import { UserActionType } from "./actions";
import { Operable } from "../../models/Operable";
import { createPatch } from "rfc6902";
import { useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { UserTypes } from "../../models/UserTypes";
import React from "react";
import { GroupAccount } from "../../models/GroupAccount";
import { PermissionType, UserPermission } from "../../models/UserPermission";
import { Permission } from "../../models/Permission";

const initialState: UserState = {
    users: [],
    selectedUser: undefined,
    loggedInUser: undefined,
};

interface IUserContext {
    userState: UserState;
    userType: UserTypes;
    myPermissions: UserPermission[];
    myPermissionsLoading: boolean;
    myPermissionTypes: PermissionType;
    getUsers: () => void;
    createUser: (user: User) => Promise<void | User>;
    deleteUser: (user: User) => void;
    editUser: (users: Operable<User>) => void;
    restoreUser: (user: User) => void;
    getInactiveUsers: () => void;
    selectUser: (user: User) => void;
    clearSelectedUser: () => void;
    getGroupAccounts: () => Promise<void | GroupAccount[]>;
    getUserPermissions: (userId: number) => Promise<void | UserPermission[]>;
    addUserPermission: (userPermission: UserPermission) => Promise<void | UserPermission>;
    deleteUserPermission: (id: number) => Promise<void | boolean>;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider = ({ children }: any) => {
    const [userState, dispatch] = useReducer(reducer, initialState);
    const { get, post, patch, del } = useFetch();
    const { decodedAccessToken, isAuthenticated } = useSelector((state: AppState) => state.authenticationState);

    const [myPermissions, setMyPermissions] = useState<UserPermission[]>([]);
    const [myPermissionsLoading, setMyPermissionsLoading] = useState(false);

    const userType = useMemo((): UserTypes => {
        if (!userState.loggedInUser || !decodedAccessToken.userId) {
            return UserTypes.Unauthenticated;
        }
        if (decodedAccessToken.roles === "GlobalAdmin") {
            return UserTypes.GlobalAdmin;
        }
        if (decodedAccessToken.roles === "SuperAdmin") {
            return UserTypes.SuperAdmin;
        }
        if (decodedAccessToken.roles === "GroupAdmin") {
            return UserTypes.GroupAdmin;
        }
        if (decodedAccessToken.tenant_id === 1) {
            return UserTypes.Member;
        }
        return UserTypes.Provider;
    }, [decodedAccessToken, userState.loggedInUser]);

    const populateMyPermissions = useCallback(async () => {
        if (userState.loggedInUser) {
            setMyPermissionsLoading(true);
            const permissions = await get<UserPermission[]>(`users/user-permissions/${userState.loggedInUser.id}`);
            setMyPermissions(permissions || []);
            setMyPermissionsLoading(false);
        }
    }, [userState.loggedInUser]);

    const myPermissionTypes = useMemo(() => {
        const hasAccess = !!myPermissions.find((perm) => perm.permissionId === Permission.Access);
        const hasEnrollment = !!myPermissions.find((perm) => perm.permissionId === Permission.Enrollment);
        return { access: hasAccess, enrollment: hasEnrollment } as PermissionType;
    }, [myPermissions]);

    useEffect(() => {
        if (decodedAccessToken.userId && userState.users.length > 0 && !userState.loggedInUser) {
            const loggedInUser = userState.users.find((u) => u.id === decodedAccessToken.userId);
            dispatch({
                type: UserActionType.SET_LOGGED_IN_USER,
                payload: loggedInUser,
            });
        }
        if (userType === UserTypes.GroupAdmin) {
            populateMyPermissions().catch((e) => console.error(e));
        } else {
            setMyPermissions([]);
        }
    }, [decodedAccessToken, populateMyPermissions, userState, userType]);

    const getUsers = useCallback(async () => {
        if (isAuthenticated) {
            const users = await get<User[]>("users");
            if (users) {
                dispatch({
                    type: UserActionType.USERS_FETCHED,
                    payload: users,
                });
            }
        }
    }, [get, isAuthenticated]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const createUser = useCallback(
        async (user: User) => {
            const createdUser = await post<User>("users", user);
            if (createdUser) {
                dispatch({
                    type: UserActionType.USER_CREATED,
                    payload: createdUser,
                });
            }
            return createdUser;
        },
        [post]
    );

    const deleteUser = useCallback(
        async (user: User) => {
            const updatedUser = {
                ...user,
                isActive: false,
            };
            const deleted = await del(`users/${user.id}`);
            const payload: Operable<User> = {
                original: user,
                updated: updatedUser,
            };
            if (deleted) {
                dispatch({
                    type: UserActionType.USER_DELETED,
                    payload: payload,
                });
            }
        },
        [del]
    );

    const editUser = useCallback(
        async (users: Operable<User>) => {
            const operations = createPatch(users.original, users.updated);
            const patchedUser = await patch<User>(`users/${users.original.id}`, operations);
            if (patchedUser) {
                dispatch({
                    type: UserActionType.USER_EDITED,
                    payload: users,
                });
            }
        },
        [patch]
    );

    const restoreUser = useCallback(
        async (user: User) => {
            const updatedUser = {
                ...user,
                isActive: true,
            };
            const operations = createPatch(user, updatedUser);
            const patchedUser = await patch<User>(`users/${user.id}`, operations);
            const payload: Operable<User> = {
                original: user,
                updated: updatedUser,
            };
            if (patchedUser) {
                dispatch({
                    type: UserActionType.USER_RESTORED,
                    payload: payload,
                });
            }
        },
        [patch]
    );

    const getInactiveUsers = useCallback(async () => {
        const users = await get<User[]>("users/inactive");
        if (users) {
            dispatch({
                type: UserActionType.INACTIVE_USERS_FETCHED,
                payload: users,
            });
        }
    }, [get]);

    const selectUser = useCallback((user: User | undefined) => {
        dispatch({
            type: UserActionType.SELECT_USER,
            payload: user,
        });
    }, []);

    const clearSelectedUser = useCallback(() => {
        dispatch({
            type: UserActionType.CLEAR_SELECTED_USER,
            payload: undefined,
        });
    }, []);

    const getGroupAccounts = useCallback(async () => {
        return await get<GroupAccount[]>("groupaccounts");
    }, [get]);

    const getUserPermissions = useCallback(
        async (userId: number) => {
            return await get<UserPermission[]>(`users/user-permissions/${userId}`);
        },
        [get]
    );

    const addUserPermission = useCallback(
        async (userPermission: UserPermission) => {
            return await post<UserPermission>("users/user-permission", userPermission);
        },
        [post]
    );

    const deleteUserPermission = useCallback(
        async (id: number) => {
            return await del(`users/user-permission/${id}`);
        },
        [del]
    );

    const value = useMemo(
        () => ({
            userState,
            userType,
            myPermissions,
            myPermissionsLoading,
            myPermissionTypes,
            getUsers,
            createUser,
            deleteUser,
            editUser,
            restoreUser,
            getInactiveUsers,
            selectUser,
            clearSelectedUser,
            getGroupAccounts,
            getUserPermissions,
            addUserPermission,
            deleteUserPermission,
        }),
        [
            userState,
            userType,
            myPermissions,
            myPermissionsLoading,
            myPermissionTypes,
            getUsers,
            createUser,
            deleteUser,
            editUser,
            restoreUser,
            getInactiveUsers,
            selectUser,
            clearSelectedUser,
            getGroupAccounts,
            getUserPermissions,
            addUserPermission,
            deleteUserPermission,
        ]
    );

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUsers = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUsers must be used within a UserProvider");
    }
    return context;
};
