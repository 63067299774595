import { LOGGED_IN, LoggedInAction, LOGIN_FAIL, LoginFailAction, LogoutAction } from "./AuthenticateActions";
import jwt_decode from "jwt-decode";
import { persistor } from "../store";
import { SsoService } from "../models/SsoService";
import { KeysysThunkAction } from "../store/KeysysThunkAction";

export function logOut(): KeysysThunkAction<void, LogoutAction> {
    return async (dispatch, getState, container) => {
        await persistor.purge();
        await new SsoService().signOut();
    };
}

export function loggedIn(accessToken: string, refreshToken: string): LoggedInAction {
    const decodedAccessToken = jwt_decode(accessToken) as any;
    return {
        type: LOGGED_IN,
        accessToken: accessToken,
        decodedAccessToken: {
            ...decodedAccessToken,
            userId: parseInt((decodedAccessToken.user_id as any) as string),
            reports: decodedAccessToken.reports as string,
        },
        refreshToken: refreshToken,
    };
}

export function loginFail(): LoginFailAction {
    return {
        type: LOGIN_FAIL,
    };
}
