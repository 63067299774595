import KeysysToast from "./KeysysToast";
import { useToast } from "../../contexts/toasts";

export const Toaster = () => {
    const { activeToasts } = useToast();
    return (
        <>
            {activeToasts.map((toast, i) => (
                <KeysysToast key={toast.name + i} {...toast} />
            ))}
        </>
    );
};
