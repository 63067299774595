import { User } from "../../models/User";
import { UserActionType } from "./actions";
import { replaceInCollection } from "../../reducers/ReplaceInCollection";
import { isT, isOperable, isArray, IAction } from "..";

export interface UserState {
    users: User[];
    selectedUser?: User;
    loggedInUser?: User;
}

export const reducer = (state: UserState, action: IAction<UserActionType, User>): UserState => {
    const { type, payload } = action;
    if (payload) {
        if (isT<User>(payload)) {
            switch (type) {
                case UserActionType.USER_CREATED:
                    return {
                        ...state,
                        users: sortUsers([...state.users, payload]),
                    };
                case UserActionType.SELECT_USER:
                    return {
                        ...state,
                        selectedUser: payload,
                    };
                case UserActionType.SET_LOGGED_IN_USER:
                    return {
                        ...state,
                        loggedInUser: payload,
                    };
            }
        }
        if (isArray<User>(payload)) {
            switch (type) {
                case UserActionType.USERS_FETCHED:
                    return {
                        ...state,
                        users: sortUsers([...payload]),
                    };
                case UserActionType.INACTIVE_USERS_FETCHED:
                    return {
                        ...state,
                        users: sortUsers([...state.users.filter((u) => u.isActive), ...payload]),
                    };
            }
        }
        if (isOperable<User>(payload)) {
            switch (type) {
                case UserActionType.USER_EDITED:
                    return {
                        ...state,
                        users: replaceUserInCollection(state.users, payload.original, payload.updated),
                    };
                case UserActionType.USER_DELETED:
                    return {
                        ...state,
                        users: replaceUserInCollection(state.users, payload.original, payload.updated),
                    };
                case UserActionType.USER_RESTORED:
                    return {
                        ...state,
                        users: replaceUserInCollection(state.users, payload.original, payload.updated),
                    };
            }
        }
    } else {
        switch (type) {
            case UserActionType.CLEAR_SELECTED_USER:
                return {
                    ...state,
                    selectedUser: undefined,
                };
        }
    }
    return state;
};

const compareUsers = (a: User, b: User): number => a.emailAddress.localeCompare(b.emailAddress);

const sortUsers = (users: User[]): User[] => {
    return users.sort(compareUsers);
};

const replaceUserInCollection = (users: User[], ogUser: User, newUser: User): User[] => {
    return replaceInCollection(users, ogUser, newUser, compareUsers);
};
