import { Fragment, useCallback, useMemo, useState } from "react";
import { Button, Col, Collapse, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAccumulators } from "../../contexts/accumulators";
import { useMembers } from "../../contexts/members";
import { useUsers } from "../../contexts/users";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { AccumulatorNumbers, AccumulatorRef, MemberAccumulator } from "../../models/Accumulator";
import { UserTypes } from "../../models/UserTypes";
import { AppState } from "../../state/AppState";
import { usePlanAllotments } from "../../contexts/planAllotments";
import { formatCurrency } from "../../helper-functions";
import { PlanAllotment } from "../../models/PlanAllotment";

const getFullName = (ma: MemberAccumulator) => `${ma.firstName} ${ma.middleName} ${ma.lastName}`;

const isDollarAmount = (accumulationType?: string) => accumulationType === "Dollar Amount";

const getRemainder = (allotment: number, utilized?: number) => {
    if (utilized === undefined) {
        utilized = 0;
    }
    const remainder = +allotment - +utilized;
    return remainder < 0 ? 0 : remainder;
};
const getPercentUtilized = (allotment: number, utilized?: number) => {
    if (allotment === 0) return 0;
    if (utilized === undefined) {
        utilized = 0;
    }
    return Math.ceil((utilized / allotment) * 100);
};

type ProductUsageBarProps = {
    percentUtilized: number;
};
const ProductUsageBar = ({ percentUtilized }: ProductUsageBarProps) => {
    const remaining = useMemo(() => {
        return 100 - percentUtilized;
    }, [percentUtilized]);
    return (
        <>
            <div className="product-usage-bar">
                <div className="utilized" style={{ width: `${percentUtilized}%` }} />
                <div className="remaining" style={{ width: `${remaining}%` }} />
            </div>
        </>
    );
};

const VisionProductMessage = () => {
    return (
        <div className="vision-product-msg">
            <i className="fas fa-exclamation-triangle icon" /> Plan provides{" "}
            <span className="form-label text-uppercase">either</span> contacts or lenses & frames,{" "}
            <span className="form-label text-uppercase">but not both</span> in any plan period
        </div>
    );
};

type ProductRowProps = {
    name: string;
    allotment?: PlanAllotment;
    accumulator?: AccumulatorRef;
    defaultAllotment?: number;
    showWarning?: boolean;
};
const ProductRow = ({ name, allotment, accumulator, defaultAllotment = 0, showWarning = false }: ProductRowProps) => {
    const isDollarAmt = useMemo(() => {
        return isDollarAmount(allotment?.accumulationType) || isDollarAmount(accumulator?.accumulationType);
    }, [allotment, accumulator]);

    const benefitValue = useMemo(() => {
        return allotment?.benefitValue ?? (isDollarAmt ? 0 : defaultAllotment);
    }, [allotment, defaultAllotment, isDollarAmt]);

    const utilized = useMemo(() => {
        return accumulator?.accumulatorAmt ?? 0;
    }, [accumulator]);

    const percentUtilized = useMemo(() => {
        return getPercentUtilized(benefitValue, utilized);
    }, [benefitValue, utilized]);

    const remainder = useMemo(() => {
        return getRemainder(benefitValue, utilized);
    }, [benefitValue, utilized]);

    return (
        <>
            <tr>
                <td></td>
                <td>{name}</td>
                {showWarning ? (
                    <td colSpan={5}>
                        <VisionProductMessage />
                    </td>
                ) : (
                    <>
                        <td>{isDollarAmt ? formatCurrency(benefitValue) : benefitValue}</td>
                        <td>{isDollarAmt ? formatCurrency(utilized) : utilized}</td>
                        <td>{isDollarAmt ? formatCurrency(remainder) : remainder}</td>
                        <td width="15%">
                            <ProductUsageBar percentUtilized={percentUtilized} />
                        </td>
                        <td>{percentUtilized}%</td>
                    </>
                )}
                <td>
                    {accumulator?.lastServiceDate ? new Date(accumulator.lastServiceDate).toLocaleDateString() : "-"}
                </td>
            </tr>
        </>
    );
};

type ProductTableProps = {
    accumulators: AccumulatorRef[];
    dependentSequenceNumber: number;
};
const DentalProductTable = ({ accumulators, dependentSequenceNumber }: ProductTableProps) => {
    const { findAllotmentByAccumulator } = usePlanAllotments();

    const products = useMemo(() => {
        return [
            {
                allotment: findAllotmentByAccumulator(AccumulatorNumbers.PlanYearAllowance),
                accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.PlanYearAllowance),
                name: "Plan Year Allowance",
            },
            {
                allotment: findAllotmentByAccumulator(AccumulatorNumbers.PlanYearDeductible),
                accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.PlanYearDeductible),
                name: "Plan Year Deductible",
            },
            // {
            //     allotment: findAllotmentByAccumulator(AccumulatorNumbers.DentalExams),
            //     accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.DentalExams),
            //     defaultAllotment: 2,
            //     name: "Exams",
            // },
            // {
            //     allotment: findAllotmentByAccumulator(AccumulatorNumbers.Cleanings),
            //     accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.Cleanings),
            //     defaultAllotment: 2,
            //     name: "Cleanings",
            // },
            // {
            //     allotment: findAllotmentByAccumulator(AccumulatorNumbers.XRayBitewing),
            //     accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.XRayBitewing),
            //     defaultAllotment: 2,
            //     name: "X-Ray Bitewing",
            // },
            // {
            //     allotment: findAllotmentByAccumulator(AccumulatorNumbers.XRayPano),
            //     accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.XRayPano),
            //     defaultAllotment: 1,
            //     name: "X-Ray Full (Pano)",
            // },
            // {
            //     allotment: findAllotmentByAccumulator(AccumulatorNumbers.Fluoride),
            //     accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.Fluoride),
            //     defaultAllotment: 2,
            //     name: "Fluoride - Age limits apply",
            // },
        ];
    }, [accumulators, findAllotmentByAccumulator]);

    return (
        <Table>
            <thead>
                <tr>
                    <th></th>
                    <th>Dental Product</th>
                    <th>Allotment</th>
                    <th>Utilized</th>
                    <th>Remaining</th>
                    <th></th>
                    <th></th>
                    <th>Last Service Date</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product) => (
                    <ProductRow key={`dental-${product.name}-${dependentSequenceNumber}`} {...product} />
                ))}
            </tbody>
        </Table>
    );
};
const VisionProductTable = ({ accumulators, dependentSequenceNumber }: ProductTableProps) => {
    const { findAllotmentByAccumulator } = usePlanAllotments();

    const products = useMemo(() => {
        const [exams, frames, lenses, contacts] = [
            {
                allotment: findAllotmentByAccumulator(AccumulatorNumbers.VisionExams),
                accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.VisionExams),
                defaultAllotment: 2,
                name: "Exams",
            },
            {
                allotment: findAllotmentByAccumulator(AccumulatorNumbers.Frames),
                accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.Frames),
                defaultAllotment: 1,
                name: "Frames - Refer to your plan",
            },
            {
                allotment: findAllotmentByAccumulator(AccumulatorNumbers.Lenses),
                accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.Lenses),
                defaultAllotment: 1,
                name: "Lenses (Pair)",
            },
            {
                allotment: findAllotmentByAccumulator(AccumulatorNumbers.Contacts),
                accumulator: accumulators.find((a) => a.accumulatorNumber === AccumulatorNumbers.Contacts),
                name: "Contacts - Refer to your plan",
            },
        ];

        const [hasContacts, hasGlasses] = [!!contacts.accumulator, !!frames.accumulator || !!lenses.accumulator];
        const hasNeither = !hasContacts && !hasGlasses;

        return [
            exams,
            {
                ...frames,
                showWarning: hasContacts || hasNeither,
            },
            {
                ...lenses,
                showWarning: hasContacts || hasNeither,
            },
            {
                ...contacts,
                showWarning: hasGlasses || hasNeither,
            },
        ];
    }, [accumulators, findAllotmentByAccumulator]);

    return (
        <Table>
            <thead>
                <tr>
                    <th></th>
                    <th>Vision Product</th>
                    <th>Allotment</th>
                    <th>Utilized</th>
                    <th>Remaining</th>
                    <th></th>
                    <th></th>
                    <th>Last Service Date</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product) => (
                    <ProductRow key={`vision-${product.name}-${dependentSequenceNumber}`} {...product} />
                ))}
            </tbody>
        </Table>
    );
};

export const AccumulatorInformation = () => {
    const { memberAccumulators } = useAccumulators();
    const { getMyPlanDetailPdf, hasCoverage, isGettingPdf, isMember, myInsuranceInfo } = useMembers();
    const { planAllotments } = usePlanAllotments();
    const { userType } = useUsers();

    const { tenant_id } = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const { currentTenant } = useCurrentTenant(tenant_id);

    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const toggleRowExpand = (row: string) => {
        setExpandedRows((prev) => {
            if (prev.includes(row)) {
                return prev.filter((r) => r !== row);
            }
            return [...prev, row];
        });
    };

    const { showDental, showVision } = useMemo(() => {
        return {
            showDental:
                (isMember && hasCoverage("dental")) ||
                (!isMember && userType == UserTypes.Provider && currentTenant?.providerType === "D"),
            showVision: false,
            // (isMember && hasCoverage("vision")) ||
            // (!isMember && userType == UserTypes.Provider && currentTenant?.providerType === "V"),
        };
    }, [hasCoverage, isMember, userType, currentTenant]);

    const dentalCoverage = useMemo(() => {
        if (!myInsuranceInfo) return undefined;
        return myInsuranceInfo.coverageInformation.find((cov) => cov.coverageType === "Dental");
    }, [myInsuranceInfo]);

    const visionCoverage = useMemo(() => {
        if (!myInsuranceInfo) return undefined;
        return myInsuranceInfo.coverageInformation.find((cov) => cov.coverageType === "Vision");
    }, [myInsuranceInfo]);

    const planYearAllowance = useMemo(() => {
        return (
            planAllotments.find((allot) => allot.accumulatorNumber === AccumulatorNumbers.PlanYearAllowance)
                ?.benefitValue ?? 0
        );
    }, [planAllotments]);

    const getIndividualLimitRemaining = useCallback(
        (ma: MemberAccumulator) => {
            const utilized =
                ma.accumulators.find((acc) => acc.accumulatorNumber === AccumulatorNumbers.PlanYearAllowance)
                    ?.accumulatorAmt ?? 0;
            return getRemainder(planYearAllowance, utilized);
        },
        [planYearAllowance]
    );

    if (!myInsuranceInfo) {
        return null;
    }
    // Temprarily removing Benefit Accumulator Information Section for Vision as per SBPRFCC-187
    return !showDental ? (
        <></>
    ) : (
        <>
            <Row>
                <Col>
                    <h5 className="border border-bottom-0 p-3 mb-0 form-label">Benefit Accumulators Information</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                {showDental && (
                                    <>
                                        <th>Individual Limit Remaining</th>
                                        <th>Dental Product Plan</th>
                                        <th>Dental Product Code</th>
                                    </>
                                )}
                                {showVision && (
                                    <>
                                        <th>Vision Product Plan</th>
                                        <th>Vision Product Code</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {memberAccumulators.map((ma, i) => {
                                const maKey = `ma-${ma.dependentSequenceNumber}-${i}`;
                                const isDependent = ma.dependentSequenceNumber !== 0;
                                return (
                                    <Fragment key={maKey}>
                                        <tr>
                                            <td>
                                                <Button size="sm" onClick={() => toggleRowExpand(maKey)}>
                                                    <i
                                                        className={
                                                            expandedRows.includes(maKey)
                                                                ? `fas fa-minus`
                                                                : `fas fa-plus`
                                                        }
                                                    />
                                                </Button>
                                            </td>
                                            <td>{getFullName(ma)}</td>
                                            {showDental && (
                                                <>
                                                    <td>{formatCurrency(getIndividualLimitRemaining(ma))}</td>
                                                    <td>
                                                        {!isDependent && dentalCoverage && (
                                                            <Button
                                                                variant="primary"
                                                                onClick={() =>
                                                                    getMyPlanDetailPdf(dentalCoverage.productPlan)
                                                                }
                                                                disabled={isGettingPdf}
                                                            >
                                                                {dentalCoverage.product} {dentalCoverage.productPlan}
                                                            </Button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {!isDependent && dentalCoverage && (
                                                            <>{dentalCoverage.productPlan}</>
                                                        )}
                                                    </td>
                                                </>
                                            )}
                                            {showVision && (
                                                <>
                                                    <td>
                                                        {!isDependent && visionCoverage && (
                                                            <Button
                                                                variant="primary"
                                                                onClick={() =>
                                                                    getMyPlanDetailPdf(visionCoverage.productPlan)
                                                                }
                                                                disabled={isGettingPdf}
                                                            >
                                                                {visionCoverage.product} {visionCoverage.productPlan}
                                                            </Button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {!isDependent && visionCoverage && (
                                                            <>{visionCoverage.productPlan}</>
                                                        )}
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                        <tr>
                                            <td colSpan={7}>
                                                <Collapse in={expandedRows.includes(maKey)}>
                                                    <div>
                                                        {showDental && (
                                                            <DentalProductTable
                                                                accumulators={ma.accumulators.filter(
                                                                    (acc) => acc.accumulatorProduct === "Dental"
                                                                )}
                                                                dependentSequenceNumber={ma.dependentSequenceNumber}
                                                            />
                                                        )}
                                                        {showVision && (
                                                            <VisionProductTable
                                                                accumulators={ma.accumulators.filter(
                                                                    (acc) => acc.accumulatorProduct === "Vision"
                                                                )}
                                                                dependentSequenceNumber={ma.dependentSequenceNumber}
                                                            />
                                                        )}
                                                    </div>
                                                </Collapse>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};
