import { MemberBenefits } from "../../models/MemberBenefits";

export interface CardTemplateProps {
    memberBenefits: MemberBenefits[];
}

export enum ProductCategories {
    DENTAL = "Dental",
    VISION = "Vision",
    CANCER = "Cancer",
    INDEMNITY = "Indemnity",
}

export const cardHeight = 270;
export const cardWidth = 428;

export const getCoverage = (coverageOption: string): string => {
    switch (coverageOption.toUpperCase()) {
        case "E":
            return "Employee";
        case "S":
            return "Spouse";
        case "F":
            return "Family";
        case "C":
            return "Child";
        default:
            return "";
    }
};

export const getFullName = (memberBenefits: MemberBenefits) => {
    let result = memberBenefits.firstName;
    if (memberBenefits.middleName) {
        result += ` ${memberBenefits.middleName}`;
    }
    result += ` ${memberBenefits.lastName}`;
    return result;
};
