import { createContext, useReducer, useCallback, useMemo, useContext } from "react";
import { reducer, RoleState } from "./reducer";
import { useFetch } from "../../hooks/useFetch";
import { Role } from "../../models/Role";
import { RoleActionType } from "./actions";

const initialState: RoleState = {
    roles: [],
};

interface IRoleContext {
    roleState: RoleState;
    getRoles: () => void;
}

const RoleContext = createContext<IRoleContext>({} as IRoleContext);

export const RoleProvider = ({ children }: any) => {
    const [roleState, dispatch] = useReducer(reducer, initialState);
    const { get } = useFetch();

    const getRoles = useCallback(async () => {
        const roles = await get<Role[]>("roles");
        if (roles) {
            dispatch({
                type: RoleActionType.ROLES_FETCHED,
                payload: roles,
            });
        }
    }, [get]);

    const value = useMemo(
        () => ({
            roleState,
            getRoles,
        }),
        [roleState, getRoles]
    );

    return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export const useRoles = () => {
    const context = useContext(RoleContext);
    if (context === undefined) {
        throw new Error("useRoles must be used within a RoleProvider");
    }
    return context;
};
