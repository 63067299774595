import LightSection from "../page-sections/LightSection";
import { Tab, Tabs } from "react-bootstrap";
import GroupAdminConfigurations from "./app-configurations/GroupAdminConfigurations";

const AppConfigurations = () => {
    return (
        <LightSection>
            <Tabs defaultActiveKey="groupAdmin" id="app-configurations">
                <Tab eventKey="groupAdmin" title="Group Admin">
                    <GroupAdminConfigurations />
                </Tab>
            </Tabs>
        </LightSection>
    );
};

export default AppConfigurations;
