import React from "react";
import SmallSquareEditButton from "../buttons/SmallSquareEditButton/SmallSquareEditButton";
import { Identifiable } from "../../models/Identifiable";

interface EditButtonRenderProps {
    data: Identifiable;
    entityName: string;
    showEdit: (identifiable: Identifiable) => void;
}

export default function EditButtonRender(props: EditButtonRenderProps) {
    return (
        <SmallSquareEditButton
            key={`edit-${props.data.id}`}
            entityNameSingular={props.entityName}
            onClick={() => props.showEdit(props.data)}
        />
    );
}
