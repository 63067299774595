import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { EditUserModalProps } from "./editUserModalProps";
import RegisterUser from "../../users/forms/RegisterUser";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { Alert, Button } from "react-bootstrap";
import { useFetch } from "../../../hooks/useFetch";
import { useHistory } from "react-router-dom";

const EditUserModal = (props: EditUserModalProps) => {
    const [passwordResetRequested, setPasswordResetRequested] = useState(false);
    const { userToEdit, onSaveChanges, errorMessage } = props;
    const { postUnique } = useFetch();
    const sendPasswordReset = async () => {
        await postUnique<string, void>(`users/forgot_password`, userToEdit.email);
        setPasswordResetRequested(true);
    };
    const history = useHistory();

    return (
        <Modal
            id={props.id}
            size={props.size || "lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            backdrop={"static"}
            onHide={props.onCancelButtonClick}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RegisterUser userToEdit={userToEdit} onSaveChanges={onSaveChanges} />
                {errorMessage && <div className={"invalid-feedback d-block text-end mb-0"}>{errorMessage}</div>}
                {passwordResetRequested && (
                    <Alert variant={"primary"}>
                        <p>
                            {"Password reset has been requested for "}
                            <span
                                className={"text-capitalize"}
                            >{`${userToEdit.firstName} ${userToEdit.lastName} `}</span>
                            {"at "}
                            <strong>{userToEdit.email.toLowerCase()}</strong>
                        </p>
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className={"justify-content-start"}>
                <ButtonToolbar>
                    <Button
                        variant={"outline-primary"}
                        size={"sm"}
                        disabled={passwordResetRequested}
                        onClick={sendPasswordReset}
                    >
                        Reset Password
                        {passwordResetRequested && <i className={"fas fa-check ms-2"} />}
                    </Button>
                    <Button
                        variant={"outline-primary"}
                        size={"sm"}
                        className={"ms-2"}
                        onClick={() => history.push("/access-permission/" + userToEdit.id)}
                    >
                        Access Permissions
                    </Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default EditUserModal;
