export const getDateStringForFileName = (date: Date = new Date(), pad = false): string => {
    const year = date.getFullYear();
    let month = `${date.getMonth() + 1}`;
    let dateOfMonth = date.getDate().toString();
    if (pad) {
        month = month.padStart(2, "0");
        dateOfMonth = dateOfMonth.padStart(2, "0");
    }
    return `${year}-${month}-${dateOfMonth}`;
};

/**
 * @returns The datetime as a string in the format YYYYMMDD-HHMMSS.
 */
export const getDateTimeForFilename = (date = new Date()) => {
    const time = date.toTimeString().split(" ")[0];
    return getDateStringForFileName(date, true).replace(/-/g, "") + "-" + time.replace(/:/g, "");
};
