import { CustomerDocument, CustomerDocumentStatistics } from "../../models/CustomerDocument";
import { IProgramReference } from "../../models/ProgramReference";

export const CUSTOMER_DOCUMENTS_FETCHED = "CUSTOMER_DOCUMENTS_FETCHED";
export const FETCH_CUSTOMER_DOCUMENT = "FETCH_CUSTOMER_DOCUMENT";
export const CREATE_CUSTOMER_DOCUMENT = "CREATE_CUSTOMER_DOCUMENT";
export const EDIT_CUSTOMER_DOCUMENT = "EDIT_CUSTOMER_DOCUMENT";
export const DELETE_CUSTOMER_DOCUMENT = "DELETE_CUSTOMER_DOCUMENT";
export const DOWNLOAD_CUSTOMER_DOCUMENT = "DOWNLOAD_CUSTOMER_DOCUMENT";
export const PROGRAM_REFERENCES_FETCHED = "PROGRAM_REFERENCES_FETCHED";
export const CUSTOMER_DOCUMENTS_STATISTICS = "CUSTOMER_DOCUMENTS_STATISTICS";

export interface CustomerDocumentsFetchedAction {
    type: typeof CUSTOMER_DOCUMENTS_FETCHED;
    customerDocuments: CustomerDocument[];
}

export interface CustomerDocumentsStatisticsAction {
    type: typeof CUSTOMER_DOCUMENTS_STATISTICS;
    customerDocumentStatistics: CustomerDocumentStatistics[];
}

export interface ProgramReferencesFetchedAction {
    type: typeof PROGRAM_REFERENCES_FETCHED;
    programReferences: IProgramReference[];
}

export interface FetchCustomerDocumentAction {
    type: typeof FETCH_CUSTOMER_DOCUMENT;
    customerDocument: CustomerDocument;
}

export interface CreateCustomerDocumentAction {
    type: typeof CREATE_CUSTOMER_DOCUMENT;
    customerDocument: CustomerDocument;
}

export interface EditCustomerDocumentAction {
    type: typeof EDIT_CUSTOMER_DOCUMENT;
    originalCustomerDocument: CustomerDocument;
    newCustomerDocument: CustomerDocument;
}

export interface DeleteCustomerDocumentAction {
    type: typeof DELETE_CUSTOMER_DOCUMENT;
    customerDocument: CustomerDocument;
}

export interface DownloadCustomerDocumentAction {
    type: typeof DOWNLOAD_CUSTOMER_DOCUMENT;
    customerDocument: any;
}

export type CustomerDocumentActions =
    | CustomerDocumentsFetchedAction
    | FetchCustomerDocumentAction
    | CreateCustomerDocumentAction
    | EditCustomerDocumentAction
    | DeleteCustomerDocumentAction
    | DownloadCustomerDocumentAction
    | ProgramReferencesFetchedAction;
