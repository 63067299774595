import { Tenant } from "../models/Tenant";
import dentalForm from "../claim-forms/Canopy_DentalClaimForm_JAD_122022.pdf";
import visionForm from "../claim-forms/Canopy_Vision_form_JAD_122022.pdf";

export const getFileClaimPath = (currentTenant: Tenant | undefined) => {
    let path = "";
    switch (currentTenant?.providerType) {
        case "D":
            path = dentalForm;
            break;

        case "V":
            path = visionForm;
            break;

        default:
            break;
    }
    return path;
};
