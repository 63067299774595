import { CustomerDocumentState } from "../../state/CustomerDocumentState";
import {
    CustomerDocumentActions,
    CUSTOMER_DOCUMENTS_FETCHED,
    CREATE_CUSTOMER_DOCUMENT,
    EDIT_CUSTOMER_DOCUMENT,
    DELETE_CUSTOMER_DOCUMENT,
    PROGRAM_REFERENCES_FETCHED,
} from "../../actions/customerDocument/CustomerDocumentActions";
import { replaceInCollection } from "./../ReplaceInCollection";

const initialState: CustomerDocumentState = {
    customerDocuments: [],
    programReferences: [],
};

export function customerDocumentReducer(
    state: CustomerDocumentState = initialState,
    action: CustomerDocumentActions
): CustomerDocumentState {
    switch (action.type) {
        case CUSTOMER_DOCUMENTS_FETCHED: {
            return {
                ...state,
                customerDocuments: action.customerDocuments,
            };
        }
        case PROGRAM_REFERENCES_FETCHED: {
            return {
                ...state,
                programReferences: action.programReferences,
            };
        }
        case CREATE_CUSTOMER_DOCUMENT:
            return {
                ...state,
                customerDocuments: [...state.customerDocuments, action.customerDocument],
            };
        case EDIT_CUSTOMER_DOCUMENT:
            return {
                ...state,
                customerDocuments: replaceInCollection(
                    state.customerDocuments,
                    action.originalCustomerDocument,
                    action.newCustomerDocument,
                    (a, b) => a.id.toString().localeCompare(b.id.toString())
                ),
            };
        case DELETE_CUSTOMER_DOCUMENT:
            const originalActiveCustomerDocument = state.customerDocuments.find(
                (r) => r.id === action.customerDocument.id
            );

            return {
                ...state,
                customerDocuments: replaceInCollection(
                    state.customerDocuments,
                    originalActiveCustomerDocument!,
                    action.customerDocument
                ),
            };
        default:
            return state;
    }
}
