import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { ConfirmationModalProps } from "./ConfirmationModalProps";

export default function ConfirmationModal(props: ConfirmationModalProps) {
    return (
        <Modal
            id={props.id}
            size={props.size || "lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            backdrop={"static"}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.theme === "danger" && (
                        <i data-testid="danger" className="fas fa-exclamation-triangle" style={{ color: "red" }}>
                            &nbsp;
                        </i>
                    )}
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button variant={props.theme} size={"sm"} onClick={props.onOkButtonClick}>
                        Yes
                    </Button>
                    &nbsp;
                    <Button variant={"dark"} size={"sm"} onClick={props.onCloseButtonClick}>
                        No
                    </Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
}
