import { useMemo, useState } from "react";
import { useUsers } from "../contexts/users";
import { Permission } from "../models/Permission";

/**
 * Return the lists of groups and accounts that the current user has access to.
 * Also keeps track of the selected group/account, and updates the list of accounts
 * when group changes. Only checks for Access permission by default, see enrollment param.
 *
 * @param enrollment - Set to true to explicitly check for Enrollment permissions.
 */
export const useMyGroupAccountOptions = (enrollment = false) => {
    const { myPermissions } = useUsers();

    const [selectedGroup, setSelectedGroup] = useState("All");
    const [selectedAccount, setSelectedAccount] = useState("All");

    const { groupOptions, accountOptions } = useMemo(() => {
        const permissions = myPermissions.filter((p) =>
            enrollment
                ? p.permissionId === Permission.Enrollment
                : p.permissionId === Permission.Access || p.permissionId === Permission.Enrollment
        );
        return {
            groupOptions: ["All", ...new Set(permissions.map((p) => p.groupNumber))],
            accountOptions: [
                "All",
                ...new Set(
                    permissions
                        .filter((p) => selectedGroup === "All" || p.groupNumber === selectedGroup)
                        .map((p) => p.accountNumber + " - " + p.accountName)
                ),
            ],
        };
    }, [enrollment, myPermissions, selectedGroup]);

    return { groupOptions, accountOptions, selectedGroup, setSelectedGroup, selectedAccount, setSelectedAccount };
};
