import LightSection from "../page-sections/LightSection";
import ManageUsersList from "./admin-users/ManageUsersList";
import React from "react";

const AdminUsers = () => {
    return (
        <LightSection>
            <ManageUsersList />
        </LightSection>
    );
};

export default AdminUsers;
