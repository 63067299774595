import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ICustomerImportFileService } from "./ICustomerImportFileService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { CustomerImportFile } from "../../models/CustomerImportFile";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import {
    ADD_CUSTOMER_IMPORT_FILE_SUCCESS,
    DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS,
    EDIT_CUSTOMER_IMPORT_FILE_SUCCESS,
} from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import axios from "axios";

@injectable()
export class CustomerImportFileService implements ICustomerImportFileService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createCustomerImportFile(customerImportFile: FormData): Promise<void | CustomerImportFile> {
        return this.httpClient
            .postFormData(customerImportFile, "customer-import-file")
            .then((t: any) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Customer Import File created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a Customer Import File. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    createCustomerImportFiles(customerImportFile: FormData): Promise<void | CustomerImportFile> {
        return axios
            .post("/customer-import-file", customerImportFile, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((t: any) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerImportFile "${t.id}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a Customer Import File. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editCustomerImportFile(
        customerImportFile: CustomerImportFile,
        newCustomerImportFile: CustomerImportFile
    ): Promise<void | CustomerImportFile> {
        const operations = createPatch(customerImportFile, newCustomerImportFile);
        return this.httpClient
            .patch<CustomerImportFile>(`/customer-import-file/${customerImportFile.id}`, operations)
            .then((patchedCustomerImportFile) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedCustomerImportFile.id}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedCustomerImportFile;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing customerImportFile "${customerImportFile.id}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteCustomerImportFile(customerImportFile: CustomerImportFile): Promise<void | CustomerImportFile> {
        return this.httpClient
            .delete(`/customer-import-file/${customerImportFile.id}`)
            .then(() => {
                const returnCustomerImportFile = { ...customerImportFile, isActive: !customerImportFile.isActive };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerImportFile "${customerImportFile.id}" ${
                        customerImportFile.isActive ? " deactivated" : " reactivated"
                    } successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnCustomerImportFile;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the customerImportFile. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportFiles(): Promise<void | CustomerImportFile[]> {
        return this.httpClient
            .get<CustomerImportFile[]>(`/customer-import-file`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customerImportFiles. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportFileById(id: number): Promise<void | CustomerImportFile> {
        return this.httpClient
            .get<CustomerImportFile>(`/customer-import-file/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customerImportFile. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportJsonFileById(id: number): Promise<void | any[]> {
        return this.httpClient
            .get<any[]>(`/customer-import-file/get-file/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customerImportFile. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
