import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MenuOptionSection } from "../../constants/GroupAdminMenuOptions";

interface LinkProps {
    options: MenuOptionSection;
}

const GroupAdminLinks = ({ options }: LinkProps) => {
    return (
        <Stack>
            <h4> {options.title} </h4>
            {options.items.map((item) => {
                return (
                    <Link key={item.title} to={item.path}>
                        {item.title}
                    </Link>
                );
            })}
        </Stack>
    );
};

export default GroupAdminLinks;
