import { ComponentType } from "react";
import AppSettings from "../components/app-settings/AppSettings";
import Tenants from "../components/tenants/Tenants";
import Users from "../components/users/Users";
import MyInsurance from "../components/my-insurance/MyInsurance";
import AdminUsers from "../components/super-admin/AdminUsers";
import AppConfigurations from "../components/super-admin/AppConfigurations";
import MemberClaimInquiry from "../components/my-claims/MemberClaimInquiry";
import CoverageInquiry from "../components/coverage-inquiry/CoverageInquiry";
import ClaimInquiry from "../components/claim-inquiry/ClaimInquiry";
import { UserTypes } from "../models/UserTypes";
import { User } from "../models/User";
import RegisterUser from "../components/users/forms/RegisterUser";
import GroupAdminLanding from "../components/group-admin/GroupAdminLanding";
import AccessPermission from "../components/users/forms/AccessPermission";
import { Billing } from "../components/billing/Billing";
import AddNewMember from "../components/group-admin/AddNewMember";
import MemberEnrollment from "../components/group-admin/MemberEnrollment";
import { UpdateEnrollment } from "../components/group-admin/UpdateEnrollment";

interface RouteDefinition {
    path: string;
    component?: ComponentType<any>;
    condition: boolean;
}

export const getUserRoutes: (userType: UserTypes, loggedInUser: User | undefined) => RouteDefinition[] = (
    userType: UserTypes,
    loggedInUser: User | undefined
) => {
    const isGlobalAdmin = userType === UserTypes.GlobalAdmin;
    const isSuperAdmin = userType === UserTypes.SuperAdmin;
    const isMember = userType === UserTypes.Member;
    const isProvider = userType === UserTypes.Provider;
    const isGroupAdmin = userType === UserTypes.GroupAdmin;

    const routes: RouteDefinition[] = [
        // Group Admin Routes
        { path: "/billing", component: Billing, condition: !!loggedInUser && isGroupAdmin },
        { path: "/group-admin", component: GroupAdminLanding, condition: isGroupAdmin },
        { path: "/add-new-member", component: AddNewMember, condition: !!loggedInUser && isGroupAdmin },
        { path: "/member-enrollment", component: MemberEnrollment, condition: !!loggedInUser && isGroupAdmin },
        { path: "/update-enrollment", component: UpdateEnrollment, condition: !!loggedInUser && isGroupAdmin },

        // Global Admin Routes
        { path: "/app-settings", component: AppSettings, condition: isGlobalAdmin },
        { path: "/tenants", component: Tenants, condition: isGlobalAdmin },
        { path: "/users", component: Users, condition: isGlobalAdmin },

        // Provider Routes
        {
            path: "/coverage-inquiry/coverage-summary",
            component: MyInsurance,
            condition: !!loggedInUser && (isProvider || isGroupAdmin),
        },

        // Super Admin Routes
        { path: "/admin-users", component: AdminUsers, condition: !!loggedInUser && isSuperAdmin },
        { path: "/app-configurations", component: AppConfigurations, condition: !!loggedInUser && isSuperAdmin },
        { path: "/register-user", component: RegisterUser, condition: !!loggedInUser && isSuperAdmin },
        { path: "/access-permission/:userId", component: AccessPermission, condition: !!loggedInUser && isSuperAdmin },

        // Member Routes
        { path: "/my-insurance", component: MyInsurance, condition: !!loggedInUser && isMember },
        { path: "/my-claims", component: MemberClaimInquiry, condition: !!loggedInUser && isMember },

        // Universal Routes
        { path: "/coverage-inquiry", component: CoverageInquiry, condition: true },
        { path: "/claim-inquiry", component: ClaimInquiry, condition: true },
    ];
    return routes;
};
