import { useMemo, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useMembers } from "../../contexts/members";
import IdCard from "../coverage-inquiry/IdCard";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { getFileClaimPath } from "../../helper-functions/getFileClaimPath";
import { useReactToPrint } from "react-to-print";
import dentalForm from "../../claim-forms/Canopy_DentalClaimForm_JAD_122022.pdf";
import visionForm from "../../claim-forms/Canopy_Vision_form_JAD_122022.pdf";

export default function PageTitle() {
    const location = useLocation();
    const { benefits, isMember, hasCoverage } = useMembers();
    const [showIdModal, setShowIdModal] = useState<boolean>(false);
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant } = useCurrentTenant(decodedAccessToken.tenant_id);

    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            let newContent: HTMLDivElement | null = componentRef?.current?.cloneNode(true) as HTMLDivElement;
            newContent?.setAttribute("style", "background-color: white; height: 100%; transform: scale(0.7);");
            return newContent;
        },
    });

    const titles = [
        {
            path: "providers/locate/dental",
            title: "Locate a Dental Provider",
        },
        {
            path: "providers/locate/vision",
            title: "Locate a Vision Provider",
        },
        {
            path: "providers/locate",
            title: "Locate a Provider",
        },
        {
            path: "coverage-inquiry/coverage-summary",
            title: "Coverage Summary",
        },
        {
            path: "coverage-inquiry",
            title: "Coverage Inquiry",
        },
        {
            path: "claim-inquiry",
            title: "Claim Inquiry",
        },
        {
            path: "my-claims",
            title: "My Claims",
        },
        {
            path: "my-insurance",
            title: "My Insurance",
        },
        {
            path: "claim-submission",
            title: "Claim Submission",
        },
        {
            path: "claim",
            title: "Claim Details",
        },
        {
            path: "app-settings",
            title: "App Settings",
        },
        {
            path: "users",
            title: "Users",
        },
        {
            path: "tenants",
            title: "Tenants",
        },
        {
            path: "group-admin",
            title: "Group Admin",
        },
        {
            path: "admin",
            title: "Admin",
        },
        {
            path: "app-configurations",
            title: "App Configurations",
        },
        {
            path: "register-user",
            title: "Register User",
        },
        {
            path: "access-permission",
            title: "Access Permission",
        },
        {
            path: "billing",
            title: "Billing",
        },
        {
            path: "add-new-member",
            title: "Add New Member",
        },
        {
            path: "member-enrollment",
            title: "Member Enrollment",
        },
        {
            path: "update-enrollment",
            title: "Update Enrollment",
        },
    ];

    const getPageTitle = (): string => {
        const title = titles.find((t) => location.pathname.includes(t.path));
        if (title) {
            return title.title;
        }
        return "";
    };

    const showIdCardLink = useMemo((): boolean => {
        return location.pathname.includes("my-insurance") && benefits !== undefined && isMember;
    }, [location, benefits, isMember]);

    const showFileClaimLink = useMemo((): boolean => {
        return location.pathname.includes("claim-inquiry");
    }, [location]);

    const showMemberFileClaimLinks = useMemo((): boolean => {
        return isMember && (location.pathname.includes("my-insurance") || location.pathname.includes("my-claims"));
    }, [location, isMember]);

    return (
        <div className="page-title py-2">
            <Container className="d-flex justify-content-between">
                <div>{getPageTitle()}</div>
                <div>
                    {showFileClaimLink && currentTenant?.providerType && (
                        <a
                            href={getFileClaimPath(currentTenant)}
                            target={"_blank"}
                            className="btn btn-page-title ms-2"
                            rel="noreferrer"
                        >
                            Claim Form
                        </a>
                    )}
                    {showMemberFileClaimLinks && hasCoverage("dental") && (
                        <a href={dentalForm} target={"_blank"} className="btn btn-page-title ms-2" rel="noreferrer">
                            Dental Claim
                        </a>
                    )}
                    {showMemberFileClaimLinks && hasCoverage("vision") && (
                        <a href={visionForm} target={"_blank"} className="btn btn-page-title ms-2" rel="noreferrer">
                            Vision Claim
                        </a>
                    )}
                    {showIdCardLink && (
                        <Button variant="" onClick={() => setShowIdModal(true)} className="btn-page-title ms-2">
                            View ID Card
                        </Button>
                    )}
                </div>
            </Container>
            <Modal show={showIdModal} onHide={() => setShowIdModal(false)} centered>
                <Modal.Header className="p-2" closeButton>
                    ID Card
                </Modal.Header>
                <Modal.Body>
                    <IdCard ref={componentRef} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowIdModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        Print
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
