import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { RegisterUserFormModel } from "./RegisterUserFormModel";
import { UserTypes } from "../../../models/UserTypes";
import { Row, Form as BootstrapForm, Col, Button, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { User } from "../../../models/User";
import { Roles } from "../../../models/Role";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../state/AppState";
import { Tenant } from "../../../models/Tenant";
import { useUsers } from "../../../contexts/users";
import LightSection from "../../page-sections/LightSection";
import { ManageUser } from "../../../models/ManageUser";
import { useHistory } from "react-router-dom";
import { getTenants } from "../../../actions/tenants/TenantActionCreators";
import KeysysSelect from "../../form/select/KeysysSelect";
import KeysysInput from "../../form/input/KeysysInput";

interface RegisterUserProps {
    userToEdit?: ManageUser;
    onSaveChanges?: (user: ManageUser) => void;
}

export default function RegisterUser(props: RegisterUserProps) {
    const [isCreateError, setIsCreateError] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { userToEdit, onSaveChanges } = props;
    const { createUser } = useUsers();
    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const claimTenantName = decodedAccessToken.tenant_name;
    const tenants = useSelector<AppState, Tenant[]>((state) => state.tenantState.tenants);
    const getInitialValues: () => RegisterUserFormModel = () => ({
        userType: UserTypes.GroupAdmin,
        userId: userToEdit?.username || "",
        firstName: userToEdit?.firstName || "",
        lastName: userToEdit?.lastName || "",
        emailAddress: userToEdit?.email || "",
        phoneNumber: userToEdit?.phoneNumber || "",
        jobTitle: userToEdit?.jobTitle || "",
    });

    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

    const registerUserSchema = Yup.object<RegisterUserFormModel>().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        userId: Yup.string().required("User Id is required"),
        emailAddress: Yup.string().required("Email Address is required").email("Invalid email format"),
        phoneNumber: Yup.string().matches(phoneRegExp, "Invalid Phone Number"),
    });

    const getUserTypeOptions = () => {
        return [{ value: UserTypes.GroupAdmin, label: UserTypes.GroupAdmin }];
    };

    const getTenantIdToSave = useCallback((): number => {
        return tenants.filter((t) => t.name === claimTenantName)[0]?.id;
    }, [claimTenantName, tenants]);

    const getTenantNameToSave = useCallback(
        (selectedTenantId: number): string => {
            return selectedTenantId ? tenants.filter((t) => t.id === selectedTenantId)[0].name : claimTenantName;
        },
        [claimTenantName, tenants]
    );

    useEffect(() => {
        dispatch(getTenants());
    }, [dispatch]);

    async function handleCreateUserSubmit(values: RegisterUserFormModel) {
        if (!userToEdit) {
            const tenantId = getTenantIdToSave();
            const userToRegister: User = {
                id: 0,
                firstName: values.firstName,
                lastName: values.lastName,
                emailAddress: values.emailAddress,
                phoneNumber: values.phoneNumber,
                jobTitle: values.jobTitle,
                userName: values.userId,
                isActive: true,
                roleId: Roles.GroupAdmin,
                tenantId: tenantId,
                tenantName: getTenantNameToSave(tenantId),
                mfaEnabled: false,
                mfaSetupComplete: false,
                mfaOptionId: 1,
            };
            const createdUser = await createUser(userToRegister);
            if (!!createdUser) {
                history.push("/access-permission/" + createdUser.id);
            } else {
                setIsCreateError(true);
            }
        }
    }

    const handleEditUserSubmit = (values: RegisterUserFormModel) => {
        onSaveChanges &&
            onSaveChanges({
                userType: values.userType,
                id: userToEdit?.id || 0,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.emailAddress,
                phoneNumber: values.phoneNumber,
                jobTitle: values.jobTitle || "",
                username: values.userId,
            });
    };

    const handleCancel = () => {
        history.push("/admin-users");
    };

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={registerUserSchema}
                onSubmit={userToEdit ? handleEditUserSubmit : handleCreateUserSubmit}
            >
                {(formProps: FormikProps<RegisterUserFormModel>) => {
                    return (
                        <LightSection>
                            <Form id="registerUserForm">
                                <BootstrapForm.Group as={Col} xl="6" className="mx-auto">
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={6}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id="userType"
                                                placeholder="Select"
                                                fieldName="userType"
                                                label="User Type"
                                                options={getUserTypeOptions()}
                                                multiple={false}
                                                disabled={!!userToEdit}
                                                required
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <KeysysInput
                                                formProps={formProps}
                                                id="userId"
                                                placeholder=""
                                                fieldName="userId"
                                                label="User Id"
                                                disabled={!!userToEdit}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={6}>
                                            <KeysysInput
                                                formProps={formProps}
                                                id="firstName"
                                                placeholder=""
                                                fieldName="firstName"
                                                label="First Name"
                                                required
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <KeysysInput
                                                formProps={formProps}
                                                id="lastName"
                                                placeholder=""
                                                fieldName="lastName"
                                                label="Last Name"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={6}>
                                            <KeysysInput
                                                formProps={formProps}
                                                id="emailAddress"
                                                placeholder=""
                                                fieldName="emailAddress"
                                                label="Email Address"
                                                required
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <BootstrapForm.Label>Phone Number</BootstrapForm.Label>
                                            <BootstrapForm.Control
                                                type="text"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                placeholder=""
                                                onChange={formProps.handleChange}
                                                value={formProps.values.phoneNumber}
                                                isInvalid={
                                                    !!(formProps.touched.phoneNumber && formProps.errors.phoneNumber)
                                                }
                                            />
                                            <BootstrapForm.Control.Feedback type="invalid">
                                                {formProps.errors.phoneNumber}
                                            </BootstrapForm.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col xs={12}>
                                            <BootstrapForm.Label>Job Title</BootstrapForm.Label>
                                            <BootstrapForm.Control
                                                type="text"
                                                id="jobTitle"
                                                name="jobTitle"
                                                placeholder=""
                                                onChange={formProps.handleChange}
                                                value={formProps.values.jobTitle}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"mb-2 justify-content-end"}>
                                        <Col xs={"auto"}>
                                            <Button
                                                type="submit"
                                                className="btn-primary ml-auto"
                                                disabled={!formProps.dirty}
                                            >
                                                {userToEdit ? "Save Changes" : "Create User"}
                                            </Button>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <Button variant="secondary" onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                    {isCreateError && (
                                        <Alert variant="danger">
                                            Email Address or User Id already exists in system.
                                        </Alert>
                                    )}
                                </BootstrapForm.Group>
                            </Form>
                        </LightSection>
                    );
                }}
            </Formik>
        </>
    );
}
