import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { AppState } from "../../state/AppState";
import _ from "lodash";
import { SsoService } from "../../models/SsoService";
import { useEffect, useMemo } from "react";
import useAppRoutes from "../../hooks/useAppRoutes";
import { useUsers } from "../../contexts/users";
import React from "react";
import { Toaster } from "../toast/Toaster";

export default function App() {
    const {
        userType,
        userState: { loggedInUser },
    } = useUsers();
    const { renderedRoutes, renderedRedirects } = useAppRoutes(userType, loggedInUser);
    const ssoService = useMemo(() => new SsoService(), []);
    const { decodedAccessToken, isAuthenticated } = useSelector((state: AppState) => state.authenticationState);

    const isAuthenticatedUser = isAuthenticated && decodedAccessToken?.tenant_name;

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
        vh = window.innerHeight * 0.01;
        debouncedSetViewportHeight(vh);
    });

    const debouncedSetViewportHeight = _.debounce((vh: number) => {
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, 100);

    useEffect(() => {
        if (!isAuthenticatedUser) {
            ssoService.signIn();
        }
    }, [isAuthenticatedUser, ssoService]);

    if (isAuthenticatedUser)
        return (
            <div className="main-content">
                <Toaster />
                {/* As new routes are added, be sure to update components/header/PageTitle */}
                <Switch>
                    {renderedRoutes}
                    {renderedRedirects}
                </Switch>
            </div>
        );

    return null;
}
