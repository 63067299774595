import { Identifiable } from "../models/Identifiable";
import { Operable } from "../models/Operable";

export interface IAction<TAction, TModel> {
    type: TAction;
    payload: TModel | TModel[] | Operable<TModel> | undefined;
}

export const isT = <T extends Identifiable>(payload: any): payload is T => {
    return (payload as T).id !== undefined;
};

export const isArray = <T>(payload: any): payload is T[] => {
    return Array.isArray(payload);
};

export const isOperable = <T>(payload: any): payload is Operable<T> => {
    return (payload as Operable<T>).original !== undefined;
};
