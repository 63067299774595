import { Stepper, Step, StepLabel } from "@mui/material";
import { Button } from "react-bootstrap";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import React from "react";
import { useHistory } from "react-router";

export default function EnrollmentStepper() {
    const {
        activeStep,
        setActiveStep,
        steps,
        setNextClicked,
        setBackClicked,
        validateDependents,
        setValidateDependents,
        validateCoverage,
        setValidateCoverage,
        discardForm,
    } = useMemberEnrollments();
    const history = useHistory();

    const handleBack = () => {
        setNextClicked(false);
        setBackClicked(true);

        if (activeStep === 2 && validateCoverage) {
            setValidateCoverage(false);
        }
        if (activeStep === 1 && validateDependents) {
            setValidateDependents(false);
        }
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    const handleNext = () => {
        setNextClicked(true);
        setBackClicked(false);
        if (activeStep === 1 && !validateDependents) {
            setValidateDependents(true);
        }
        if (activeStep === 2 && !validateCoverage) {
            setValidateCoverage(true);
        }
    };

    const handleDiscard = () => {
        discardForm();
        history.push("/group-admin");
    };

    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Button disabled={activeStep === 0 || activeStep === 4} onClick={handleBack} className="m-1">
                    Back
                </Button>
                <Button
                    disabled={activeStep === steps.length - 2 || activeStep === 4}
                    onClick={handleNext}
                    className="m-1"
                >
                    Next
                </Button>
                <Button disabled={activeStep === 4} onClick={handleDiscard} className="m-1">
                    Discard and Exit
                </Button>
            </div>
        </div>
    );
}
