import { UserTypes } from "../models/UserTypes";
import { User } from "../models/User";

interface RedirectDefinition {
    from: string;
    to: string;
    condition: boolean;
}

export const getUserRedirects: (userType: UserTypes, loggedInUser: User | undefined) => RedirectDefinition[] = (
    userType: UserTypes,
    loggedInUser: User | undefined
) => {
    const isSuperAdmin = userType === UserTypes.SuperAdmin;
    const isMember = userType === UserTypes.Member;
    const isGroupAdmin = userType === UserTypes.GroupAdmin;

    const redirects: RedirectDefinition[] = [
        { from: "/", to: "/admin-users", condition: !!loggedInUser && isSuperAdmin },
        { from: "/", to: "/group-admin", condition: !!loggedInUser && isGroupAdmin },
        { from: "/", to: "/my-insurance", condition: !!loggedInUser && isMember },
        { from: "/", to: "/coverage-inquiry", condition: !!loggedInUser && !isMember },
    ];

    return redirects;
};
