import { inject, injectable } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { FinosecSystemStatisticReports } from "../../models/FinosecSystemStatistic";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IFinosecSystemStatisticReportsService } from "./IFinosecSystemStatisticReportsService";
@injectable()
export class FinosecSystemStatisticReportsService implements IFinosecSystemStatisticReportsService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getFinosecSystemStatisticsReportsTenantId(tenantId: number): Promise<void | FinosecSystemStatisticReports> {
        return this.httpClient
            .get<FinosecSystemStatisticReports>(`/finosec-system-statistics/tenant/${tenantId}/full`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving system statistics. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
