import React, { useState } from "react";
import { Alert, Button, Col, Collapse, Modal, Row, Table } from "react-bootstrap";
import { useMembers } from "../../contexts/members";
import { getFormattedDateFromDbValue } from "../../helper-functions/getFormattedDateFromDbValue";
import { LoadingSpinner } from "../loading/Loading";
import { CoverageInformation } from "../../models/MyInsuranceInfo";
import { InsuranceMember } from "../../models/InsuranceMember";

export default function CoverageInformationView() {
    const { getMyPlanDetailPdf, hasPdfError, setHasPdfError, isGettingPdf, myInsuranceInfo } = useMembers();
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const toggleRowExpand = (row: string) => {
        setExpandedRows((current) => {
            if (current.includes(row)) {
                return current.filter((x) => x !== row);
            } else {
                return [...current, row];
            }
        });
    };

    if (myInsuranceInfo)
        return (
            <>
                <Row>
                    <Col>
                        <h5 className="border border-bottom-0 p-3 mb-0 form-label">Coverage Information</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Product</th>
                                    {/* <th>Benefit Classification</th> */}
                                    <th>Effective Date</th>
                                    <th>Product Plan</th>
                                    <th>Coverage Option</th>
                                    {/* <th>Volume</th> */}
                                    {/* <th>Last Premium Billed</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {myInsuranceInfo.coverageInformation.map((x: CoverageInformation, i: number) => (
                                    <React.Fragment key={`ci-${x.productPlan}-${i}`}>
                                        <tr>
                                            <td>
                                                <Button size="sm" onClick={() => toggleRowExpand(`ci-${i}`)}>
                                                    <i
                                                        className={
                                                            expandedRows.includes(`ci-${i}`)
                                                                ? `fas fa-minus`
                                                                : `fas fa-plus`
                                                        }
                                                    />
                                                </Button>
                                            </td>
                                            <td>{x.product}</td>
                                            <td>{getFormattedDateFromDbValue(x.effectiveDate)}</td>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => getMyPlanDetailPdf(x.productPlan)}
                                                    disabled={isGettingPdf}
                                                >
                                                    {x.product} {x.productPlan}
                                                </Button>
                                            </td>
                                            <td>{x.coverageOption}</td>
                                        </tr>
                                        <Collapse in={expandedRows.includes(`ci-${i}`)}>
                                            <tr>
                                                <td />
                                                <td colSpan={6}>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Status</th>
                                                                <th>Name</th>
                                                                <th>Effective Date</th>
                                                                <th>Termination Date</th>
                                                                <th>Relationship</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {x.members.map((y: InsuranceMember, i: number) => (
                                                                <React.Fragment key={`im-${y.name}-${i}`}>
                                                                    <tr>
                                                                        <td>{y.status}</td>
                                                                        <td>{y.name}</td>
                                                                        <td>
                                                                            {getFormattedDateFromDbValue(
                                                                                y.effectiveDate
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {getFormattedDateFromDbValue(
                                                                                y.terminationDate || ""
                                                                            )}
                                                                        </td>
                                                                        <td>{y.relationship}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Modal
                    centered
                    show={hasPdfError || isGettingPdf}
                    onHide={hasPdfError ? () => setHasPdfError(false) : undefined}
                >
                    {isGettingPdf && (
                        <Modal.Body as={Alert} variant="success" className="m-0">
                            <LoadingSpinner />
                        </Modal.Body>
                    )}
                    {hasPdfError && (
                        <>
                            <Modal.Body as={Alert} variant="danger" className="m-0">
                                There was a problem retrieving the plan information. For more assistance, please call
                                Customer Service at{" "}
                                <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                                    (205) 451-0444
                                </a>
                                .
                            </Modal.Body>
                            {hasPdfError && (
                                <Modal.Footer>
                                    <Button onClick={() => setHasPdfError(false)}>Continue</Button>
                                </Modal.Footer>
                            )}
                        </>
                    )}
                </Modal>
            </>
        );

    return (
        <p>
            Search returned no results. Before denying coverage, please call Customer Service at{" "}
            <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                (205) 451-0444
            </a>
            .
        </p>
    );
}
