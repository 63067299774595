import { useFetch } from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { ManageUser } from "../../../models/ManageUser";
import { Table } from "react-bootstrap";
import SmallSquareEditButton from "../../buttons/SmallSquareEditButton/SmallSquareEditButton";
import SmallSquareDeleteButton from "../../buttons/SmallSquareDeleteButton/SmallSquareDeleteButton";
import { UserTypes } from "../../../models/UserTypes";
import ConfirmationModal from "../../modal/ConfirmationModal/ConfirmationModal";
import EditUserModal from "./EditUserModal";
import { LoadingSpinner } from "../../loading/Loading";

const ManageUsersList = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<ManageUser[]>([]);
    const [userToDelete, setUserToDelete] = useState<ManageUser>();
    const [userToEdit, setUserToEdit] = useState<ManageUser>();
    const [editErrorMessage, setEditErrorMessage] = useState<string>();

    const { get, del, put } = useFetch();

    const startDeleteUser = (user: ManageUser) => {
        setUserToDelete(user);
    };
    const startEditUser = (user: ManageUser) => {
        setUserToEdit(user);
    };

    const cancelEditUser = () => {
        setUserToEdit(undefined);
        setEditErrorMessage(undefined);
    };

    const handleDeleteUser = async () => {
        const res = await del(`users/hard/${userToDelete?.id}`);
        if (res) removeDeletedUser(userToDelete);
    };

    const handleSaveChanges = async (user: ManageUser) => {
        const res = await put<ManageUser>(`users/manageable-user/${user.id}`, user, (e) =>
            setEditErrorMessage(e.error)
        );
        if (res) refreshUpdatedUser(user);
    };

    const refreshUpdatedUser = (userToUpdate: ManageUser) => {
        setEditErrorMessage(undefined);
        const indexToUpdate = users.findIndex((user) => user.id === userToUpdate.id);
        if (indexToUpdate !== -1) {
            const updatedUsers = [userToUpdate, ...users.slice(0, indexToUpdate), ...users.slice(indexToUpdate + 1)];
            setUsers(updatedUsers);
        }
        setUserToEdit(undefined);
    };

    const removeDeletedUser = (userToDelete?: ManageUser) => {
        if (userToDelete) {
            const updatedUsers = users.filter((user) => user.id !== userToDelete.id);
            setUsers(updatedUsers);
        }
        setUserToDelete(undefined);
    };

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true);
            const users = await get<ManageUser[]>("users/manageable-users");
            if (users) setUsers(users);
            setLoading(false);
        };
        getUsers();
    }, []);

    interface ActionsProps {
        user: ManageUser;
    }

    const ConfirmationModalContent = (props: { user?: ManageUser }) => {
        const { user } = props;
        return (
            <>
                {user && (
                    <p>
                        Do you really want to delete
                        <span className={"text-danger ms-1"}>{user.email.toLowerCase()}</span>
                    </p>
                )}
                <p>
                    <strong>This action cannot be undone.</strong>
                </p>
            </>
        );
    };

    const Actions = (props: ActionsProps) => {
        const { user } = props;
        return (
            <div className={"d-flex justify-content-around"}>
                <SmallSquareEditButton
                    className={!(user.userType === UserTypes.GroupAdmin) ? "disabled" : ""}
                    entityNameSingular={"User"}
                    onClick={() => startEditUser(props.user)}
                />
                <SmallSquareDeleteButton
                    className={user.userType === UserTypes.SuperAdmin ? "disabled" : ""}
                    isActive
                    entityNameSingular={"User"}
                    onClick={() => startDeleteUser(props.user)}
                />
            </div>
        );
    };

    if (loading) {
        return (
            <>
                <LoadingSpinner />
            </>
        );
    }
    return (
        <>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr className={"text-center"}>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>User Id</th>
                        <th>User Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.email}>
                            <td className={"text-capitalize"}>{user.firstName}</td>
                            <td className={"text-capitalize"}>{user.lastName}</td>
                            <td>{user.email.toLowerCase()}</td>
                            <td>{user.username?.toLowerCase()}</td>
                            <td className={"text-capitalize"}>{user.userType}</td>
                            <td>
                                <Actions user={user} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ConfirmationModal
                id="confirmDeleteUserModal"
                show={!!userToDelete}
                size={"sm"}
                title="Delete User?"
                message={<ConfirmationModalContent user={userToDelete} />}
                onCloseButtonClick={() => setUserToDelete(undefined)}
                onOkButtonClick={handleDeleteUser}
                theme={"danger"}
            />
            {userToEdit && (
                <EditUserModal
                    id="editUserModal"
                    show={!!userToEdit}
                    userToEdit={userToEdit}
                    title="Manage User Profile"
                    theme={"primary"}
                    onCancelButtonClick={cancelEditUser}
                    onSaveChanges={handleSaveChanges}
                    errorMessage={editErrorMessage}
                />
            )}
        </>
    );
};

export default ManageUsersList;
