import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useClaims } from "../../contexts/claims";
import { useMembers } from "../../contexts/members";
import { useUsers } from "../../contexts/users";
import { getDateStringForInput } from "../../helper-functions";
import { MemberClaimsRequest } from "../../models/ClaimSearch";
import Claims from "../claim-inquiry/Claims";
import { LoadingSpinner } from "../loading/Loading";
import DarkSection from "../page-sections/DarkSection";
import LightSection from "../page-sections/LightSection";

export default function MemberClaimInquiry() {
    const { getMemberClaims, claimsFilter, setClaimsFilter } = useClaims();
    const { myInsuranceInfo, isMember, member } = useMembers();
    const {
        userState: { loggedInUser },
    } = useUsers();
    const getInitialStartDate = useCallback((): string => {
        const now = new Date();
        const date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        return getDateStringForInput(date);
    }, []);
    const getMinimumDate = useCallback((): string => {
        const now = new Date();
        const date = new Date(now.getFullYear() - 3, now.getMonth(), now.getDate());
        return getDateStringForInput(date);
    }, []);
    const [startDateInput, setStartDateInput] = useState<string>(getInitialStartDate());
    const [endDateInput, setEndDateInput] = useState<string>(getDateStringForInput());
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [hasCompletedInitialFetch, setHasCompleteInitialFetch] = useState(false);

    const getClaims = useCallback(async () => {
        setHasLoaded(false);
        setHasSubmitted(true);
        if (!loggedInUser) {
            return;
        }
        setIsLoading(true);
        const request: MemberClaimsRequest = {
            participantIdNumber: isMember ? loggedInUser.participantIdNumber! : member?.participantIDNumber || "",
            startDate: startDateInput,
            endDate: endDateInput,
        };
        await getMemberClaims(request);
        setIsLoading(false);
        setHasLoaded(true);
        setHasCompleteInitialFetch(true);
    }, [loggedInUser, getMemberClaims, startDateInput, endDateInput, isMember, member]);

    const handleGetClaims = useCallback(
        async (e) => {
            e.preventDefault();
            await getClaims();
        },
        [getClaims]
    );

    useEffect(() => {
        if (!hasCompletedInitialFetch) {
            getClaims();
        }
    }, [hasCompletedInitialFetch, getClaims]);

    const getPatientOptions = useCallback((): any[] => {
        if (!myInsuranceInfo) {
            return [];
        }
        return myInsuranceInfo.enrollmentInformation
            .map((x) => ({
                value: `${x.firstName} ${x.lastName}`,
                name: `${x.firstName} ${x.lastName}`,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [myInsuranceInfo]);

    return (
        <>
            <DarkSection>
                <Form>
                    <Row>
                        <Col xl={3} md={4}>
                            <Form.Group controlId="patient">
                                <Form.Label>Patient</Form.Label>
                                <Form.Select value={claimsFilter} onChange={(e) => setClaimsFilter(e.target.value)}>
                                    <option value="">All</option>
                                    {getPatientOptions().map((x) => (
                                        <option key={x.value} value={x.value}>
                                            {x.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xl={3} md={3}>
                            <Form.Group controlId="startDate">
                                <Form.Label>From Service Date</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type="date"
                                        value={startDateInput}
                                        onChange={(e) => setStartDateInput(e.target.value)}
                                        min={getMinimumDate()}
                                    />
                                    {!!startDateInput && (
                                        <Button
                                            size="sm"
                                            variant="outline-secondary"
                                            onClick={() => setStartDateInput("")}
                                            className="ms-1"
                                        >
                                            <i className="fas fa-times" />
                                        </Button>
                                    )}
                                </div>
                                <Form.Text>Up to 3 years prior.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col xl={3} md={3}>
                            <Form.Group controlId="endDate">
                                <Form.Label>To Service Date</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type="date"
                                        value={endDateInput}
                                        onChange={(e) => setEndDateInput(e.target.value)}
                                        min={getMinimumDate()}
                                    />
                                    {!!endDateInput && (
                                        <Button
                                            size="sm"
                                            variant="outline-secondary"
                                            onClick={() => setEndDateInput("")}
                                            className="ms-1"
                                        >
                                            <i className="fas fa-times" />
                                        </Button>
                                    )}
                                </div>
                                <Form.Text>Up to 3 years prior.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col xl={1} md={2}>
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <div>
                                    <Button variant="primary" type="submit" onClick={handleGetClaims}>
                                        Retrieve
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </DarkSection>
            {(isLoading || (hasSubmitted && hasLoaded)) && (
                <LightSection>
                    {isLoading && <LoadingSpinner />}
                    {!isLoading && hasSubmitted && hasLoaded && <Claims />}
                </LightSection>
            )}
        </>
    );
}
