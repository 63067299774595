import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { nullProperties, redBorder } from "../../../cssProperties/CssProperties";
import { KeysysInputProps } from "./KeysysInputProps";
import ErrorMessageText from "../error-message/ErrorMessageText";

export default function KeysysInput(props: KeysysInputProps) {
    return (
        <div>
            <Field name={props.fieldName}>
                {({ field }: FieldProps<any>) => (
                    <div>
                        <label htmlFor={props.id} className="form-label">
                            {props.label}
                            {props.required && <span className="text-danger"> *</span>}
                        </label>
                        <input
                            {...field}
                            value={!field.value ? "" : field.value}
                            id={props.id}
                            type={props.type ? props.type : "text"}
                            className="form-control"
                            placeholder={props.placeholder}
                            disabled={!!props.disabled ? props.disabled : false}
                            style={
                                props.formProps.errors[props.fieldName] && props.formProps.touched[props.fieldName]
                                    ? redBorder
                                    : nullProperties
                            }
                        />
                    </div>
                )}
            </Field>
            <ErrorMessage name={props.fieldName}>{(msg) => <ErrorMessageText message={msg} />}</ErrorMessage>
        </div>
    );
}
