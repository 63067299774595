import { Role } from "../../models/Role";
import { RoleActionType } from "./actions";
import { IAction, isArray } from "..";

export interface RoleState {
    roles: Role[];
}

export const reducer = (state: RoleState, action: IAction<RoleActionType, Role>): RoleState => {
    const { type, payload } = action;
    if (isArray<Role>(payload)) {
        switch (type) {
            case RoleActionType.ROLES_FETCHED:
                return {
                    ...state,
                    roles: payload,
                };
            default:
                return state;
        }
    }
    return state;
};
