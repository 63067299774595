import { Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import { CoverageFormModel } from "./CoverageFormModel";
import { useEffect, useMemo, useRef } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import KeysysSelect from "../form/select/KeysysSelect";
import { PlanCodeDescription, RateLevel } from "../../models/Enrollment";
import KeysysDatetime from "../form/datetime/KeysysDatetime";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import React from "react";

const schema = yup.object<CoverageFormModel>({
    plan: yup.string().required("Required"),
    coverage: yup.string().required("Required"),
    effectiveDate: yup.date().nullable().required("Required"),
});

type CoverageFormProps = {
    title: string;
    plans: PlanCodeDescription[];
    rateLevel: RateLevel;
    shouldSubmit: boolean;
    onSubmit(key: string, values: CoverageFormModel): void;
    initialValues: CoverageFormModel;
    disabled: boolean;
    onDisable(key: string): void;
};

export const CoverageForm = ({
    title,
    plans,
    rateLevel,
    shouldSubmit,
    onSubmit,
    initialValues,
    disabled,
    onDisable,
}: CoverageFormProps) => {
    const { setValidateCoverage, isUpdating, dbMember } = useMemberEnrollments();
    const ref = useRef<FormikProps<any>>(null);

    const planOptions = useMemo(() => {
        return plans.map((p) => {
            const codeDesc = `${p.planCode} - ${p.description}`;
            return { value: codeDesc, label: codeDesc };
        });
    }, [plans]);

    const coverageOptions = useMemo(() => {
        const options = ["Member Only", "Family"];
        if (rateLevel.level === 4) {
            options.splice(2, 0, "Member + Spouse", "Member + Child");
        }
        return options.map((o) => ({ value: o, label: o }));
    }, [rateLevel]);

    const isExistingCoverage = useMemo(() => {
        return isUpdating && !!dbMember?.coverage?.[title];
    }, [dbMember, title, isUpdating]);

    useEffect(() => {
        const handleSubmit = async () => {
            await ref.current?.submitForm();

            if (!ref.current?.isValid) {
                setValidateCoverage(false);
            }
        };
        if (shouldSubmit && !disabled) {
            handleSubmit();
        }
    }, [shouldSubmit, ref, disabled, setValidateCoverage]);

    return (
        <Formik
            innerRef={ref}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => onSubmit(title, values)}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<CoverageFormModel>) => {
                return (
                    <Form id="coverage-form">
                        <Card>
                            <Card.Header className="d-flex align-items-center justify-content-between">
                                <Card.Title>{title}</Card.Title>
                                <Button
                                    size="sm"
                                    variant={disabled ? "secondary" : "danger"}
                                    title={disabled ? "Enable Coverage form" : "Disable Coverage form"}
                                    onClick={() => onDisable(title)}
                                    disabled={isExistingCoverage}
                                >
                                    {disabled ? <i className="fas fa-plus" /> : <i className="fas fa-minus" />}
                                </Button>
                            </Card.Header>
                            <Card.Body style={{ backgroundColor: disabled ? "lightgray" : "" }}>
                                <Row className="mb-2">
                                    <Col>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id="plan"
                                            placeholder="Select a plan option"
                                            fieldName="plan"
                                            label="Plan"
                                            options={planOptions}
                                            multiple={false}
                                            required
                                            disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id="coverage"
                                            placeholder="Select a coverage option"
                                            fieldName="coverage"
                                            label="Coverage Option"
                                            options={coverageOptions}
                                            multiple={false}
                                            required
                                            disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <KeysysDatetime
                                            formProps={formProps}
                                            id="effectiveDate"
                                            placeholder="Select a date"
                                            fieldName="effectiveDate"
                                            label="Effective Date"
                                            required
                                            disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                {isUpdating && (
                                    <Row className="mb-2">
                                        <Col>
                                            <KeysysDatetime
                                                formProps={formProps}
                                                id="TerminationDate"
                                                placeholder="Select a date"
                                                fieldName="terminationDate"
                                                label="Termination Date"
                                                disabled={disabled}
                                                clearable
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        </Card>
                    </Form>
                );
            }}
        </Formik>
    );
};
