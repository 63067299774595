import { Spinner } from "react-bootstrap";

export const LoadingSpinner = ({ size }: { size?: "sm" }) => {
    return (
        <div
            style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <Spinner size={size} animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};
