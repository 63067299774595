import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../images/Canopy_Logo.png";
import { AppState } from "../../state/AppState";

export default function Footer() {
    const { decodedAccessToken, isAuthenticated } = useSelector((state: AppState) => state.authenticationState);

    const isAuthenticatedUser = isAuthenticated && decodedAccessToken?.tenant_name;

    return (
        <div className="keysys-footer">
            <Container className="my-4">
                <Row>
                    <Col sm={4} md={3}>
                        <p className="footer-heading">Canopy Insurance</p>
                        <p>
                            <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                                (205) 451-0444
                            </a>
                        </p>
                        <p>PO Box 3020</p>
                        <p>Tuscaloosa, AL 35403</p>
                    </Col>
                    <Col sm={4} md={2}>
                        <p className="footer-heading">About Us</p>
                        <p>
                            <a href="https://canopyinsurancecorp.com/" target="_blank" rel="noreferrer noopener">
                                Home
                            </a>
                        </p>
                        <p>
                            <a
                                href="https://canopyinsurancecorp.com/why-canopy/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Why Canopy
                            </a>
                        </p>
                        <p>
                            <a
                                href="https://canopyinsurancecorp.com/products/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Products
                            </a>
                        </p>
                    </Col>
                    <Col sm={4} md={3}>
                        <p className="footer-heading">Support</p>
                        <p>
                            <a
                                href="https://canopyinsurancecorp.com/contact-us/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Contact Us
                            </a>
                        </p>
                        <p>
                            <a
                                href="https://canopyinsurancecorp.com/provider-search/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Find a Provider
                            </a>
                        </p>
                        <p>
                            <a
                                href="https://canopyinsurancecorp.com/customer-links/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Customer Links
                            </a>
                        </p>
                    </Col>
                    <Col sm={12} md={4} className="d-flex align-items-center justify-content-end">
                        <Link to="/">
                            <img src={logo} alt="Canopy Logo" className="my-2" style={{ maxWidth: "223px" }} />
                            <span className="footer-heading footer-logo-text">Canopy Insurance</span>
                        </Link>
                    </Col>
                </Row>
                {/* TODO: hiding the Terms and Conditions clause until the link is fixed
                https://www.southlandbenefit.com/GroupWeb3/help/TermsOfUse.asp */}

                {/* <Row className="mt-2">
                    <Col>
                        <p className="footer-small">By accessing this site, you agree to the Terms and Conditions</p>
                        <p className="footer-small">
                            &copy; Copyright Southland Benefit Solutions {new Date().getFullYear()}
                        </p>
                    </Col>
                </Row> */}
            </Container>
        </div>
    );
}
