import React, { useMemo, useState } from "react";
import LightSection from "../page-sections/LightSection";
import { Col, Form as BootstrapForm, Row, Button } from "react-bootstrap";
import { useUsers } from "../../contexts/users";
import { Permission } from "../../models/Permission";
import { Form, Formik, FormikProps } from "formik";
import { NewMemberFormModel } from "./NewMemberFormModel";
import * as Yup from "yup";
import { useMemberEnrollments } from "../../contexts/memberEnrollments/MemberEnrollmentProvider";
import { useHistory } from "react-router-dom";
import KeysysSelect from "../form/select/KeysysSelect";
import KeysysDatetime from "../form/datetime/KeysysDatetime";
import KeysysInput from "../form/input/KeysysInput";

export default function AddNewMember() {
    const history = useHistory();
    const { myPermissions } = useUsers();
    const { setNewMember, setActiveStep, setIsUpdating } = useMemberEnrollments();
    const [selectedGroup, setSelectedGroup] = useState<string>("Select a group");

    const getInitialValues: () => NewMemberFormModel = () => ({
        group: "",
        account: "",
        hireDate: undefined,
        applicationDate: undefined,
        personalInformation: undefined,
        dependent: undefined,
    });

    const newMemberSchema = Yup.object<NewMemberFormModel>().shape({
        group: Yup.string().required("Group is required"),
        account: Yup.string().required("Account is required"),
        hireDate: Yup.date().nullable().required("Hire Date is required").typeError("Hire Date is required"),
        applicationDate: Yup.date()
            .nullable()
            .required("Application Date is required")
            .typeError("Application Date is required"),
    });

    const groupOptionValues = useMemo(() => {
        let groupOptions = [
            ...new Set(
                myPermissions!.filter((ga) => ga.permissionId === Permission.Enrollment).map((ga) => ga.groupNumber)
            ),
        ];
        groupOptions = ["Select a group", ...groupOptions];
        return groupOptions.map((g) => ({ value: g, label: g }));
    }, [myPermissions]);

    const accountOptionValues = useMemo(() => {
        let groupAccounts =
            selectedGroup === "Select a group"
                ? myPermissions!
                      .filter((ga) => ga.permissionId === Permission.Enrollment)
                      .map((ga) => `${ga.accountNumber}-${ga.accountName}`)
                : myPermissions!
                      .filter((ga) => ga.permissionId === Permission.Enrollment && ga.groupNumber === selectedGroup)
                      .map((ga) => `${ga.accountNumber}-${ga.accountName}`);

        let groupAccountOptions = [...new Set(groupAccounts)];

        groupAccountOptions = ["Select an account", ...groupAccountOptions];
        return groupAccountOptions.map((ga) => ({ value: ga, label: ga }));
    }, [myPermissions, selectedGroup]);

    async function handleAddNewMember(values: NewMemberFormModel) {
        setActiveStep(0);
        setNewMember(values);
        setIsUpdating(false);
        sessionStorage.setItem("newMember", JSON.stringify(values));
        history.push("/member-enrollment");
    }

    const handleGroupChange = (e: any) => {
        setSelectedGroup(e?.value);
    };

    return (
        <>
            <Formik initialValues={getInitialValues()} validationSchema={newMemberSchema} onSubmit={handleAddNewMember}>
                {(formProps: FormikProps<NewMemberFormModel>) => {
                    return (
                        <LightSection>
                            <Form id="addMemberForm">
                                <BootstrapForm.Group as={Col} xl="8" className="mx-auto">
                                    <Row className="mb-3">
                                        <Col>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id="group"
                                                placeholder="Select a group"
                                                fieldName="group"
                                                label="Group"
                                                options={groupOptionValues}
                                                multiple={false}
                                                required
                                                onChange={(e) => {
                                                    handleGroupChange(e);
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id="account"
                                                placeholder="Select an account"
                                                fieldName="account"
                                                label="Account"
                                                options={accountOptionValues}
                                                multiple={false}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs="6">
                                            <KeysysDatetime
                                                formProps={formProps}
                                                id="hireDate"
                                                placeholder="Select a date"
                                                fieldName="hireDate"
                                                label="Hire Date"
                                                required
                                            />
                                        </Col>
                                        <Col xs="6">
                                            <KeysysDatetime
                                                formProps={formProps}
                                                id="applicationDate"
                                                placeholder="Select a date"
                                                fieldName="applicationDate"
                                                label="Application Date"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"justify-content-end"}>
                                        <Col xs={"auto"}>
                                            <Button type="submit" className="btn-primary ml-auto">
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </BootstrapForm.Group>
                            </Form>
                        </LightSection>
                    );
                }}
            </Formik>
        </>
    );
}
