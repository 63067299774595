import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { LoadingSpinner } from "../loading/Loading";
import { useState } from "react";

const ProviderLookup = () => {
    const { type } = useParams<{ type: string }>();

    const [isLoading, setIsLoading] = useState(true);

    const title: string = type ? `Locate a ${type[0].toUpperCase() + type.slice(1)} Provider` : "Locate a Provider";

    if (!type)
        return (
            <div className="main-content bg-white">
                <Container className="">
                    <Row className="m-4">
                        <Col className="mx-auto" sm={3}>
                            <Link to="/providers/locate/dental" className="btn btn-primary">
                                Dental Providers
                            </Link>
                        </Col>
                    </Row>
                    <Row className="m-4">
                        <Col className="mx-auto" sm={3}>
                            <Link to="/providers/locate/vision" className="btn btn-primary">
                                Vision Providers
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        );

    return (
        <div className="main-content bg-white">
            <Container>
                <Row className="pt-2">
                    {!!type && isLoading && <LoadingSpinner />}
                    {!!type && (
                        <iframe
                            title={title}
                            style={{ height: "75vh" }}
                            onLoad={() => setIsLoading(false)}
                            src={
                                type === "dental"
                                    ? "https://legacy.southlandbenefit.com/DentaNet.aspx"
                                    : "https://legacy.southlandbenefit.com/VisaNet.aspx"
                            }
                        />
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default ProviderLookup;
