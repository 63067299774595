import React from "react";
import { Alert, Button, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";

export default function Confirmation() {
    const history = useHistory();
    const { filename, uploadSuccess, discardForm } = useMemberEnrollments();
    const variant = uploadSuccess ? "success" : "danger";

    const handleOk = () => {
        discardForm();
        history.push("/group-admin");
    };
    return (
        <div className="d-flex justify-content-center">
            <Col xs="8">
                <Alert variant={variant}>
                    {uploadSuccess ? (
                        <div>
                            {`File ${filename} has been submitted.`}
                            <br />
                            <br />
                            Please allow up to 2 business days for your request to be processed.
                            <br />
                            <br />
                            To exit this screen, select OK.
                        </div>
                    ) : (
                        <div>
                            {`File ${filename} submission failed.`}
                            <br />
                            <br />
                            Please try again.
                            <br />
                            <br />
                            To exit this screen, select OK.
                        </div>
                    )}
                    <div className="d-flex justify-content-end">
                        <Button variant={variant} onClick={handleOk}>
                            OK
                        </Button>
                    </div>
                </Alert>
            </Col>
        </div>
    );
}
