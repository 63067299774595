import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ITenantService } from "./ITenantService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Tenant } from "../../models/Tenant";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_TENANT_SUCCESS, DEACTIVATE_TENANT_SUCCESS, EDIT_TENANT_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import { CustomerMenuItemCreate, ICustomerMenuItem } from "../../models/CustomerMenuItem";

@injectable()
export class TenantService implements ITenantService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createTenant(tenant: Tenant): Promise<void | Tenant> {
        return this.httpClient
            .post<Tenant>("/tenants", tenant)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_TENANT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${t.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating tenant. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    deleteCustomerMenuItem(customerMenuItem: ICustomerMenuItem): Promise<void | ICustomerMenuItem> {
        return this.httpClient
            .delete(`/customer-menu-items/${customerMenuItem.id}`)
            .then(() => {
                const returnCustomerMenuItem = { ...customerMenuItem, isActive: false };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_TENANT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Menu item "${customerMenuItem.name}"  deactivated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnCustomerMenuItem;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivate the customer menu item. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editCustomerMenuItem(
        customerMenuItem: ICustomerMenuItem,
        newCustomerMenuItem: ICustomerMenuItem
    ): Promise<void | ICustomerMenuItem> {
        const operations = createPatch(customerMenuItem, newCustomerMenuItem);
        return this.httpClient
            .patch<ICustomerMenuItem>(`/customer-menu-items/${customerMenuItem.id}`, operations)
            .then((patchedCustomerMenuItem) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_TENANT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Customer Menu Item "${patchedCustomerMenuItem.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedCustomerMenuItem;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing menu item "${customerMenuItem.name}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteTenant(tenant: Tenant): Promise<void | Tenant> {
        return this.httpClient
            .delete(`/tenants/${tenant.id}`)
            .then(() => {
                const returnTenant = { ...tenant, isActive: !tenant.isActive };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_TENANT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${tenant.name}" ${tenant.isActive ? " deactivated" : " reactivated"} successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnTenant;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivate the tenant. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getTenants(): Promise<void | Tenant[]> {
        return this.httpClient
            .get<Tenant[]>(`/tenants`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving tenants. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerMenuItems(tenantId: number) {
        return this.httpClient
            .get<ICustomerMenuItem[]>(`/customer-menu-items/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customer menu items"
                )
            );
    }

    createCustomerMenuItem(customerMenuItem: CustomerMenuItemCreate) {
        return this.httpClient
            .post<CustomerMenuItemCreate>("/customer-menu-items", customerMenuItem)
            .then((item) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_TENANT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `menu item "${item.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return item as ICustomerMenuItem;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating customer menu item. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getTenantById(id: number): Promise<void | Tenant> {
        return this.httpClient
            .get<Tenant>(`/tenants/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving tenant. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editTenant(tenant: Tenant, newTenant: Tenant): Promise<void | Tenant> {
        const operations = createPatch(tenant, newTenant);
        return this.httpClient
            .patch<Tenant>(`/tenants/${tenant.id}`, operations)
            .then((patchedTenant) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_TENANT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedTenant.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedTenant;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing tenant "${tenant.name}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }
}
