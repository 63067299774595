import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { useMyGroupAccountOptions } from "../../hooks/groupAdmin";
import LightSection from "../page-sections/LightSection";
import DataTable from "../grid/DataTable";
import { useFetch } from "../../hooks/useFetch";
import { Enrollment, EnrollmentDetail, EnrollmentRequest } from "../../models/Enrollment";
import { LoadingSpinner } from "../loading/Loading";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import { getDateFromDbValue } from "../../helper-functions/getFormattedDateFromDbValue";
import { SSNInputGroup } from "../form/input/SSNInput";

type EnrollmentRow = Omit<Enrollment, "firstName" | "middleName" | "lastName"> & {
    name: string;
};

export const UpdateEnrollment = () => {
    const history = useHistory();

    const { setActiveStep, setIsUpdating, setEnrollmentDetail } = useMemberEnrollments();
    const {
        selectedAccount,
        setSelectedAccount,
        selectedGroup,
        setSelectedGroup,
        groupOptions,
        accountOptions,
    } = useMyGroupAccountOptions(true);
    const { postUnique } = useFetch();

    const filterByOptions = useMemo(() => {
        return ["Member ID", "SSN"];
    }, []);
    const [filterBy, setFilterBy] = useState(filterByOptions[0]);
    const [query, setQuery] = useState("");

    const [enrollments, setEnrollments] = useState<Enrollment[]>([]);
    const [loadingTableData, setLoadingTableData] = useState(false);

    const [enrollment, setEnrollment] = useState<EnrollmentRow>();
    const [loadingEnrollmentDetail, setLoadingEnrollmentDetail] = useState(false);

    const onRetrieve = useCallback(async () => {
        setLoadingTableData(true);
        const data = await postUnique<EnrollmentRequest, Enrollment[]>("enrollments", {
            groupNumber: selectedGroup,
            accountNumber: selectedAccount.split("-")[0].trim(),
            memberId: filterBy === "Member ID" ? query : "",
            govtId: filterBy === "SSN" ? query.slice(-4) : "",
        });
        if (data) {
            setEnrollments(data);
        }
        setLoadingTableData(false);
    }, [selectedAccount, selectedGroup, filterBy, query, postUnique]);

    const onUpdate = useCallback(async () => {
        if (!enrollment) return;
        setLoadingEnrollmentDetail(true);
        const data = await postUnique<EnrollmentRequest, EnrollmentDetail>("enrollments/details", {
            groupNumber: enrollment.group,
            accountNumber: enrollment.account,
            memberId: enrollment.memberId,
        });
        setLoadingEnrollmentDetail(false);
        if (data) {
            setEnrollmentDetail(data);
            setIsUpdating(true);
            setActiveStep(0);
            history.push("/member-enrollment");
        }
    }, [enrollment, setEnrollmentDetail, setIsUpdating, setActiveStep, postUnique]);

    const tableData = useMemo<EnrollmentRow[]>(() => {
        return enrollments.map((e) => {
            return {
                ...e,
                name: `${e.lastName}, ${e.firstName} ${e.middleName ?? ""}`,
                birthDate: e.birthDate ? new Date(e.birthDate).toLocaleDateString() : "-",
            };
        });
    }, [enrollments]);

    const columns: ColumnDef<EnrollmentRow>[] = [
        {
            id: "checkbox",
            cell: ({ row: { original } }) => (
                <input
                    type="checkbox"
                    checked={enrollment?.memberId === original.memberId}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setEnrollment(original);
                        } else {
                            setEnrollment(undefined);
                        }
                    }}
                />
            ),
        },
        {
            header: "Member Name",
            accessorKey: "name",
        },
        {
            header: "Member ID",
            accessorKey: "memberId",
        },
        {
            header: "SSN",
            accessorKey: "govtId",
        },
        {
            header: "Group",
            accessorKey: "group",
        },
        {
            header: "Account",
            accessorKey: "account",
        },
        {
            header: "Birth Date",
            accessorKey: "birthDate",
        },
    ];

    return (
        <LightSection>
            <Row>
                <Col>
                    <p>
                        <span className="bold">Filter Member</span>
                        <br />
                        To request by member, select the filter values below and click Retrieve.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xl={3} md={12} className="mb-3">
                    <Form.Group>
                        <Form.Label>Group</Form.Label>
                        <Form.Select
                            value={selectedGroup}
                            onChange={(e) => {
                                setSelectedGroup(e.target.value);
                                setSelectedAccount("All");
                            }}
                        >
                            {groupOptions.map((group) => (
                                <option key={group} value={group}>
                                    {group}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xl={3} md={12} className="mb-3">
                    <Form.Group>
                        <Form.Label>Account</Form.Label>
                        <Form.Select value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
                            {accountOptions.map((account) => (
                                <option key={account} value={account}>
                                    {account}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xl={3} md={12} className="mb-3">
                    <Form.Group>
                        <Form.Label>Filter By</Form.Label>
                        <Form.Select
                            value={filterBy}
                            onChange={(e) => {
                                setFilterBy(e.target.value);
                                setQuery("");
                            }}
                        >
                            {filterByOptions.map((filter) => (
                                <option key={filter} value={filter}>
                                    {filter}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xl={3} md={12} className="mb-3">
                    {filterBy === "SSN" ? (
                        <SSNInputGroup id="ssn" label="SSN" onChange={(value) => setQuery(value)} />
                    ) : (
                        <Form.Group>
                            <Form.Label>{filterBy}</Form.Label>
                            <Form.Control value={query} onChange={(e) => setQuery(e.target.value)} />
                        </Form.Group>
                    )}
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <Button onClick={onRetrieve} disabled={loadingTableData}>
                        {loadingTableData ? (
                            <div className="p-1">
                                <LoadingSpinner size="sm" />
                            </div>
                        ) : (
                            "Retrieve"
                        )}
                    </Button>
                </Col>
            </Row>
            <hr />
            <Row className="mb-4">
                <Col>
                    <p>Select the member to Update.</p>
                    <Button onClick={onUpdate} disabled={!enrollment}>
                        Update
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable paginated={tableData.length > 20} showGlobalFilter columns={columns} data={tableData} />
                </Col>
            </Row>
        </LightSection>
    );
};
