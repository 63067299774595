import { ReportState } from "../../state/ReportState";
import { ReportActions, REPORTS_FETCHED, CREATE_REPORT, DELETE_REPORT } from "../../actions/reports/ReportActions";
import { replaceInCollection } from "./../ReplaceInCollection";

const initialState: ReportState = {
    reports: [],
};

export function reportReducer(state: ReportState = initialState, action: ReportActions): ReportState {
    switch (action.type) {
        case REPORTS_FETCHED: {
            return {
                ...state,
                reports: action.reports,
            };
        }

        case CREATE_REPORT:
            return {
                ...state,
                reports: [...state.reports, action.report],
            };
        case DELETE_REPORT:
            const originalActiveReport = state.reports.find((r) => r.id === action.report.id);

            return {
                ...state,
                reports: replaceInCollection(state.reports, originalActiveReport!, action.report),
            };
        default:
            return state;
    }
}
