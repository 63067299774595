import { TabState } from "../state/TabState";
import {
    CHANGE_ACTIVE_SUBTAB,
    CHANGE_ACTIVE_TAB,
    GET_SUBTABS,
    GET_TABS,
    GETTING_SUBTABS,
    GETTING_TABS,
    NEW_SUBTABS,
    NEW_TABS,
    TabActionTypes,
} from "../actions/TabActions";
import { Subtab } from "../models/Subtab";

const initialState: TabState = {
    activeTabId: 0,
    activeSubtabId: 0,
    tabs: [],
    activeSubtabs: [],
    allSubtabs: [],
    fetchTabs: false,
    fetchSubtabs: false,
};

export function tabReducer(state: TabState = initialState, action: TabActionTypes): TabState {
    switch (action.type) {
        case CHANGE_ACTIVE_TAB:
            let [filteredSubtabs, activeSubtabId] = filterSubtabsAndGetActiveSubtabId(
                state.allSubtabs,
                action.activeTabId,
                state
            );
            return {
                ...state,
                activeTabId: action.activeTabId,
                activeSubtabs: filteredSubtabs,
                activeSubtabId: +state.activeTabId === +action.activeTabId ? state.activeSubtabId : activeSubtabId,
            };
        case CHANGE_ACTIVE_SUBTAB:
            return {
                ...state,
                activeSubtabId: action.activeTabId,
            };
        case NEW_TABS:
            let activeTabId;
            if (!state.activeTabId || !action.tabs || !action.tabs.find((t) => +t.id === state.activeTabId)) {
                activeTabId = action.tabs && action.tabs.length > 0 ? action.tabs[0].id : 0;
            } else {
                activeTabId = state.activeTabId;
            }
            let [filteredSubtabsNewTabs, activeSubtabIdNewTabs] = filterSubtabsAndGetActiveSubtabId(
                state.allSubtabs,
                activeTabId,
                state
            );
            return {
                ...state,
                activeTabId: activeTabId,
                tabs: action.tabs,
                activeSubtabs: filteredSubtabsNewTabs,
                activeSubtabId: activeSubtabIdNewTabs,
            };
        case NEW_SUBTABS:
            let [filteredSubtabsNewSubtabs, activeSubtabIdNewSubtabs] = filterSubtabsAndGetActiveSubtabId(
                action.subtabs,
                state.activeTabId,
                state
            );
            return {
                ...state,
                allSubtabs: action.subtabs,
                activeSubtabs: filteredSubtabsNewSubtabs,
                activeSubtabId: activeSubtabIdNewSubtabs,
            };
        case GET_TABS:
            return {
                ...state,
                fetchTabs: true,
            };
        case GET_SUBTABS:
            return {
                ...state,
                fetchSubtabs: true,
            };
        case GETTING_TABS:
            return {
                ...state,
                fetchTabs: false,
            };
        case GETTING_SUBTABS:
            return {
                ...state,
                fetchSubtabs: false,
            };
        default:
            return state;
    }
}

function filterSubtabsAndGetActiveSubtabId(
    subtabs: Subtab[] | undefined,
    activeTabId: number,
    state: TabState
): [Subtab[], number] {
    subtabs = !!subtabs ? subtabs : [];
    const subtabsForActiveTab = subtabs.filter((s) => s.tabId === +activeTabId);
    let activeSubtabId = 0;
    if (!state.activeSubtabId || !subtabsForActiveTab.find((s) => +s.id === +state.activeSubtabId)) {
        if (subtabsForActiveTab.length > 0) {
            activeSubtabId = subtabsForActiveTab[0].id;
        }
    } else {
        activeSubtabId = state.activeSubtabId;
    }

    return [subtabsForActiveTab, activeSubtabId];
}
