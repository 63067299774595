import { CustomerReportState } from "../../state/CustomerReportState";
import {
    ADD_CUSTOMER_REPORT_REVIEW_SUCCESS,
    CustomerReportActions,
    CUSTOMER_REPORTS_FETCHED,
    CUSTOMER_REPORTS_REVIEW_FETCHED,
    ALL_CUSTOMER_REPORTS_REVIEW_FETCHED,
    EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS,
    EDIT_CUSTOMER_REPORT_SUCCESS,
} from "../../actions/customerReport/CustomerReportAction";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: CustomerReportState = {
    customerReports: [],
    customerReportReviews: [],
};

export function customerReportReducer(
    state: CustomerReportState = initialState,
    action: CustomerReportActions
): CustomerReportState {
    switch (action.type) {
        case CUSTOMER_REPORTS_FETCHED: {
            return {
                ...state,
                customerReports: action.customerReports,
            };
        }
        case CUSTOMER_REPORTS_REVIEW_FETCHED: {
            return {
                ...state,
                customerReportReviews: [
                    ...state.customerReportReviews.filter(
                        (customerReportReview) =>
                            !action.customerReportReviews
                                .map((customerReportReviewFromAction) => customerReportReviewFromAction.id)
                                .includes(customerReportReview.id)
                    ),
                    ...action.customerReportReviews,
                ],
            };
        }
        case ALL_CUSTOMER_REPORTS_REVIEW_FETCHED: {
            return {
                ...state,
                customerReportReviews: action.customerReportReviews,
            };
        }
        case ADD_CUSTOMER_REPORT_REVIEW_SUCCESS:
            return {
                ...state,
                customerReportReviews: [...state.customerReportReviews, action.customerReportReview],
            };
        case EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS:
            return {
                ...state,
                customerReportReviews: replaceInCollection(
                    state.customerReportReviews,
                    action.customerReportReview,
                    action.newCustomerReportReview,
                    (a, b) => `${a.lineNumber}`.localeCompare(`${b.lineNumber}`)
                ),
            };
        case EDIT_CUSTOMER_REPORT_SUCCESS:
            return {
                ...state,
                customerReports: replaceInCollection(
                    state.customerReports,
                    action.customerReport,
                    action.newCustomerReport,
                    (a, b) => `${a.id}`.localeCompare(`${b.id}`)
                ),
            };
        default:
            return state;
    }
}
