import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DependentForm from "./DependentForm";
import { DependentFormModel } from "./DependentFormModel";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import * as Yup from "yup";
import { NewMemberFormModel } from "./NewMemberFormModel";

export default function Dependent() {
    const {
        newMember,
        setNewMember,
        activeStep,
        setNextClicked,
        setActiveStep,
        nextClicked,
        setCanProceedWithoutDependent,
        canProceedWithoutDependent,
    } = useMemberEnrollments();
    const [dependents, setDependents] = useState<DependentFormModel[]>(newMember?.dependent ?? []);
    const [incrementStep, setIncrementStep] = useState(false);

    const handleAddDependent = () => {
        let newDependents: DependentFormModel[] = newMember?.dependent ?? [];
        setDependents((prevDependents) => {
            newDependents = [
                ...(newMember?.dependent ?? []),
                {
                    id: prevDependents[prevDependents.length - 1]?.id + 1 || 1,
                    firstName: "",
                    lastName: "",
                    dob: undefined,
                    relationship: "Child",
                    effectiveDate: undefined,
                },
            ];
            setNewMember((prev) => {
                if (prev) {
                    const newMember = {
                        ...prev,
                        dependent: newDependents,
                    };
                    return newMember;
                }
                return prev;
            });
            return newDependents;
        });

        setCanProceedWithoutDependent(false);
    };

    const handleRemoveDependent = (dependentId: any) => {
        setDependents((prevDependents) => {
            const filteredDependents = prevDependents.filter((dep) => dep.id !== dependentId);
            return filteredDependents;
        });
        setNewMember((prevNewMember) => {
            if (prevNewMember) {
                const updatedDependents = prevNewMember.dependent?.filter((dep) => dep.id !== dependentId);
                const updatedNewMember = {
                    ...prevNewMember,
                    dependent: updatedDependents,
                };
                if (updatedDependents === undefined || updatedDependents?.length === 0) {
                    setCanProceedWithoutDependent(true);
                    setNextClicked(false);
                }
                sessionStorage.setItem("newMember", JSON.stringify(updatedNewMember));
                return updatedNewMember;
            }
            return prevNewMember;
        });
    };

    const dependentSchema = Yup.object({
        firstName: Yup.string().required("First Name is Required"),
        lastName: Yup.string().required("Last Name is Required"),
        dob: Yup.date().nullable().required("Date of Birth is Required"),
        relationship: Yup.string().required("Relationship is Required"),
    });
    const validateAllDependents = async (deps: DependentFormModel[]) => {
        let isValid = true;
        deps.forEach((dependent) => {
            try {
                dependentSchema.validateSync(dependent);
            } catch (error) {
                isValid = false;
                setNextClicked(false);
            }
        });
        return isValid;
    };

    const handleValidationSuccess = async (newMember: NewMemberFormModel) => {
        if (newMember.dependent !== undefined) {
            setDependents(newMember.dependent);

            if (await validateAllDependents(newMember.dependent)) {
                sessionStorage.setItem("newMember", JSON.stringify(newMember));
                setIncrementStep(true);
            }
        }
    };

    useEffect(() => {
        if (activeStep === 1 && nextClicked && canProceedWithoutDependent) {
            setIncrementStep(true);
        }
    }, [
        activeStep,
        canProceedWithoutDependent,
        newMember?.dependent,
        nextClicked,
        setCanProceedWithoutDependent,
        setNextClicked,
    ]);

    useEffect(() => {
        if (
            activeStep === 1 &&
            nextClicked &&
            (newMember?.dependent === undefined || newMember?.dependent.length === 0)
        ) {
            setCanProceedWithoutDependent(true);
            setNextClicked(false);
        }
    }, [activeStep, newMember?.dependent, nextClicked, setNextClicked, setCanProceedWithoutDependent]);

    useEffect(() => {
        if (activeStep === 1 && incrementStep) {
            setIncrementStep(false);
            setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
        }
    }, [activeStep, incrementStep, setActiveStep]);

    return (
        <>
            <Form>
                <Button variant="primary" onClick={handleAddDependent}>
                    Add Dependent
                </Button>
                {dependents.map((dependent) => (
                    <DependentForm
                        key={dependent.id}
                        dependent={dependent}
                        onRemove={handleRemoveDependent}
                        onValidationSuccess={handleValidationSuccess}
                    />
                ))}
            </Form>
        </>
    );
}
