import { inject, injectable } from "inversify";
import { Store } from "redux";
import { showToast } from "../../actions/ToastActionCreators";
import { ShowToastAction } from "../../actions/ToastActions";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_SYSTEM_SUCCESS } from "../../constants/ToastConstants";
import { TYPES } from "../../dependencyInjection/Types";
import { FinosecSystem } from "../../models/FinosecSystem";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IFinosecSystemService } from "./IFinosecSystemService";

@injectable()
export class FinosecSystemService implements IFinosecSystemService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getFinosecSystems(): Promise<void | FinosecSystem[]> {
        return this.httpClient
            .get<FinosecSystem[]>(`/finosec-systems`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving systems. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    createFinosecSystem(system: FinosecSystem): Promise<void | FinosecSystem> {
        return this.httpClient
            .post<FinosecSystem>(`/finosec-systems`, system)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_SYSTEM_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `System "${t.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a system. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
