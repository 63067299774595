import { AuthenticationState } from "../state/AuthenticationState";
import { AuthenticateActions, LOGGED_IN, LOGIN_FAIL, LOGOUT } from "../actions/AuthenticateActions";

const initialState: AuthenticationState = {
    login: false,
    isAuthenticated: false,
    decodedAccessToken: {
        roles: "",
        userId: -1,
        sub: "",
        tenant_name: "",
        tenant_id: -1,
        family_name: "",
        given_name: "",
        email: "",
        qr_code_image: "",
        manual_entry_code: "",
    },
    accessToken: "",
    refreshToken: "",
    failedLoginAttempts: 0,
};

export function authenticationReducer(
    state: AuthenticationState = initialState,
    action: AuthenticateActions
): AuthenticationState {
    switch (action.type) {
        case LOGGED_IN:
            return {
                ...state,
                isAuthenticated: true,
                decodedAccessToken: action.decodedAccessToken,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                failedLoginAttempts: 0,
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: "",
                refreshToken: "",
                failedLoginAttempts: state.failedLoginAttempts + 1,
            };
        default:
            return state;
    }
}
