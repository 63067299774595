import { createContext } from "react";
import { Accumulator, MemberAccumulator } from "../../models/Accumulator";

export type IAccumulatorContext = {
    accumulators: Accumulator[];
    memberAccumulators: MemberAccumulator[];
    getAccumulators(groupNumber: string, participantIDNumber: string): void;
};

const defaultValue: IAccumulatorContext = {
    accumulators: [],
    memberAccumulators: [],
    getAccumulators: () => {},
};
export const AccumulatorContext = createContext<IAccumulatorContext>(defaultValue);
