import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MemberEnrollmentUpdateModel, NewMemberFormModel } from "../../components/group-admin/NewMemberFormModel";
import { IMemberEnrollmentContext, MemberEnrollmentContext } from "./MemberEnrollmentContext";
import React from "react";
import { useStorage } from "../../hooks/useStorage";
import { EnrollmentDetail, PlanCodeDescription, RateLevel } from "../../models/Enrollment";
import { DependentFormModel } from "../../components/group-admin/DependentFormModel";
import { getDateFromDbValue } from "../../helper-functions/getFormattedDateFromDbValue";
import { PersonalInformationFormModel } from "../../components/group-admin/PersonalInformationFormModel";
import { CoverageFormModel } from "../../components/group-admin/CoverageFormModel";
import { useFetch } from "../../hooks/useFetch";

export const MemberEnrollmentProvider = (props: PropsWithChildren<any>) => {
    const { postUnique } = useFetch();

    const [newMember, setNewMember] = useState<NewMemberFormModel | undefined>();
    const [dbMember, setDbMember] = useState<NewMemberFormModel | undefined>();

    const [activeStep, setActiveStep] = useState(0);
    const [nextClicked, setNextClicked] = useState(false);
    const [backClicked, setBackClicked] = useState(false);
    const [validateDependents, setValidateDependents] = useState(false);
    const [validateCoverage, setValidateCoverage] = useState(false);
    const [filename, setFilename] = useState("");
    const [uploadSuccess, setUploadSuccess] = useState<boolean | undefined>();
    const [isUpdating, setIsUpdating] = useStorage("update-enrollment", false);
    const [updatedMember, setUpdatedMember] = useState<MemberEnrollmentUpdateModel | undefined>();

    const [coverageFormData, setCoverageFormData] = useStorage<{
        rateLevels: RateLevel[];
        planCodeDescriptions: PlanCodeDescription[];
    }>("coverage-form", { rateLevels: [], planCodeDescriptions: [] });

    const [enrollmentDetail, setEnrollmentDetail] = useStorage<EnrollmentDetail | undefined>(
        "enrollment-details",
        undefined,
        true
    );
    const [canProceedWithoutDependent, setCanProceedWithoutDependent] = useState(false);

    useEffect(() => {
        if (!isUpdating || !enrollmentDetail) return;
        const { personalInfo, dependents, coverages } = enrollmentDetail;

        const personalInformation: PersonalInformationFormModel = {
            ...personalInfo,
            streetAddress: personalInfo.address1 + " " + personalInfo.address2,
            dob: getDateFromDbValue(personalInfo.birthDate),
            phone: personalInfo.phoneNumber,
            location: personalInfo.location.toString(),
            terminationDate: getDateFromDbValue(personalInfo.terminationDate),
            effectiveDate: getDateFromDbValue(personalInfo.effectiveDate),
            governmentIdentifier: personalInfo.govtId,
        };

        const dependent = dependents.map<DependentFormModel>((dep) => ({
            ...dep,
            id: dep.dependentSequenceNumber,
            dob: getDateFromDbValue(dep.birthDate),
            effectiveDate: getDateFromDbValue(dep.effectiveDate),
        }));

        const coverage: Record<string, CoverageFormModel> = {};
        coverages.forEach((c) => {
            const rateLevel = coverageFormData.rateLevels.find((r) => r.productId === c.productId);
            const planCodeDescription = coverageFormData.planCodeDescriptions.find((r) => r.planCode === c.planCode);

            if (rateLevel && planCodeDescription) {
                coverage[c.productType] = {
                    plan: `${planCodeDescription.planCode} - ${planCodeDescription.description}`,
                    coverage: c.coverageOption,
                    effectiveDate: getDateFromDbValue(c.effectiveDate) ?? null,
                    terminationDate: getDateFromDbValue(c.terminationDate),
                };
            }
        });

        setDbMember({
            group: enrollmentDetail.group,
            account: enrollmentDetail.account.toString(),
            hireDate: getDateFromDbValue(enrollmentDetail.hireDate),
            applicationDate: undefined,
            personalInformation,
            dependent,
            coverage,
        });
        setNewMember({
            group: enrollmentDetail.group,
            account: enrollmentDetail.account.toString(),
            hireDate: getDateFromDbValue(enrollmentDetail.hireDate),
            applicationDate: undefined,
            personalInformation,
            dependent,
            coverage,
        });
    }, [enrollmentDetail, isUpdating, coverageFormData]);

    const steps = useMemo(() => ["Personal Information", "Dependent", "Coverage", "Review", "Confirmation"], []);

    useEffect(() => {
        const storedNewMember = sessionStorage.getItem("newMember");
        if (storedNewMember) {
            try {
                const parsedNewMember: NewMemberFormModel = JSON.parse(storedNewMember);
                if (parsedNewMember.applicationDate) {
                    parsedNewMember.applicationDate = new Date(parsedNewMember.applicationDate);
                }
                if (parsedNewMember.personalInformation && parsedNewMember.personalInformation.dob) {
                    parsedNewMember.personalInformation.dob = new Date(parsedNewMember.personalInformation.dob);
                }
                if (parsedNewMember.personalInformation && parsedNewMember.personalInformation.terminationDate) {
                    parsedNewMember.personalInformation.terminationDate = new Date(
                        parsedNewMember.personalInformation.terminationDate
                    );
                }
                if (parsedNewMember.hireDate) {
                    parsedNewMember.hireDate = new Date(parsedNewMember.hireDate);
                }
                if (parsedNewMember.personalInformation && parsedNewMember.personalInformation.effectiveDate) {
                    parsedNewMember.personalInformation.effectiveDate = new Date(
                        parsedNewMember.personalInformation.effectiveDate
                    );
                }
                if (parsedNewMember.personalInformation && parsedNewMember.personalInformation.effectiveDate) {
                    parsedNewMember.personalInformation.effectiveDate = new Date(
                        parsedNewMember.personalInformation.effectiveDate
                    );
                }
                if (parsedNewMember.dependent) {
                    parsedNewMember.dependent.forEach((dep) => {
                        if (dep.dob) {
                            dep.dob = new Date(dep.dob);
                        }
                        if (dep.effectiveDate) {
                            dep.effectiveDate = new Date(dep.effectiveDate);
                        }
                    });
                }

                if (parsedNewMember.coverage) {
                    for (const [key, value] of Object.entries(parsedNewMember.coverage)) {
                        value.effectiveDate = value.effectiveDate ? new Date(value.effectiveDate) : null;
                        value.terminationDate = value.terminationDate ? new Date(value.terminationDate) : null;
                    }
                }

                setNewMember(parsedNewMember);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        }
    }, []);

    useEffect(() => {
        const loadCoverageFormData = async () => {
            if (!newMember) return;
            const account = newMember.account.includes("-") ? newMember.account.split("-")[0] : newMember.account;
            const body = { groupNumber: newMember.group, accountNumber: account };
            const [planCodeDescs, rateLevels] = await Promise.all([
                postUnique<any, PlanCodeDescription[]>("enrollments/plancodedescriptions", body),
                postUnique<any, RateLevel[]>("enrollments/ratelevels", body),
            ]);
            setCoverageFormData((prev) => {
                const newState = { ...prev };
                if (planCodeDescs) {
                    newState.planCodeDescriptions = planCodeDescs;
                }
                if (rateLevels) {
                    newState.rateLevels = rateLevels;
                }
                return newState;
            });
        };
        loadCoverageFormData();
    }, [newMember?.group, newMember?.account]);

    const resetFlags = useCallback(() => {
        setNextClicked(false);
        setBackClicked(false);
        setValidateDependents(false);
        setValidateCoverage(false);
        setCanProceedWithoutDependent(false);
    }, []);

    const discardForm = useCallback(() => {
        sessionStorage.removeItem("newMember");
        setNewMember(undefined);
        setEnrollmentDetail(undefined);
        setCoverageFormData({ planCodeDescriptions: [], rateLevels: [] });
        setIsUpdating(false);
        resetFlags();
        setActiveStep(0);
    }, [resetFlags]);

    const value = useMemo(
        (): IMemberEnrollmentContext => ({
            newMember,
            setNewMember,
            activeStep,
            setActiveStep,
            steps,
            nextClicked,
            setNextClicked,
            backClicked,
            setBackClicked,
            validateDependents,
            setValidateDependents,
            validateCoverage,
            setValidateCoverage,
            resetFlags,
            discardForm,
            filename,
            setFilename,
            uploadSuccess,
            setUploadSuccess,
            isUpdating,
            setIsUpdating,
            updatedMember,
            setUpdatedMember,
            enrollmentDetail,
            setEnrollmentDetail,
            coverageFormData,
            dbMember,
            canProceedWithoutDependent,
            setCanProceedWithoutDependent,
        }),
        [
            newMember,
            activeStep,
            steps,
            nextClicked,
            backClicked,
            validateDependents,
            validateCoverage,
            setValidateCoverage,
            resetFlags,
            discardForm,
            filename,
            setFilename,
            uploadSuccess,
            setUploadSuccess,
            isUpdating,
            setIsUpdating,
            updatedMember,
            setUpdatedMember,
            enrollmentDetail,
            setEnrollmentDetail,
            coverageFormData,
            dbMember,
            canProceedWithoutDependent,
            setCanProceedWithoutDependent,
        ]
    );

    return <MemberEnrollmentContext.Provider value={value} {...props} />;
};

export const useMemberEnrollments = () => {
    const context = useContext(MemberEnrollmentContext);
    if (context === undefined) {
        throw new Error("useMemberEnrollments must be used within a MemberEnrollmentProvider");
    }
    return context;
};
