import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getDateStringForFileName } from "../../helper-functions/getDateStringForFileName";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { useFetch } from "../../hooks/useFetch";
import { Member } from "../../models/Member";
import { MemberBenefits } from "../../models/MemberBenefits";
import { searchMembersByIdRequest, searchMembersByNameRequest, MemberBenefitsRequest } from "../../models/MemberSearch";
import { MyInsuranceInfo, MyInsuranceInfoRequest } from "../../models/MyInsuranceInfo";
import { UserTypes } from "../../models/UserTypes";
import { AppState } from "../../state/AppState";
import { useUsers } from "../users";

interface IMemberContext {
    member: Member | undefined;
    memberDependents: Member[];
    isMember: boolean;
    benefits: MemberBenefits[] | undefined;
    isLoading: boolean;
    isLoadingMemberInfo: boolean;
    myInsuranceInfo: MyInsuranceInfo | undefined;
    getMyPlanDetailPdf: (planCode: string) => void;
    getMemberPDF: (member: Member) => void;
    getMemberInsuranceInfo: (participantIDNumber?: string, groupNumber?: string, accountNumber?: number) => void;
    getMemberBenefits: (participantIdNumber: string, accountNumber: string, groupNumber: string) => void;
    getMyMemberBenefits: () => void;
    isGettingPdf: boolean;
    hasPdfError: boolean;
    setHasPdfError: any;
    hasCoverage: (coverageType: string) => boolean;
    searchByName: (request: searchMembersByNameRequest) => void;
    searchById: (request: searchMembersByIdRequest) => void;
    pretreatmentMessage: string;
}

const MemberContext = createContext<IMemberContext>({} as IMemberContext);

export const MemberProvider = ({ children }: any) => {
    const { getFileWithPostMethod, postUnique, get } = useFetch();
    const {
        userType,
        userState: { loggedInUser },
    } = useUsers();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant } = useCurrentTenant(decodedAccessToken.tenant_id);
    const [member, setMember] = useState<Member | undefined>(undefined);
    const [memberDependents, setMemberDependents] = useState<Member[]>([]);
    const [benefits, setBenefits] = useState<MemberBenefits[] | undefined>(undefined);
    const [isGettingPdf, setIsGettingPdf] = useState<boolean>(false);
    const [myInsuranceInfo, setMyInsuranceInfo] = useState<MyInsuranceInfo | undefined>(undefined);
    const [hasPdfError, setHasPdfError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingMyInsuranceInfo, setIsLoadingMyInsuranceInfo] = useState<boolean>(false);
    const [isLoadingMyBenefits, setIsLoadingMyBenefits] = useState<boolean>(false);
    const isMember = decodedAccessToken.tenant_id === 1 && loggedInUser?.roleId === 4;
    const isLoadingMemberInfo = isLoadingMyInsuranceInfo || isLoadingMyBenefits;

    const [pretreatmentMessage, setPretreatmentMessage] = useState(
        "We suggest a pretreatment estimate on any dental service over $250"
    );

    const hasCoverage = useCallback(
        (coverageType: string): boolean => {
            if (!myInsuranceInfo || !myInsuranceInfo.coverageInformation) {
                return false;
            }
            const coverage = myInsuranceInfo.coverageInformation.find(
                (product) => product.coverageType?.toLowerCase() === coverageType.toLowerCase()
            );
            return !!coverage;
        },
        [myInsuranceInfo]
    );

    const getMemberInsuranceInfo = useCallback(
        async (participantIDNumber?: string, groupNumber?: string, accountNumber?: number, providerType?: string) => {
            setIsLoading(true);
            const request: MyInsuranceInfoRequest = {
                accountNumber: accountNumber || 0,
                participantIDNumber: participantIDNumber || "",
                groupNumber: groupNumber || "",
            };
            if (providerType) {
                request.providerType = providerType;
            }
            const response = await postUnique<MyInsuranceInfoRequest, MyInsuranceInfo>(`members/my-insurance`, request);
            if (response) {
                setMyInsuranceInfo(response);
            }
            setIsLoading(false);
        },
        [postUnique]
    );

    const getMyInsuranceInfo = useCallback(async () => {
        if (loggedInUser && userType === UserTypes.Member) {
            setIsLoadingMyInsuranceInfo(true);
            await getMemberInsuranceInfo(
                loggedInUser.participantIdNumber,
                loggedInUser.groupNumber,
                loggedInUser.accountNumber
            );
        }
        setIsLoadingMyInsuranceInfo(false);
    }, [getMemberInsuranceInfo, loggedInUser, userType]);

    useEffect(() => {
        getMyInsuranceInfo();
    }, [getMyInsuranceInfo]);

    useEffect(() => {
        if (member && currentTenant) {
            getMemberInsuranceInfo(
                member.participantIDNumber,
                member.groupNumber,
                member.accountNumber,
                currentTenant.providerType
            );
        }
    }, [getMemberInsuranceInfo, member, currentTenant]);

    const getMemberBenefits = useCallback(
        async (participantIdNumber: string = "", accountNumber: string = "", groupNumber = "") => {
            setBenefits(undefined);
            if (!participantIdNumber && !member) return;
            setIsLoading(true);

            const request: MemberBenefitsRequest = {
                groupNumber: groupNumber || member?.groupNumber,
                participantIdNumber: participantIdNumber || member?.participantIDNumber,
                accountNumber: accountNumber || member?.accountNumber.toString(),
            };
            const memberBenefits = await postUnique<MemberBenefitsRequest, MemberBenefits[]>(
                `providers/benefits/member`,
                request
            );
            if (memberBenefits) {
                setBenefits(memberBenefits);
            }
            setIsLoading(false);
        },
        [get, member]
    );

    const getMyMemberBenefits = useCallback(async () => {
        if (loggedInUser && userType === UserTypes.Member) {
            setIsLoadingMyBenefits(true);
            await getMemberBenefits(
                loggedInUser.participantIdNumber,
                loggedInUser.accountNumber?.toString(),
                loggedInUser.groupNumber?.toString()
            );
        }
        setIsLoadingMyBenefits(false);
    }, [getMemberBenefits, userType, loggedInUser]);

    useEffect(() => {
        getMyMemberBenefits();
    }, [getMyMemberBenefits]);

    const getPlanPDf = useCallback(
        async (planCode: string, participantIdNumber?: string, accountNumber?: number, groupNumber?: string) => {
            setHasPdfError(false);
            setIsGettingPdf(true);
            try {
                await getFileWithPostMethod(
                    `providers/plan-pdf`,
                    {
                        planCode: planCode.trim(),
                        accountNumber: accountNumber,
                        groupNumber: groupNumber,
                    },
                    `Plan Detail ${participantIdNumber} ${getDateStringForFileName()}`,
                    "application/pdf",
                    true
                );
            } catch {
                setHasPdfError(true);
            }
            setIsGettingPdf(false);
        },
        [getFileWithPostMethod]
    );

    const getMyPlanDetailPdf = useCallback(
        async (planCode: string) => {
            if (loggedInUser && isMember) {
                await getPlanPDf(
                    planCode,
                    loggedInUser.participantIdNumber,
                    loggedInUser.accountNumber,
                    loggedInUser.groupNumber
                );
            }
            if (loggedInUser && member && !isMember) {
                await getPlanPDf(planCode, member.participantIDNumber, member.accountNumber, member.groupNumber);
            }
        },
        [loggedInUser, isMember, getPlanPDf, member]
    );

    const getMemberPDF = useCallback(
        async (member: Member) => {
            setHasPdfError(false);
            setIsGettingPdf(true);
            try {
                await getFileWithPostMethod(
                    `providers/plan-pdf`,
                    {
                        planCode: member.planCode.trim(),
                        accountNumber: member.accountNumber,
                        groupNumber: member.groupNumber,
                    },
                    `Plan Detail ${member.participantIDNumber} ${getDateStringForFileName()}`,
                    "application/pdf",
                    true
                );
            } catch {
                setHasPdfError(true);
            }
            setIsGettingPdf(false);
        },
        [getFileWithPostMethod]
    );

    const searchByName = useCallback(
        async (request: searchMembersByNameRequest) => {
            setMember(undefined);
            setMemberDependents([]);
            setHasPdfError(false);
            request.providerType =
                request.providerType === "Dental"
                    ? "D"
                    : request.providerType === "Vision"
                    ? "V"
                    : request.providerType
                    ? request.providerType
                    : "";
            const members = await postUnique<searchMembersByNameRequest, Member[]>("providers/member", request);
            if (members && members.length > 0) {
                setMember(members.find((m) => m.dependentSequenceNumber === 0));
                setMemberDependents(members.filter((m) => m.dependentSequenceNumber !== 0));
            } else {
                setMyInsuranceInfo(undefined);
            }
        },
        [postUnique]
    );

    const searchById = useCallback(
        async (request: searchMembersByIdRequest) => {
            setMember(undefined);
            setMemberDependents([]);
            setHasPdfError(false);
            request.providerType =
                request.providerType === "Dental"
                    ? "D"
                    : request.providerType === "Vision"
                    ? "V"
                    : request.providerType
                    ? request.providerType
                    : "";
            const newMembers = await postUnique<searchMembersByIdRequest, Member[]>("providers/member", request);
            if (newMembers && newMembers.length > 0) {
                setMember(newMembers.find((m) => m.dependentSequenceNumber === 0));
                setMemberDependents(newMembers.filter((m) => m.dependentSequenceNumber !== 0));
            } else {
                setMyInsuranceInfo(undefined);
            }
        },
        [postUnique]
    );

    const value = useMemo(
        () => ({
            member,
            memberDependents,
            myInsuranceInfo,
            isMember,
            benefits,
            getMyPlanDetailPdf,
            getMemberInsuranceInfo,
            getMemberPDF,
            getMemberBenefits,
            getMyMemberBenefits,
            isGettingPdf,
            hasPdfError,
            setHasPdfError,
            searchByName,
            searchById,
            hasCoverage,
            isLoading,
            isLoadingMemberInfo,
            pretreatmentMessage,
        }),
        [
            getMemberPDF,
            getMyPlanDetailPdf,
            getMemberInsuranceInfo,
            myInsuranceInfo,
            isGettingPdf,
            hasPdfError,
            setHasPdfError,
            member,
            memberDependents,
            isMember,
            benefits,
            getMemberBenefits,
            getMyMemberBenefits,
            searchByName,
            searchById,
            hasCoverage,
            isLoading,
            isLoadingMemberInfo,
            pretreatmentMessage,
        ]
    );

    return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>;
};

export const useMembers = () => {
    const context = useContext(MemberContext);
    if (context === undefined) {
        throw new Error("useMembers must be used within a MemberProvider");
    }
    return context;
};
