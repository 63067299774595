export const saveFile = (name: string, type: string, data: any) => {
    if (data) {
        const objUrl = window.URL.createObjectURL(new Blob([data], { type }));
        const a = document.createElement("a");

        a.setAttribute("href", objUrl);
        a.setAttribute("download", name);

        document.body.append(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(objUrl);
    }
};
