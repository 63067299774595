const TYPES = {
    AuthenticationService: Symbol.for("AuthenticationService"),
    AwsFileService: Symbol.for("AwsFileService"),
    HttpClient: Symbol.for("HttpClient"),
    IExceptionHandler: Symbol.for("IExceptionHandler"),
    RoleService: Symbol.for("RoleService"),
    RequestPromiseApi: Symbol.for("RequestPromiseApi"),
    Store: Symbol.for("Store"),
    SubtabService: Symbol.for("SubtabService"),
    ReportService: Symbol.for("ReportService"),
    SubReportService: Symbol.for("SubReportService"),
    TabService: Symbol.for("TabService"),
    TenantService: Symbol.for("TenantService"),
    UserService: Symbol.for("UserService"),
    ReportAttributeService: Symbol.for("ReportAttributeService"),
    SubReportAttributeService: Symbol.for("SubReportAttributeService"),
    FinosecSystemService: Symbol.for("FinosecSystemService"),
    FinosecSystemStatisticService: Symbol.for("FinosecSystemStatisticService"),
    FinosecSystemStatisticsReportsService: Symbol.for("FinosecSystemStatisticsReportsService"),
    CustomerImportService: Symbol.for("CustomerImportService"),
    CustomerImportFileService: Symbol.for("CustomerImportFileService"),
    CustomerReportService: Symbol.for("CustomerReportService"),
    NoteService: Symbol.for("NoteService"),
    CustomerDocumentService: Symbol.for("CustomerDocumentService"),
    CustomerDocumentsStatisticsService: Symbol.for("CustomerDocumentsStatisticsService"),
    FileStatusService: Symbol.for("FileStatusService"),
    MFAService: Symbol.for("MFAService"),
};

export { TYPES };
