import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import { useUsers } from "../../contexts/users";
import { GroupAdminMenuOptions } from "../../constants/GroupAdminMenuOptions";
import LightSection from "../page-sections/LightSection";
import { useEffect, useState } from "react";
import { GroupAdminBannerMessage } from "../../models/GroupAdminBannerMessage";
import { useFetch } from "../../hooks/useFetch";
import GroupAdminLinks from "./GroupAdminLinks";
import { LoadingSpinner } from "../loading/Loading";

const GroupAdminLanding = () => {
    const [bannerMessage, setBannerMessage] = useState<string>();
    const { myPermissions, myPermissionTypes, myPermissionsLoading } = useUsers();
    const { get } = useFetch();

    const populateBannerMessage = async () => {
        const response = await get<GroupAdminBannerMessage>("app-configurations/banner-message");
        if (response) setBannerMessage(response.bannerMessage);
    };

    useEffect(() => {
        populateBannerMessage().catch((e) => console.error(e));
    }, []);

    return (
        <LightSection>
            <Container>
                {bannerMessage && (
                    <Row className={"mb-4"}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Text>{bannerMessage}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Row>
                    {myPermissionsLoading ? (
                        <Col>
                            <LoadingSpinner />
                        </Col>
                    ) : (
                        <>
                            {!myPermissionsLoading && myPermissions.length ? (
                                <>
                                    {myPermissionTypes.access && !myPermissionTypes.enrollment && (
                                        <Col md={"auto"}>
                                            <GroupAdminLinks options={GroupAdminMenuOptions.customerService} />
                                        </Col>
                                    )}
                                    {myPermissionTypes.enrollment && (
                                        <>
                                            <Col md={"auto"}>
                                                <GroupAdminLinks options={GroupAdminMenuOptions.customerService} />
                                            </Col>
                                            <Col md={"auto"}>
                                                <GroupAdminLinks options={GroupAdminMenuOptions.manageEnrollment} />
                                            </Col>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Col>
                                    <Alert variant={"danger"}>
                                        You do not have access to any Groups yet. Please contact administrator.
                                    </Alert>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </Container>
        </LightSection>
    );
};
export default GroupAdminLanding;
