import { useEffect, useState, useCallback, ChangeEvent, createRef } from "react";
import { Col, Form, Row, Button, Alert } from "react-bootstrap";
import { useApp } from "../../contexts/app";
import { useFetch } from "../../hooks/useFetch";
import { AppSettings } from "../../models/AppSettings";
import { AwsFile } from "../../models/AwsFile";
import { Operable } from "../../models/Operable";
import { uploadImage, clearImage } from "../../actions/aws/UploadImageActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import "./app-settings-inputs.scss";

interface AppSettingsInputsProps {
    tenantId: number;
}

export default function AppSettingsForm(props: AppSettingsInputsProps) {
    const { tenantId } = props;
    const uploadBtnRef = createRef<HTMLInputElement>();
    const { get } = useFetch();
    const dispatch = useDispatch();
    const {
        createAppSettings,
        editAppSettings,
        siteName,
        logoUri,
        partnerName,
        customerName,
        menuBackgroundColor,
        menuSelectedColor,
    } = useApp();
    const [storedSettings, setStoredSettings] = useState<AppSettings>({} as AppSettings);
    const [siteNameInput, setSiteNameInput] = useState<string>(siteName);
    const [logoUriInput, setLogoUriInput] = useState<string>(logoUri);
    const [partnerNameInput, setPartnerNameInput] = useState<string>(partnerName);
    const [customerNameInput, setCustomerNameInput] = useState<string>(customerName);
    const [menuBgInput, setMenuBgInput] = useState<string>(menuBackgroundColor);
    const [menuSelectedInput, setMenuSelectedInput] = useState<string>(menuSelectedColor);
    const [error, setError] = useState<string>("");
    const errorMessage = "File type is not valid";
    const defaultFileInputMessage = "Choose a file...";
    const loadingMessage = "loading from file...";
    const awsFiles = useSelector<AppState, AwsFile[]>((state) => state.awsFileState.awsFiles);

    const getAppSettingsForTenant = useCallback(async () => {
        if (tenantId) {
            const response = await get<AppSettings>(`appsettings/tenants/${tenantId}`);
            if (response) {
                setStoredSettings(response);
                setSiteNameInput(response.siteName || "");
                setLogoUriInput(response.logoUri || "");
                setPartnerNameInput(response.partnerName || "Partner");
                setCustomerNameInput(response.customerName || "Customer");
                setMenuBgInput(response.menuBackgroundColor || "#0879A8");
                setMenuSelectedInput(response.menuSelectedColor || "#001325");
            }
        }
    }, [get, tenantId]);

    useEffect(() => {
        getAppSettingsForTenant();
    }, []);

    useEffect(() => {
        if (awsFiles.length && logoUriInput === loadingMessage) {
            setLogoUriInput(awsFiles[awsFiles.length - 1].url);
        }
    }, [logoUriInput, awsFiles]);

    function isExtensionValid(path: string): boolean {
        const extension = path.substring(path.lastIndexOf(".") + 1).toLowerCase();
        return extension === "gif" || extension === "png" || extension === "jpeg" || extension === "jpg";
    }

    function removeFile(): void {
        setLogoUriInput("");
        dispatch(clearImage());
    }

    function handleFileInputChange(file: File) {
        setError("");
        if (file) {
            if (isExtensionValid(file.name)) {
                var reader = new FileReader();

                reader.onload = function (event) {
                    const uploadedImage: AwsFile = {
                        filename: file.name,
                        content: reader.result?.toString().split(",")[1] || "",
                        url: "",
                    };
                    dispatch(uploadImage(uploadedImage));
                };

                reader.readAsDataURL(file);

                if (reader.DONE === 2) {
                    setLogoUriInput(loadingMessage);
                    return;
                }

                setError(defaultFileInputMessage);
                return;
            }

            if (file.name === "") {
                setError(defaultFileInputMessage);
                return;
            }
        }

        setError(errorMessage);
    }

    const saveSettings = async () => {
        const settings: AppSettings = {
            ...storedSettings,
            tenantId: tenantId,
            siteName: siteNameInput,
            logoUri: logoUriInput,
            partnerName: partnerNameInput,
            customerName: customerNameInput,
            menuBackgroundColor: menuBgInput,
            menuSelectedColor: menuSelectedInput,
        };
        if (storedSettings.tenantId) {
            const settingsRecords: Operable<AppSettings> = {
                original: storedSettings,
                updated: settings,
            };
            const newSettings = await editAppSettings(settingsRecords);
            if (newSettings) {
                setStoredSettings(newSettings);
            }
        } else {
            const newSettings = await createAppSettings(settings);
            if (newSettings) {
                setStoredSettings(newSettings);
            }
        }
        dispatch(clearImage());
    };

    const hasUnsavedChanges = useCallback((): boolean => {
        if (!storedSettings.tenantId) {
            return false;
        }
        if (
            storedSettings.siteName === siteNameInput &&
            storedSettings.logoUri === logoUriInput &&
            storedSettings.partnerName === partnerNameInput &&
            storedSettings.customerName === customerNameInput &&
            storedSettings.menuBackgroundColor &&
            storedSettings.menuBackgroundColor.toLowerCase() === menuBgInput.toLowerCase() &&
            storedSettings.menuSelectedColor &&
            storedSettings.menuSelectedColor.toLowerCase() === menuSelectedInput.toLowerCase()
        ) {
            return false;
        }
        return true;
    }, [
        storedSettings,
        siteNameInput,
        logoUriInput,
        partnerNameInput,
        customerNameInput,
        menuBgInput,
        menuSelectedInput,
    ]);

    return (
        <Row>
            <Col lg={4} xl={3}>
                <Form.Group className="mb-3" controlId="siteName">
                    <Form.Label>Site Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={siteNameInput}
                        onChange={(e) => setSiteNameInput(e.target.value)}
                    />
                    <Form.Text>This will be displayed in the site footer.</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="partnerName">
                    <Form.Label>Partner Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={partnerNameInput}
                        onChange={(e) => setPartnerNameInput(e.target.value)}
                    />
                    <Form.Text>This is how higher level tenants will be identified.</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="customerName">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={customerNameInput}
                        onChange={(e) => setCustomerNameInput(e.target.value)}
                    />
                    <Form.Text>This is how lower level tenants will be identified.</Form.Text>
                </Form.Group>
            </Col>
            <Col lg={4} xl={3}>
                <Form.Group className="mb-3" controlId="logoUri">
                    <Form.Label>Logo</Form.Label>
                    <Form.Control type="text" value={logoUriInput} disabled readOnly hidden={!logoUriInput} />
                    <Form.Control
                        ref={uploadBtnRef}
                        type="file"
                        accept="image/*"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileInputChange(e.target.files![0])}
                        style={{ display: "none" }}
                    />
                </Form.Group>

                <Form.Group>
                    <div className="d-flex">
                        {logoUriInput.length > 0 ? (
                            <Button onClick={removeFile} variant="warning" className="ml-2">
                                Remove file
                            </Button>
                        ) : (
                            <Button onClick={() => uploadBtnRef.current!.click()} variant="dark">
                                Choose a file
                            </Button>
                        )}
                    </div>
                    <Form.Text>This image will be displayed in the site header and footer.</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                    <img
                        className="mt-3"
                        style={{ maxWidth: "200px", maxHeight: "70px" }}
                        alt=""
                        src={awsFiles.length > 0 ? awsFiles[awsFiles.length - 1].url : logoUriInput}
                    />
                </Form.Group>

                {!!error && <Alert variant="danger">{error}</Alert>}

                <Form.Group className="mb-3">
                    <Form.Label>Menu Background Color</Form.Label>
                    <Form.Control type="color" value={menuBgInput} onChange={(e) => setMenuBgInput(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Menu Selected Color</Form.Label>
                    <Form.Control
                        type="color"
                        value={menuSelectedInput}
                        onChange={(e) => setMenuSelectedInput(e.target.value)}
                    />
                </Form.Group>
            </Col>
            <Col lg={4} xl={3}>
                <Form.Group className="mb-3">
                    <Form.Label>Menu Preview</Form.Label>
                    <div className="menu-preview">
                        <div className="menu-preview-item" style={{ backgroundColor: menuBgInput }}>
                            Menu Item
                        </div>
                        <div className="menu-preview-item" style={{ backgroundColor: menuSelectedInput }}>
                            Selected Menu Item
                        </div>
                    </div>
                </Form.Group>

                <Form.Group>
                    <Button onClick={saveSettings} variant="primary">
                        Save Changes
                    </Button>
                </Form.Group>
                {hasUnsavedChanges() && <Form.Text className="text-danger">You have unsaved changes!</Form.Text>}
                {!storedSettings.tenantId && (
                    <Form.Text className="text-danger">This partner currently has no app settings saved.</Form.Text>
                )}
            </Col>
        </Row>
    );
}
