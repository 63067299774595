import { Alert } from "react-bootstrap";
import { useMembers } from "../../contexts/members";
import { LoadingSpinner } from "../loading/Loading";
import { MemberBenefits } from "../../models/MemberBenefits";
import {
    CanopyIdCard,
    PeehipIdCard,
    SeibIdCard,
    LghipIdCard,
    DrummondTwinPinesIdCard,
    DrummondDrswIdCard,
    DrummondDrldIdCard,
    DrummondDrsaIdCard,
    DrummondDrmwIdCard,
} from "./id-card-templates";
import { forwardRef } from "react";

const IdCard = forwardRef((props, ref: any) => {
    const { isLoading, benefits } = useMembers();

    const getCardTemplate = (memberBenefits: MemberBenefits[]): any => {
        if (!memberBenefits.length) return null;

        const groupNumber = memberBenefits[0].groupNumber;
        if (!groupNumber) return null;

        if (
            groupNumber.toUpperCase().startsWith("SBS") ||
            groupNumber.toUpperCase().startsWith("CIC") ||
            groupNumber === "5000" ||
            groupNumber === "0000005000"
        ) {
            return CanopyIdCard;
        }

        // if (groupNumber === "1000" || groupNumber === "0000001000") {
        //     return PeehipIdCard;
        // }

        // if (groupNumber === "2000" || groupNumber === "0000002000") {
        //     return SeibIdCard;
        // }

        // if (groupNumber === "2500" || groupNumber === "0000002500") {
        //     return LghipIdCard;
        // }

        // if (groupNumber.toUpperCase() === "DRTP") {
        //     return DrummondTwinPinesIdCard;
        // }

        // if (groupNumber.toUpperCase() === "DRSW") {
        //     // drummond DRWS (USWA Employee)
        //     return DrummondDrswIdCard;
        // }

        // if (groupNumber.toUpperCase() === "DRLD") {
        //     // drummond DRLD salaried employee
        //     return DrummondDrldIdCard;
        // }

        // if (groupNumber.toUpperCase() === "DRSA") {
        //     // drummond DRSA salaried employee
        //     return DrummondDrsaIdCard;
        // }

        // if (groupNumber.toUpperCase() === "DRMW") {
        //     // drummond DRMW (UMWA Employee)
        //     return DrummondDrmwIdCard;
        // }

        return null;
    };

    const getTestData = (groupNumber: string = "DRSW"): MemberBenefits[] => [
        {
            birthDate: "1/1/1985",
            firstName: "Bob",
            middleName: "K",
            lastName: "Smith",
            accountNumber: 12345,
            groupNumber: groupNumber,
            memberProductLinkEffectiveDate: "20200101",
            participantIDNumber: "12345678",
            planCode: "3099",
            memberProductLinkTermDate: "5/3/2022",
            productId: "DENTAL",
            productCategory: "Dental",
            memberProductEffectiveDate: "20200102",
            coverageOption: "E",
            groupName: "Happy Group",
        },
        {
            birthDate: "1/1/1985",
            firstName: "Bob",
            middleName: "K",
            lastName: "Smith",
            accountNumber: 12345,
            groupNumber: groupNumber,
            memberProductLinkEffectiveDate: "20200101",
            participantIDNumber: "12345678",
            planCode: "3099",
            memberProductLinkTermDate: "5/3/2022",
            productId: "DENTAL",
            productCategory: "Vision",
            memberProductEffectiveDate: "20200102",
            coverageOption: "E",
            groupName: "Happy Group",
        },
        {
            birthDate: "1/1/1985",
            firstName: "Bob",
            middleName: "K",
            lastName: "Smith",
            accountNumber: 12345,
            groupNumber: groupNumber,
            memberProductLinkEffectiveDate: "20200101",
            participantIDNumber: "12345678",
            planCode: "3099",
            memberProductLinkTermDate: "5/3/2022",
            productId: "DENTAL",
            productCategory: "Cancer",
            memberProductEffectiveDate: "20200102",
            coverageOption: "E",
            groupName: "Happy Group",
        },
        {
            birthDate: "1/1/1985",
            firstName: "Bob",
            middleName: "K",
            lastName: "Smith",
            accountNumber: 12345,
            groupNumber: groupNumber,
            memberProductLinkEffectiveDate: "20200101",
            participantIDNumber: "12345678",
            planCode: "3099",
            memberProductLinkTermDate: "5/3/2022",
            productId: "DENTAL",
            productCategory: "Indemnity",
            memberProductEffectiveDate: "20200102",
            coverageOption: "E",
            groupName: "Happy Group",
        },
    ];

    const getIdCard = (memberBenefits: MemberBenefits[]) => {
        const Component = getCardTemplate(memberBenefits);
        if (Component) {
            return <Component memberBenefits={memberBenefits} />;
        }
        return null;
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (benefits && !!benefits.length) {
        return (
            <div className="d-flex justify-content-center" ref={ref}>
                {getIdCard(benefits)}
            </div>
        );
    }

    return (
        <Alert variant="danger">
            There was a problem retrieving the member benefits. For more assistance, please call Customer Service at{" "}
            <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                (205) 451-0444
            </a>
            .
        </Alert>
    );
});

export default IdCard;
