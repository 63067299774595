import { IAwsFileService } from "../../services/aws/IAwsFileService";
import { UPLOAD_IMAGE, CLEAR_IMAGE, UploadImageAction, ClearImageAction } from "./UploadImageActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { AwsFile } from "../../models/AwsFile";

export function uploadImage(awsFile: AwsFile): KeysysThunkAction<void, UploadImageAction> {
    return async (dispatch, getState, container) => {
        const awsFileService = container.get<IAwsFileService>(TYPES.AwsFileService);
        const uploadedAwsFile = await awsFileService.uploadImage(awsFile);
        if (uploadedAwsFile) {
            dispatch<UploadImageAction>({
                type: UPLOAD_IMAGE,
                awsFile: uploadedAwsFile,
            });
        }
        return !!uploadedAwsFile;
    };
}

export function clearImage(): ClearImageAction {
    return {
        type: CLEAR_IMAGE,
    };
}
