import { Accordion, Button, ButtonToolbar, Card, Col, Container, Modal, Row } from "react-bootstrap";
import React, { useCallback, useRef, useState } from "react";
import html2pdf from "html2pdf.js";

import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import { useFetch } from "../../hooks/useFetch";
import { getDateTimeForFilename } from "../../helper-functions/getDateStringForFileName";
import { EnrollmentUploadResult } from "../../models/Enrollment";
import { LoadingSpinner } from "../loading/Loading";
import Badge from "react-bootstrap/Badge";
import { CoverageFormModel } from "./CoverageFormModel";
import { formatSSN, maskSSN } from "../../helper-functions/ssn";

export default function Review() {
    const { postFormData } = useFetch();
    const {
        newMember,
        setActiveStep,
        resetFlags,
        setFilename,
        setUploadSuccess,
        isUpdating,
        updatedMember,
        dbMember,
    } = useMemberEnrollments();

    const addedDependents = newMember?.dependent?.filter(
        (newMemDep) => !dbMember?.dependent?.some((dbDep) => newMemDep.id === dbDep.id)
    );
    const removedDependents = dbMember?.dependent?.filter(
        (dbMemDep) => !newMember?.dependent?.some((newMemDep) => dbMemDep.id === newMemDep.id)
    );
    const renderCoverageBadge = (key: string, coverage: CoverageFormModel): JSX.Element => {
        let returnJsx: JSX.Element = <></>;
        const isCoverageInDb = !!dbMember?.coverage && key in dbMember.coverage;
        if (isCoverageInDb && coverage.terminationDate) {
            returnJsx = (
                <Badge bg="danger" className=" danger-badge m-1">
                    TERMINATED
                </Badge>
            );
        }
        if (!isCoverageInDb && !coverage.terminationDate) {
            returnJsx = (
                <Badge bg="primary" className="m-1">
                    ADDED
                </Badge>
            );
        }
        return returnJsx;
    };

    const renderUpatedBadge = (): JSX.Element => {
        return (
            <Badge bg="warning" className="warning-badge m-1">
                UPDATED
            </Badge>
        );
    };

    const ref = useRef<HTMLDivElement>(null);
    const activeKey = ["PersonalInfoKey", "DependentKey", "CoverageKey"];

    const [isConfirming, setIsConfirming] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isMasking, setIsMasking] = useState(true);

    const printPdf = useCallback(
        (filename: string): Promise<Blob> | undefined => {
            if (ref.current) {
                const options = {
                    margin: 2,
                    filename,
                    pagebreak: { before: ".pdfPageBreak", avoid: "tr" },
                };

                return html2pdf().from(ref.current).set(options).toPdf().output("blob");
            }
        },
        [ref]
    );

    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        setIsMasking(false);
        const filename = `${getDateTimeForFilename()}-${newMember?.group}-${newMember?.personalInformation?.lastName}-${
            isUpdating ? "UpdateMember.pdf" : "NewMember.pdf"
        }`;

        const blob = await printPdf(filename);
        if (blob) {
            const formData = new FormData();
            formData.append("file", blob, filename);
            const response = await postFormData<EnrollmentUploadResult>("enrollments/upload", formData);

            setFilename(filename);
            setUploadSuccess(response?.success);
        }
        setIsSubmitting(false);
        setIsConfirming(false);
        setIsMasking(true);
        setActiveStep((prev) => prev + 1);
    }, [isUpdating, newMember?.group, postFormData, printPdf, setActiveStep, setFilename, setUploadSuccess]);

    return (
        <>
            <div ref={ref}>
                <Container as={Col}>
                    <Row className={isSubmitting ? "d-none" : "mb-3"}>
                        <Col className="d-flex justify-content-end">
                            <Button variant="primary" onClick={() => setIsConfirming(true)} disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p className="m-0">
                                <b>Group</b>
                            </p>
                            <p className="m-0">{newMember?.group}</p>
                        </Col>
                        <Col>
                            <p className="m-0">
                                <b>Account</b>
                            </p>
                            <p className="m-0">{newMember?.account}</p>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p className="m-0">
                                <b>Hire Date</b>
                            </p>
                            <p className="m-0">{newMember?.hireDate?.toDateString()}</p>
                        </Col>
                        <Col>
                            <p className="m-0">
                                <b>Application Date</b>
                            </p>
                            <p className="m-0">{newMember?.applicationDate?.toDateString()}</p>
                        </Col>
                    </Row>
                </Container>
                <Accordion activeKey={activeKey}>
                    <Accordion.Item eventKey="PersonalInfoKey">
                        <Accordion.Header>Personal Information</Accordion.Header>
                        <Accordion.Body>
                            <Row className={isSubmitting ? "d-none" : "mb-3"}>
                                <Col className="d-flex justify-content-end">
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            resetFlags();
                                            setActiveStep(0);
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            </Row>
                            <Container as={Col}>
                                <Row className="mb-4">
                                    <Col xs="6">
                                        <p className="m-0">
                                            <b>First Name</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.firstName &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.firstName}</p>
                                    </Col>
                                    <Col xs="6">
                                        <p className="m-0">
                                            <b>Last Name</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.lastName &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.lastName}</p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="d-flex align-items-center">
                                        <div>
                                            <p className="m-0">
                                                <b>Social Security Number</b>
                                                {isUpdating &&
                                                    updatedMember?.personalInformation?.governmentIdentifier &&
                                                    renderUpatedBadge()}
                                            </p>
                                            <p className="m-0">
                                                {isMasking
                                                    ? maskSSN(newMember?.personalInformation?.governmentIdentifier)
                                                    : formatSSN(newMember?.personalInformation?.governmentIdentifier)}
                                            </p>
                                        </div>
                                        <Button
                                            size="sm"
                                            className={isSubmitting ? "d-none" : "mx-4"}
                                            title={isMasking ? "Show" : "Hide"}
                                            onClick={() => setIsMasking((prev) => !prev)}
                                            variant="secondary"
                                        >
                                            <i className={isMasking ? "far fa-eye" : "far fa-eye-slash"} />
                                        </Button>
                                    </Col>
                                    <Col>
                                        <p className="m-0">
                                            <b>Date of Birth</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.dob &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.dob?.toDateString()}</p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <p className="m-0">
                                            <b>Gender</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.gender &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.gender}</p>
                                    </Col>
                                    <Col>
                                        <p className="m-0">
                                            <b>Marital Status</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.maritalStatus &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.maritalStatus}</p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="3">
                                        <p className="m-0">
                                            <b>Smoker</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.smoker &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.smoker}</p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <p className="m-0">
                                            <b>Street Address</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.streetAddress &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.streetAddress}</p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="6">
                                        <p className="m-0">
                                            <b>City</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.city &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.city}</p>
                                    </Col>
                                    <Col xs="3">
                                        <p className="m-0">
                                            <b>State</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.state &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.state}</p>
                                    </Col>
                                    <Col xs="3">
                                        <p className="m-0">
                                            <b>Zip</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.zip &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.zip}</p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <p className="m-0">
                                            <b>Phone</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.phone &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.phone}</p>
                                    </Col>
                                    <Col>
                                        <p className="m-0">
                                            <b>Email Address</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.emailAddress &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.emailAddress}</p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="6">
                                        <p className="m-0">
                                            <b>Location</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.location &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">{newMember?.personalInformation?.location}</p>
                                    </Col>
                                    <Col xs="3">
                                        <p className="m-0">
                                            <b>Effective Date</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.effectiveDate &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">
                                            {newMember?.personalInformation?.effectiveDate?.toLocaleDateString()}
                                        </p>
                                    </Col>
                                    <Col xs="3">
                                        <p className="m-0">
                                            <b>Termination Date</b>
                                            {isUpdating &&
                                                updatedMember?.personalInformation?.terminationDate &&
                                                renderUpatedBadge()}
                                        </p>
                                        <p className="m-0">
                                            {newMember?.personalInformation?.terminationDate?.toLocaleDateString()}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mb-4"></Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="DependentKey">
                        <Accordion.Header>Dependent</Accordion.Header>
                        <Accordion.Body>
                            <Row className={isSubmitting ? "d-none" : "mb-3"}>
                                <Col className="d-flex justify-content-end">
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            resetFlags();
                                            setActiveStep(1);
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            </Row>
                            {newMember?.dependent?.map((dep) => (
                                <Row className="mb-2">
                                    <Col>
                                        <Card key={dep.id}>
                                            <Card.Body>
                                                <Card.Title>
                                                    {`Dependent - ${dep.id}`}
                                                    {isUpdating && addedDependents?.includes(dep) && (
                                                        <Badge bg="primary" className="m-1">
                                                            NEW
                                                        </Badge>
                                                    )}
                                                </Card.Title>
                                                <Card.Text>
                                                    <Row className="mb-4">
                                                        <Col>
                                                            <p className="m-0">
                                                                <b>First Name</b>
                                                                {isUpdating &&
                                                                    updatedMember?.dependent?.some(
                                                                        (d) => d.id === dep.id && d.firstName
                                                                    ) &&
                                                                    renderUpatedBadge()}
                                                            </p>
                                                            <p className="m-0">{dep.firstName}</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="m-0">
                                                                <b>Last Name</b>
                                                                {isUpdating &&
                                                                    updatedMember?.dependent?.some(
                                                                        (d) => d.id === dep.id && d.lastName
                                                                    ) &&
                                                                    renderUpatedBadge()}
                                                            </p>
                                                            <p className="m-0">{dep.lastName}</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="m-0">
                                                                <b>Date of Birth</b>
                                                                {isUpdating &&
                                                                    updatedMember?.dependent?.some(
                                                                        (d) => d.id === dep.id && d.dob
                                                                    ) &&
                                                                    renderUpatedBadge()}
                                                            </p>
                                                            <p className="m-0">{dep.dob?.toLocaleDateString()}</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="m-0">
                                                                <b>Relationship</b>
                                                                {isUpdating &&
                                                                    updatedMember?.dependent?.some(
                                                                        (d) => d.id === dep.id && d.relationship
                                                                    ) &&
                                                                    renderUpatedBadge()}
                                                            </p>
                                                            <p className="m-0">{dep.relationship}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Col>
                                                            <p className="m-0">
                                                                <b>Effective Date</b>
                                                                {isUpdating &&
                                                                    updatedMember?.dependent?.some(
                                                                        (d) => d.id === dep.id && d.effectiveDate
                                                                    ) &&
                                                                    renderUpatedBadge()}
                                                            </p>
                                                            <p className="m-0">
                                                                {dep.effectiveDate?.toLocaleDateString()}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            ))}
                            {isUpdating &&
                                removedDependents?.map((dep) => (
                                    <Row className="mb-2">
                                        <Col>
                                            <Card key={dep.id}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        {`Dependent - ${dep.id}`}
                                                        <Badge bg="danger" className="danger-badge m-1">
                                                            REMOVED
                                                        </Badge>
                                                    </Card.Title>
                                                    <Card.Text>
                                                        <Row className="mb-4">
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>First Name</b>
                                                                </p>
                                                                <p className="m-0">{dep.firstName}</p>
                                                            </Col>
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>Last Name</b>
                                                                </p>
                                                                <p className="m-0">{dep.lastName}</p>
                                                            </Col>
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>Date of Birth</b>
                                                                </p>
                                                                <p className="m-0">{dep.dob?.toLocaleDateString()}</p>
                                                            </Col>
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>Relationship</b>
                                                                </p>
                                                                <p className="m-0">{dep.relationship}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-4">
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>Effective Date</b>
                                                                </p>
                                                                <p className="m-0">
                                                                    {dep.effectiveDate?.toLocaleDateString()}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="CoverageKey">
                        <Accordion.Header>Coverage</Accordion.Header>
                        <Accordion.Body>
                            <Row className={isSubmitting ? "d-none" : "mb-3"}>
                                <Col className="d-flex justify-content-end">
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            resetFlags();
                                            setActiveStep(2);
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            </Row>
                            {!!newMember?.coverage &&
                                Object.entries(newMember.coverage).map(([key, coverage], index) => (
                                    <Row className="mb-2">
                                        <Col>
                                            <Card key={index}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        {key}

                                                        {isUpdating && renderCoverageBadge(key, coverage)}
                                                    </Card.Title>
                                                    <Card.Text>
                                                        <Row className="mb-4">
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>Plan</b>
                                                                    {isUpdating &&
                                                                        updatedMember?.coverage?.hasOwnProperty(key) &&
                                                                        updatedMember?.coverage[key].plan &&
                                                                        renderUpatedBadge()}
                                                                </p>
                                                                <p className="m-0">{coverage.plan}</p>
                                                            </Col>
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>Coverage</b>
                                                                    {isUpdating &&
                                                                        updatedMember?.coverage?.hasOwnProperty(key) &&
                                                                        updatedMember?.coverage[key].coverage &&
                                                                        renderUpatedBadge()}
                                                                </p>
                                                                <p className="m-0">{coverage.coverage}</p>
                                                            </Col>
                                                            <Col>
                                                                <p className="m-0">
                                                                    <b>Effective Date</b>
                                                                    {isUpdating &&
                                                                        updatedMember?.coverage?.hasOwnProperty(key) &&
                                                                        updatedMember?.coverage[key].effectiveDate &&
                                                                        renderUpatedBadge()}
                                                                </p>
                                                                <p className="m-0">
                                                                    {coverage.effectiveDate?.toLocaleDateString()}
                                                                </p>
                                                            </Col>
                                                            {isUpdating && (
                                                                <Col>
                                                                    <p className="m-0">
                                                                        <b>Termination Date</b>
                                                                        {updatedMember?.coverage?.hasOwnProperty(key) &&
                                                                            updatedMember?.coverage[key]
                                                                                .terminationDate &&
                                                                            renderUpatedBadge()}
                                                                    </p>
                                                                    <p className="m-0">
                                                                        {coverage.terminationDate?.toLocaleDateString()}
                                                                    </p>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <Modal
                centered
                id="confirm-submit-enrollment"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                show={isConfirming}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Submit Enrollment Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You will not be able to return to this screen. Are you sure you want to submit it?
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center">
                        {isSubmitting && (
                            <div className="mx-2">
                                <LoadingSpinner size="sm" />
                            </div>
                        )}
                        <ButtonToolbar>
                            <Button disabled={isSubmitting} variant="primary" size="sm" onClick={handleSubmit}>
                                Yes
                            </Button>
                            &nbsp;
                            <Button
                                disabled={isSubmitting}
                                variant="dark"
                                size="sm"
                                onClick={() => setIsConfirming(false)}
                            >
                                No
                            </Button>
                        </ButtonToolbar>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
