import { AccessToken } from "../models/AccessToken";

export const LOGGED_IN = "LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAIL = "LOGIN_FAIL";

export interface LoggedInAction {
    type: typeof LOGGED_IN;
    accessToken: string;
    decodedAccessToken: AccessToken;
    refreshToken: string;
}

export interface LogoutAction {
    type: typeof LOGOUT;
}

export interface LoginFailAction {
    type: typeof LOGIN_FAIL;
}

export type AuthenticateActions = LoggedInAction | LogoutAction | LoginFailAction;
