import { AwsFileState } from "../state/AwsFileState";
import { UploadImageActions, UPLOAD_IMAGE, CLEAR_IMAGE } from "../actions/aws/UploadImageActions";

const initialState: AwsFileState = {
    awsFiles: [],
};

export function awsFileReducer(state: AwsFileState = initialState, action: UploadImageActions): AwsFileState {
    switch (action.type) {
        case UPLOAD_IMAGE:
            return {
                ...state,
                awsFiles: [...state.awsFiles, action.awsFile],
            };
        case CLEAR_IMAGE:
            return {
                ...state,
                awsFiles: [],
            };
        default:
            return state;
    }
}
