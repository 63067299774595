import { ColDef } from "ag-grid-community";
import { useMemo } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useClaims } from "../../contexts/claims";
import { Claim } from "../../models/Claim";
import KeysysGrid from "../grid/KeysysGrid";
import { LoadingSpinner } from "../loading/Loading";
import LightSection from "../page-sections/LightSection";

export default function Claims() {
    const { claims, getEobPdf, isGettingClaims, isGettingPdf, hasPdfError, setHasPdfError, claimsFilter } = useClaims();

    const filteredClaims = useMemo(() => {
        return claimsFilter
            ? claims.filter(
                  (c) => `${c.claimantFirstName} ${c.claimantLastName}`.toLowerCase() === claimsFilter.toLowerCase()
              )
            : claims;
    }, [claims, claimsFilter]);

    const getProviderPaymentStatus = (code: string): string => {
        // issued, all, closed, paid, not issued, pended, voided
        switch (code) {
            case "I":
                return "Issued";
            case "V":
                return "Void";
            case "S":
                return "Suspended";
            case "X":
                return "Closed";
            default:
                return "";
        }
    };

    const getPatientName = (data: Claim): string => {
        let result = data.claimantFirstName;
        if (data.claimantMiddleName) {
            result += ` ${data.claimantMiddleName}`;
        }
        result += ` ${data.claimantLastName}`;
        return result;
    };

    const getServiceDate = (data: Claim): string => {
        return new Date(
            data.serviceFromDateYear,
            data.serviceFromDateMonth - 1,
            data.serviceFromDateDay
        ).toLocaleDateString();
    };

    const getCheckTotal = (data: Claim): string => {
        return (data.providerCheckAmount + data.employeeCheckAmount).toFixed(2);
    };

    const columnDefs: ColDef[] = [
        {
            headerName: "Claim Number",
            field: "claimNumber",
        },
        {
            headerName: "Patient",
            field: "patient",
            valueGetter: ({ data }) => getPatientName(data),
        },
        {
            headerName: "Member ID",
            field: "participantIDNumber",
        },
        {
            headerName: "Service Date",
            field: "serviceDate",
            cellRenderer: ({ data }) => getServiceDate(data),
            valueGetter: ({ data }) => new Date(getServiceDate(data)).getTime(),
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (valueA == valueB) return 0;
                return valueA > valueB ? 1 : -1;
            },
            sort: "desc",
            sortIndex: 1,
            maxWidth: 150,
        },
        {
            headerName: "Submitted",
            field: "originalPendingAmount",
            cellRenderer: ({ value }) => value.toFixed(2),
            cellClass: "d-flex justify-content-end",
            maxWidth: 130,
        },
        {
            headerName: "Patient Responsibility",
            field: "patientResponsibility",
            cellRenderer: ({ value }) => value.toFixed(2),
            cellClass: "d-flex justify-content-end",
        },
        {
            headerName: "Check Total(s)",
            field: "providerCheckAmount",
            valueGetter: ({ data }) => getCheckTotal(data),
            cellClass: "d-flex justify-content-end",
            maxWidth: 150,
        },
        {
            headerName: "Status",
            field: "providerPaymentStatus",
            cellRenderer: ({ value }) => getProviderPaymentStatus(value),
        },
        {
            headerName: "EOB",
            field: "nothingReally",
            sortable: false,
            filter: false,
            maxWidth: 70,
            resizable: false,
            cellRendererFramework: ({ data }: any) => {
                return (
                    <Button
                        variant="primary"
                        onClick={() => getEobPdf(data)}
                        size="sm"
                        title="Download EOB"
                        disabled={isGettingPdf}
                    >
                        <i className="fas fa-download" />
                    </Button>
                );
            },
        },
    ];

    if (isGettingClaims) {
        return (
            <LightSection>
                <LoadingSpinner />
            </LightSection>
        );
    }

    if (!!filteredClaims.length) {
        return (
            <>
                <div className="ag-theme-alpine">
                    <KeysysGrid
                        data={filteredClaims}
                        columnDefs={columnDefs}
                        getRowId={(data) => data.claimNumber}
                        pagination={filteredClaims.length > 20}
                        pageSize={10}
                    />
                </div>

                <Modal centered show={isGettingPdf || hasPdfError} onHide={() => setHasPdfError(false)}>
                    <Modal.Body as={Alert} variant={hasPdfError ? "danger" : "success"} className="m-0">
                        {isGettingPdf && (
                            <>
                                Downloading PDF...
                                <LoadingSpinner />
                            </>
                        )}
                        {hasPdfError && (
                            <>
                                There was a problem retrieving the EOB. For more assistance, please call Customer
                                Service at{" "}
                                <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                                    (205) 451-0444
                                </a>
                                .
                            </>
                        )}
                    </Modal.Body>
                    {hasPdfError && (
                        <Modal.Footer>
                            <Button onClick={() => setHasPdfError(false)}>Continue</Button>
                        </Modal.Footer>
                    )}
                </Modal>
            </>
        );
    }

    return <p>No claims found.</p>;
}
