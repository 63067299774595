import { Button, Card, Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useMemo, useRef } from "react";
import * as Yup from "yup";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import { DependentFormModel } from "./DependentFormModel";
import { Form, Formik, FormikProps } from "formik";
import KeysysInput from "../form/input/KeysysInput";
import KeysysSelect from "../form/select/KeysysSelect";
import KeysysDatetime from "../form/datetime/KeysysDatetime";

const DependentForm = React.memo(({ dependent, onRemove, onValidationSuccess }: any) => {
    const relationshipOptions = useMemo(() => {
        return [
            { value: "Child", label: "Child" },
            { value: "Spouse", label: "Spouse" },
            { value: "Other", label: "Other" },
        ];
    }, []);

    const ref = useRef<FormikProps<any>>(null);

    const {
        setNextClicked,
        backClicked,
        nextClicked,
        activeStep,
        setNewMember,
        validateDependents,
        isUpdating,
        updatedMember,
        setUpdatedMember,
        dbMember,
    } = useMemberEnrollments();
    const dependentSchema = Yup.object({
        firstName: Yup.string().required("First Name is Required"),
        lastName: Yup.string().required("Last Name is Required"),
        dob: Yup.date().nullable().required("Date of Birth is Required"),
        relationship: Yup.string().required("Relationship is Required"),
    });

    const handleUpdatedFields = (values: DependentFormModel) => {
        const updatedFields: Partial<DependentFormModel> = { id: values.id };
        const dbDependent = dbMember?.dependent?.find((dep) => dep.id === dependent.id);

        if (dbDependent) {
            Object.keys(values).forEach((key) => {
                if (key === "dob" || key === "effectiveDate") {
                    if (values[key]?.toDateString() !== dbDependent[key]?.toDateString()) {
                        updatedFields[key] = values[key];
                    }
                } else {
                    if (values[key] !== dbDependent[key]) {
                        updatedFields[key] = values[key];
                    }
                }
            });

            const updatedDependents = updatedMember?.dependent ? [...updatedMember.dependent] : [];
            const dependentIndex = updatedDependents.findIndex((dependent) => dependent.id === updatedFields.id);
            if (dependentIndex !== -1) {
                updatedDependents[dependentIndex] = updatedFields;
            } else {
                updatedDependents.push(updatedFields as DependentFormModel);
            }
            setUpdatedMember((prev) => ({
                ...prev,
                dependent: updatedDependents,
            }));
        } else {
            setUpdatedMember((prev) => ({
                ...prev,
                addedDependents: [...(prev?.addedDependents || []), values.id],
            }));
        }
    };
    const handleValidation = async (values: DependentFormModel) => {
        if (ref.current?.isValid) {
            if (isUpdating) {
                handleUpdatedFields(values);
            }

            let newValue;
            setNewMember((prevNewMember) => {
                if (prevNewMember !== undefined) {
                    let updatedDependentArray: DependentFormModel[];
                    if (prevNewMember.dependent) {
                        updatedDependentArray = prevNewMember.dependent.map((dep) => {
                            if (dep.id === dependent.id) {
                                return {
                                    ...dep,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    dob: values.dob,
                                    relationship: values.relationship,
                                    effectiveDate: values.effectiveDate,
                                };
                            }
                            return dep;
                        });
                        const foundDependent = updatedDependentArray.find((dep) => dep.id === dependent.id);
                        if (!foundDependent) {
                            updatedDependentArray.push({
                                id: values.id,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                dob: values.dob,
                                relationship: values.relationship,
                                effectiveDate: values.effectiveDate,
                            });
                        }
                    } else {
                        updatedDependentArray = [
                            {
                                id: 1,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                dob: values.dob,
                                relationship: values.relationship,
                                effectiveDate: values.effectiveDate,
                            },
                        ];
                    }
                    newValue = {
                        ...prevNewMember,
                        personalInformation: prevNewMember.personalInformation,
                        dependent: updatedDependentArray,
                    };
                    return newValue;
                }
                return prevNewMember;
            });
            await onValidationSuccess(newValue);
        }
    };

    useEffect(() => {
        const triggerSubmit = async () => {
            await ref.current?.submitForm();
            if (!ref.current?.isValid) {
                setNextClicked(false);
            }
        };

        if (!backClicked && nextClicked && activeStep === 1 && validateDependents) {
            triggerSubmit();
        }
    }, [activeStep, backClicked, nextClicked, setNextClicked, validateDependents]);

    return (
        <Container className="m-2">
            <Formik
                innerRef={ref}
                initialValues={dependent}
                validationSchema={dependentSchema}
                onSubmit={(values) => handleValidation(values)}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<DependentFormModel>) => {
                    return (
                        <Form id="dependent-form-id">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{`Dependent ${dependent.id}`}</Card.Title>
                                    <Card.Text>
                                        <Row className="m-2">
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"firstName"}
                                                    placeholder={""}
                                                    fieldName={"firstName"}
                                                    label={"First Name"}
                                                    required
                                                />
                                            </Col>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"lastName"}
                                                    placeholder={""}
                                                    fieldName={"lastName"}
                                                    label={"Last Name"}
                                                    required
                                                />
                                            </Col>
                                            <Col>
                                                <KeysysSelect
                                                    formProps={formProps}
                                                    id="relationship"
                                                    placeholder="Select"
                                                    fieldName="relationship"
                                                    label="Relationship"
                                                    options={relationshipOptions}
                                                    multiple={false}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="m-2">
                                            <Col xs="4">
                                                <KeysysDatetime
                                                    formProps={formProps}
                                                    id="dob"
                                                    placeholder="Select a date"
                                                    fieldName="dob"
                                                    label="Date of Birth"
                                                    required
                                                />
                                            </Col>
                                            <Col xs="4">
                                                <KeysysDatetime
                                                    formProps={formProps}
                                                    id="effectiveDate"
                                                    placeholder="Select a date"
                                                    fieldName="effectiveDate"
                                                    label="Effective Date"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 ">
                                            <Col className="d-flex justify-content-end ">
                                                <Button variant="secondary" onClick={() => onRemove(dependent.id)}>
                                                    Remove Dependent
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
});
export default DependentForm;
