interface MenuOptionItems {
    title: string;
    path: string;
}

export interface MenuOptionSection {
    title: string;
    items: MenuOptionItems[];
}

export const GroupAdminMenuOptions: {
    customerService: MenuOptionSection;
    manageEnrollment: MenuOptionSection;
} = {
    customerService: {
        title: "Customer Service",
        items: [
            { title: "Billing/Payment Inquiry", path: "/billing" },
            { title: "Coverage Inquiry", path: "/coverage-inquiry" },
        ],
    },
    manageEnrollment: {
        title: "Manage Enrollment",
        items: [
            { title: "Add New Member", path: "/add-new-member" },
            { title: "Update Enrollment", path: "/update-enrollment" },
        ],
    },
};
