import React, { useEffect, useState } from "react";
import { GridOptions, GridSizeChangedEvent, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { IKeysysGrid } from "./IKeysysGrid";

export default function KeysysGrid(props: IKeysysGrid) {
    const propsGridOptions = props.gridOptions || {};
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    let gridOptions: GridOptions = {
        onGridReady: (params) => {
            setGridApi(params.api);
        },
        autoGroupColumnDef: {
            showRowGroup: true,
            minWidth: 200,
            headerName: props.initialHeader || "",
            // sortable: true
            // comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
            //     return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
            // },
        },
        // showOpenedGroup: true,
        ...propsGridOptions,
        suppressAggFuncInHeader: true,
    };

    if (props.externalFilter) {
        gridOptions = {
            doesExternalFilterPass: props.externalFilter,
            isExternalFilterPresent: () => true,
        };
    }

    const onSearch = (searchString: string | undefined) => {
        gridApi?.setQuickFilter(searchString);
    };

    useEffect(() => {
        if (gridApi && props.pageSize) {
            gridApi.paginationSetPageSize(props.pageSize);
        }
    }, [props.pageSize]);

    useEffect(() => {
        if (gridApi) {
            onSearch(props.searchText);
        }
    }, [props.searchText, gridApi]);

    return (
        <div>
            <AgGridReact
                colResizeDefault={"shift"}
                paginateChildRows
                onGridSizeChanged={(event: GridSizeChangedEvent) => {
                    event.api.sizeColumnsToFit();
                }}
                {...gridOptions}
                immutableData
                pagination={props.pagination}
                paginationPageSize={props.pageSize}
                columnDefs={props.columnDefs}
                getRowNodeId={(data) => {
                    if (props.getRowId) {
                        return props.getRowId(data);
                    }
                    return data?.id;
                }}
                defaultColDef={{
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    //autoHeight: true,
                    suppressMenu: true,
                    filter: false,
                    cellStyle: {
                        lineHeight: "2",
                        alignItems: "center",
                        display: "flex",
                        paddingRight: "16",
                        paddingLeft: "16",
                    },
                }}
                rowData={props.getRows ? props.getRows() : props.data || []}
            />
        </div>
    );
}
