import { useEffect, useState } from "react";
import { ICustomerForm } from "./ICustomerForm";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { AddCustomerFormModel } from "./AddCustomerFormModel";
import VerticallyCenteredModal from "../../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../state/AppState";
import { Card, Col, Row } from "react-bootstrap";
import KeysysInput from "../../form/input/KeysysInput";
import {
    createTenant,
    deleteTenant,
    editTenant,
    getCustomerMenuItems,
} from "../../../actions/tenants/TenantActionCreators";
import { Tenant } from "../../../models/Tenant";
import * as Yup from "yup";
import ConfirmationModal from "../../modal/ConfirmationModal/ConfirmationModal";
import SmallRoundCheckbox from "../../checkbox/small-round-checkbox/SmallRoundCheckbox";
import KeysysSelect from "../../form/select/KeysysSelect";
import { KeysysSelectOption } from "../../form/select/KeysysSelectOption";
import FormBuilder, { ApiViewFields } from "../../form-builder/FormBuilder";
import { CustomerMenuItemCreate } from "../../../models/CustomerMenuItem";
import { useApp } from "../../../contexts/app";

export default function CustomerForm(props: ICustomerForm) {
    const dispatch = useDispatch();
    const { customerName, partnerName } = useApp();
    const [isActiveValue, setIsActiveValue] = useState<boolean>(false);
    const [pullSmartsheets, setPullSmartsheets] = useState<boolean>(false);
    const [finosecAcademy, setfinosecAcademy] = useState<boolean>(false);
    const [uarAccess, setUARAccess] = useState<boolean>(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showConfirmReactivateModal, setShowConfirmReactivateModal] = useState<boolean>(false);

    const tenants = useSelector<AppState, Tenant[]>((s) =>
        s.tenantState.tenants.sort((a, b) => a.name.localeCompare(b.name))
    );

    const partnertenants = useSelector<AppState, Tenant[]>((s) =>
        s.tenantState.tenants
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((x) => x.parentTenantId === null || x.parentTenantId === undefined)
    );

    const partnerTenantOptions: KeysysSelectOption[] = partnertenants.map(
        (tenant) => ({ value: tenant.id, label: `${tenant.name}` } as KeysysSelectOption)
    );
    const menuItems = useSelector((state: AppState) =>
        props.type === "edit"
            ? state.tenantState.customerMenuItems.filter((item) => item.tenantId === props.tenant?.id)
            : []
    );

    const tenant = tenants.find((t) => t.id === props.tenant?.id);
    const currentPartnerTenantId = tenant?.parentTenantId;

    useEffect(() => {
        if (props.tenant?.isActive) {
            setIsActiveValue(props.tenant?.isActive);
        }
        if (props.tenant?.pullSmartsheets) {
            setPullSmartsheets(props.tenant?.pullSmartsheets);
        }
        if (props.tenant?.finosecAcademy) {
            setfinosecAcademy(props.tenant?.finosecAcademy);
        }
        if (props.tenant?.uarAccess) {
            setUARAccess(props.tenant?.uarAccess);
        }
    }, [props]);

    useEffect(() => {
        if (props.tenant?.id) {
            dispatch(getCustomerMenuItems(props.tenant.id));
        }
    }, [props.tenant]);

    function getInitialValues() {
        const parentTenantId = props.type === "add" ? currentPartnerTenantId : props.tenant?.parentTenantId;
        return {
            name: props.tenant?.name ?? "",
            parentTenantId,
            usersCount: props.tenant?.usersCount ?? 0,
            isActive: props.tenant?.isActive ?? true,
            pathfinderUrl: props.tenant?.pathfinderUrl ?? "",
            sysorecUrl: props.tenant?.sysorecUrl ?? "",
            pullSmartsheets: props.tenant?.pullSmartsheets ?? false,
            finosecAcademy: props.tenant?.finosecAcademy ?? false,
            uarAccess: props.tenant?.uarAccess ?? false,
            menuOptions: menuItems,
        };
    }

    const customerSchema = Yup.object<AddCustomerFormModel>().shape({
        name: Yup.string().max(50, "Name must be 50 characters or less").required("Name is required"),
        distributionEmail: Yup.string().email("Invalid email format"),
    });

    function clearFormAndHide(
        formProps: FormikProps<AddCustomerFormModel> | FormikHelpers<AddCustomerFormModel>
    ): void {
        formProps.resetForm();
        props.onClose();
    }

    const meta: ApiViewFields[] = [
        {
            key: "menuOptions",
            label: "Menu Items",
            type: "array",
            arrayChunk: 1,
            dynamicData: true,
            fields: [
                {
                    key: "id",
                    label: "Identifier",
                    type: "input",
                    hidden: true,
                    controlStyle: {
                        width: "55px",
                    },
                },
                {
                    key: "icon",
                    label: "Icon",
                    type: "iconSelector",
                    placeholder: "select icon",
                },
                {
                    key: "name",
                    label: "Label",
                    type: "input",
                    placeholder: "Enter Name",
                },
                {
                    key: "url",
                    label: "URL",
                    controlStyle: {
                        width: "50%",
                    },
                    type: "input",
                    placeholder: "Enter URL",
                },
                {
                    key: "ordinal",
                    label: "Order",
                    type: "input",
                    placeholder: "Enter Order",
                },
            ],
        },
    ];

    function handleSubmit(value: any, FormikHelpers: FormikHelpers<any>) {
        const { menuOptions, ...values } = value;
        console.log(values);
        if (props.type === "add") {
            const tenantToCreate: Tenant = {
                id: 0,
                name: values.name,
                logoUri: "",
                usersCount: values.usersCount,
                parentTenantId: values.parentTenantId,
                pathfinderUrl: values.pathfinderUrl,
                sysorecUrl: values.sysorecUrl,
                pullSmartsheets: values.pullSmartsheets,
                finosecAcademy: values.finosecAcademy,
                isActive: true,
                uarAccess: uarAccess,
            };
            const menuItemsToCreate = menuOptions.map((option: any) => {
                const { id, ...returnOption } = option;
                return returnOption;
            });
            dispatch(createTenant(tenantToCreate, menuItemsToCreate));
        } else {
            const newMenuItems = menuOptions
                .filter((option: CustomerMenuItemCreate) => !option.id)
                .map((option: any) => {
                    const { id, ...returnOption } = option;
                    return returnOption;
                });
            const editMenuItems = menuOptions.filter((option: CustomerMenuItemCreate) => option.id);
            const itemsToDelete = menuItems.filter(
                (item) => !menuOptions.map((option: CustomerMenuItemCreate) => option.id).includes(item.id)
            );
            const editedTenant: Tenant = {
                ...props.tenant!,
                id: props.tenant!.id,
                name: values.name,
                logoUri: "",
                usersCount: values.usersCount,
                parentTenantId: values.parentTenantId,
                pathfinderUrl: values.pathfinderUrl,
                sysorecUrl: values.sysorecUrl,
                pullSmartsheets: pullSmartsheets,
                finosecAcademy: finosecAcademy,
                isActive: isActiveValue,
                uarAccess: uarAccess,
            };
            dispatch(editTenant(props.tenant!, editedTenant, newMenuItems, editMenuItems, itemsToDelete, menuItems));
        }
        clearFormAndHide(FormikHelpers);
    }

    function getTitle() {
        if (props.type === "add") {
            return `Add ${customerName}`;
        }
        if (props.type === "edit") {
            return `Edit ${customerName}`;
        }
        return customerName;
    }

    const handleShowConfirmModal = () => {
        if (props.tenant!.isActive) {
            setShowConfirmDeleteModal(true);
        } else {
            setShowConfirmReactivateModal(true);
        }
    };
    const handleDeleteCustomer = (tenant: Tenant) => {
        dispatch(deleteTenant(tenant));
        setShowConfirmDeleteModal(false);
        setShowConfirmReactivateModal(false);
        props.onClose();
    };

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={customerSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<AddCustomerFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditCustomerModal"
                            show={true}
                            title={getTitle()}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={true}
                            showDeleteButton={props.type === "edit"}
                            deleteButtonText={props.tenant?.isActive ? "Deactivate" : "Reactivate"}
                            onDeleteButtonClick={() => handleShowConfirmModal()}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="addEditCustomerForm" data-testid={"add-edit-customer-form"}>
                                <Card>
                                    <Card.Body>
                                        <Row className={"mb-4"}>
                                            {props.type === "add" && (
                                                <Col xs={12} lg={6}>
                                                    <KeysysSelect
                                                        formProps={formProps}
                                                        id={"parentTenant"}
                                                        placeholder={partnerName}
                                                        fieldName={"parentTenantId"}
                                                        label={`Select a ${partnerName}`}
                                                        options={partnerTenantOptions}
                                                        multiple={false}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"CustomerName"}
                                                    placeholder={"Name"}
                                                    fieldName={"name"}
                                                    label={"Name"}
                                                />
                                            </Col>
                                        </Row>
                                        {/* <Row className={"mb-4"}>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"pathfinderUrl"}
                                                    placeholder={"http://"}
                                                    fieldName={"pathfinderUrl"}
                                                    label={"Enter Current Pathfinder Smartsheet URL"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"sysorecUrl"}
                                                    placeholder={"http://"}
                                                    fieldName={"sysorecUrl"}
                                                    label={"Enter Current Sysorec Smartsheet URL"}
                                                />
                                            </Col>
                                        </Row> */}
                                        <Row className={"mb-4"}>
                                            <Col className={"d-inline-flex"}>
                                                <SmallRoundCheckbox
                                                    entityNameSingular="UARAccess"
                                                    id={"UARAccessCheckbox"}
                                                    checked={uarAccess}
                                                    onClick={() => {
                                                        setUARAccess(!uarAccess);
                                                    }}
                                                />
                                                <label htmlFor="UARAccess">UAR Access</label>
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            {props.type === "edit" && (
                                                <Col className={"d-inline-flex"}>
                                                    <SmallRoundCheckbox
                                                        entityNameSingular="PullSmartsheet"
                                                        id={"pullSmartsheetsCheckbox"}
                                                        checked={pullSmartsheets}
                                                        onClick={() => {
                                                            setPullSmartsheets(!pullSmartsheets);
                                                        }}
                                                    />
                                                    <label htmlFor="pullSmartsheets">
                                                        Automatically pull files from SmartSheets
                                                    </label>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row className={"mb-4"}>
                                            {props.type === "edit" && (
                                                <Col className={"d-inline-flex"}>
                                                    <SmallRoundCheckbox
                                                        entityNameSingular="finosecAcademy"
                                                        id={"finosecAcademyCheckbox"}
                                                        checked={finosecAcademy}
                                                        onClick={() => {
                                                            setfinosecAcademy(!finosecAcademy);
                                                        }}
                                                    />
                                                    <label htmlFor="finosecAcademy">Finosec Academy</label>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row className={"mb-4"}>
                                            {props.type === "edit" && (
                                                <Col className={"d-inline-flex"}>
                                                    <SmallRoundCheckbox
                                                        entityNameSingular="IsActive"
                                                        id={"isActiveCheckbox"}
                                                        checked={isActiveValue}
                                                        onClick={() => {
                                                            setIsActiveValue(!isActiveValue);
                                                        }}
                                                        disabled={true}
                                                    />
                                                    <label htmlFor="isActiveCheckbox">Is Active?</label>
                                                </Col>
                                            )}
                                        </Row>
                                        {/* <Formik {...formProps} onSubmit={(v) => console.log(v)}> */}
                                        <div
                                            style={{ display: "flex", flexDirection: "column" }}
                                            className="justify-content-md-center keysys-form"
                                        >
                                            <FormBuilder
                                                meta={meta}
                                                chunkNumber={1}
                                                initialValues={{ menuItems: [] }}
                                            />
                                        </div>
                                        {/* </Formik> */}
                                    </Card.Body>
                                </Card>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
            <ConfirmationModal
                id="confirmDeleteCustomerModal"
                show={showConfirmDeleteModal}
                title="Deactivate Customer?"
                message={`Are you sure you want to Deactivate this ${customerName}? They will no longer have access to the portal or documents.`}
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={() => handleDeleteCustomer(props.tenant!)}
                theme={"danger"}
            />
            <ConfirmationModal
                id="confirmReactivateCustomerModal"
                show={showConfirmReactivateModal}
                title="Reactivate Customer?"
                message={`Are you sure you want to Reactivate this ${customerName}?`}
                onCloseButtonClick={() => setShowConfirmReactivateModal(false)}
                onOkButtonClick={() => handleDeleteCustomer(props.tenant!)}
                theme={"danger"}
            />
        </>
    );
}
