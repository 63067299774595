import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "../../state/AppState";
import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../../images/Canopy_Logo.png";
import { logOut } from "../../actions/AuthenticationActionCreators";
import PageTitle from "./PageTitle";
import { useUsers } from "../../contexts/users";
import { UserTypes } from "../../models/UserTypes";
import { useCallback } from "react";
import { getSanitizedProviderName } from "../../helper-functions";
import { GroupAdminMenuOptions } from "../../constants/GroupAdminMenuOptions";

export default function Header() {
    const { decodedAccessToken, isAuthenticated } = useSelector((state: AppState) => state.authenticationState);
    const dispatch = useDispatch();
    const {
        userType,
        userState: { loggedInUser },
        myPermissionTypes,
    } = useUsers();
    const { tenant_name } = decodedAccessToken;
    const isAuthenticatedUser = isAuthenticated && !!tenant_name;
    const isGlobalAdmin = userType === UserTypes.GlobalAdmin;

    const getName = useCallback((): string => {
        if (
            loggedInUser &&
            (userType === UserTypes.Member || userType === UserTypes.SuperAdmin || userType === UserTypes.GroupAdmin)
        ) {
            return `${loggedInUser.firstName} ${loggedInUser.lastName}`;
        }

        return getSanitizedProviderName(tenant_name);
    }, [userType, loggedInUser, tenant_name]);

    const CustomerServiceDropdownOptions = () => {
        return (
            <>
                <NavDropdown.Header>{GroupAdminMenuOptions.customerService.title}</NavDropdown.Header>
                {GroupAdminMenuOptions.customerService.items.map((item) => (
                    <NavDropdown.Item key={item.title} as={Link} to={item.path} eventKey={item.title}>
                        {item.title}
                    </NavDropdown.Item>
                ))}
            </>
        );
    };
    const CustomerServiceLinkOptions = () => {
        return (
            <>
                {GroupAdminMenuOptions.customerService.items.map((item) => (
                    <Nav.Link key={item.title} as={Link} to={item.path} eventKey={item.title} className="d-lg-none">
                        {item.title}
                    </Nav.Link>
                ))}
            </>
        );
    };

    const ManageEnrollmentDropdownOptions = () => {
        return (
            <>
                <NavDropdown.Header>{GroupAdminMenuOptions.manageEnrollment.title}</NavDropdown.Header>
                {GroupAdminMenuOptions.manageEnrollment.items.map((item) => (
                    <NavDropdown.Item key={item.title} as={Link} to={item.path} eventKey={item.title}>
                        {item.title}
                    </NavDropdown.Item>
                ))}
            </>
        );
    };
    const ManageEnrollmentLinkOptions = () => {
        return (
            <>
                {GroupAdminMenuOptions.manageEnrollment.items.map((item) => (
                    <Nav.Link key={item.title} as={Link} to={item.path} eventKey={item.title} className="d-lg-none">
                        {item.title}
                    </Nav.Link>
                ))}
            </>
        );
    };

    return (
        <>
            <div className="keysys-header">
                <Navbar collapseOnSelect expand="lg" style={{ height: "130px" }}>
                    <Container>
                        <Navbar.Brand>
                            <Link to="/">
                                <img
                                    src={logo}
                                    alt="Canopy Logo"
                                    className="my-2"
                                    style={{ maxWidth: "235px", marginLeft: "-7px" }}
                                />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end bg-white">
                            <Nav>
                                {isAuthenticatedUser && (
                                    <>
                                        <Nav.Item as={Nav.Link} className="d-lg-none text-capitalize">
                                            {getName()}
                                        </Nav.Item>
                                        <Dropdown.Divider className="d-lg-none" />
                                        {userType === UserTypes.Member ? (
                                            <>
                                                <Nav.Link as={Link} to="/my-insurance" eventKey="my-insurance">
                                                    My Insurance
                                                </Nav.Link>
                                                <Nav.Link as={Link} to="/my-claims" eventKey="my-claims">
                                                    My Claims
                                                </Nav.Link>
                                            </>
                                        ) : (
                                            userType !== UserTypes.SuperAdmin &&
                                            userType !== UserTypes.GroupAdmin && (
                                                <>
                                                    <Nav.Link
                                                        as={Link}
                                                        to="/coverage-inquiry"
                                                        eventKey="coverage-inquiry"
                                                    >
                                                        Coverage Inquiry
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to="/claim-inquiry" eventKey="claim-inquiry">
                                                        Claim Inquiry
                                                    </Nav.Link>
                                                </>
                                            )
                                        )}
                                        {isGlobalAdmin && (
                                            <>
                                                <Dropdown.Divider className="d-lg-none" />
                                                <Nav.Link as={Link} to="/users" eventKey="users" className="d-lg-none">
                                                    Users
                                                </Nav.Link>
                                                <Nav.Link
                                                    as={Link}
                                                    to="/tenants"
                                                    eventKey="tenants"
                                                    className="d-lg-none"
                                                >
                                                    Tenants
                                                </Nav.Link>
                                                <Dropdown.Divider className="d-lg-none" />
                                            </>
                                        )}
                                        {userType === UserTypes.SuperAdmin && (
                                            <>
                                                <Nav.Link
                                                    as={Link}
                                                    to="/admin-users"
                                                    eventKey="admin-users"
                                                    className="d-lg-none"
                                                >
                                                    Users
                                                </Nav.Link>
                                                <Nav.Link
                                                    as={Link}
                                                    to="/register-user"
                                                    eventKey="register-new-user"
                                                    className="d-lg-none"
                                                >
                                                    Register New User
                                                </Nav.Link>
                                                <Nav.Link
                                                    as={Link}
                                                    eventKey="app-configurations"
                                                    className="d-lg-none"
                                                    to={"/app-configurations"}
                                                >
                                                    App Configurations
                                                </Nav.Link>
                                                <Dropdown.Divider className="d-lg-none" />
                                            </>
                                        )}
                                        {userType === UserTypes.Member && (
                                            <>
                                                <Nav.Link
                                                    href="https://canopyinsurancecorp.com/provider-search/"
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                >
                                                    Find a Provider
                                                </Nav.Link>
                                            </>
                                        )}
                                        {userType === UserTypes.GroupAdmin && (
                                            <>
                                                {myPermissionTypes.access &&
                                                    !myPermissionTypes.enrollment &&
                                                    CustomerServiceLinkOptions()}
                                                {myPermissionTypes.enrollment && (
                                                    <>
                                                        {CustomerServiceLinkOptions()}
                                                        {ManageEnrollmentLinkOptions()}
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <Nav.Link
                                            href="#"
                                            className="d-lg-none"
                                            onClick={() => dispatch(logOut())}
                                            eventKey="logout-mobile"
                                        >
                                            Log Out
                                        </Nav.Link>
                                        <NavDropdown
                                            title={getName()}
                                            className="header-item d-none d-lg-block text-capitalize"
                                        >
                                            {isGlobalAdmin ? (
                                                <>
                                                    <NavDropdown.Item as={Link} to="/users" eventKey="users">
                                                        Users
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to="/tenants" eventKey="tenants">
                                                        Tenants
                                                    </NavDropdown.Item>
                                                    <Dropdown.Divider className="d-lg-none" />
                                                </>
                                            ) : userType === UserTypes.SuperAdmin ? (
                                                <>
                                                    <NavDropdown.Item
                                                        as={Link}
                                                        to="/admin-users"
                                                        eventKey="admin-users"
                                                    >
                                                        Users
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item
                                                        as={Link}
                                                        to="/register-user"
                                                        eventKey="register-new-user"
                                                    >
                                                        Register New User
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item as={Link} to={"/app-configurations"}>
                                                        App Configurations
                                                    </NavDropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            ) : (
                                                userType === UserTypes.GroupAdmin && (
                                                    <>
                                                        {myPermissionTypes.access && !myPermissionTypes.enrollment && (
                                                            <>
                                                                {CustomerServiceDropdownOptions()}
                                                                <Dropdown.Divider />
                                                            </>
                                                        )}
                                                        {myPermissionTypes.enrollment && (
                                                            <>
                                                                {CustomerServiceDropdownOptions()}
                                                                <Dropdown.Divider />
                                                                {ManageEnrollmentDropdownOptions()}
                                                                <Dropdown.Divider />
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            )}
                                            <NavDropdown.Item onClick={() => dispatch(logOut())}>
                                                Log Out
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <PageTitle />
            </div>
        </>
    );
}
