import canopy from "../../images/id-cards/canopy.png";
import visionchoice from "../../images/id-cards/vision-choice-bw.jpg";
import abccoke from "../../images/id-cards/abccoke.png";
import drummond from "../../images/id-cards/drummond.png";
import umwa from "../../images/id-cards/umwa.png";
import alseal from "../../images/id-cards/al-seal.png";
import peehip from "../../images/id-cards/peehip.png";
import twinPines from "../../images/id-cards/twinpines.png";
import { cardHeight, cardWidth } from "./id-card-helpers";

export const canopyLogo = (
    <img
        src={canopy}
        style={{
            maxWidth: cardWidth * 0.5,
            maxHeight: cardHeight * 0.25,
            height: "auto",
            width: "auto",
        }}
        alt="Canopy logo"
        className="mb-3"
    />
);

export const abccokeLogo = (
    <img
        src={abccoke}
        style={{
            maxWidth: cardWidth * 0.35,
            maxHeight: cardHeight * 0.35,
            height: "auto",
            width: "auto",
        }}
        alt="ABC Coke"
        className="mb-3"
    />
);

export const alSealLogo = (
    <img
        src={alseal}
        style={{
            maxWidth: cardWidth * 0.2,
            maxHeight: cardHeight * 0.2,
            height: "auto",
            width: "auto",
        }}
        alt="Alabama Seal"
        className="mb-3"
    />
);

export const drummondLogo = (
    <img
        src={drummond}
        style={{
            maxWidth: cardWidth * 0.4,
            maxHeight: cardHeight * 0.4,
            height: "auto",
            width: "auto",
        }}
        alt="Drummond Company, Inc."
        className="mb-3"
    />
);

export const peehipLogo = (
    <img
        src={peehip}
        style={{
            maxWidth: cardWidth * 0.2,
            maxHeight: cardHeight * 0.2,
            height: "auto",
            width: "auto",
        }}
        alt="PEEHIP"
        className="mb-3"
    />
);

export const twinPinesLogo = (
    <img
        src={twinPines}
        style={{
            maxWidth: cardWidth * 0.5,
            maxHeight: cardHeight * 0.5,
            height: "auto",
            width: "auto",
        }}
        alt="Twin Pines"
        className="mb-3"
    />
);

export const umwaLogo = (
    <img
        src={umwa}
        style={{
            maxWidth: cardWidth * 0.25,
            maxHeight: cardHeight * 0.25,
            height: "auto",
            width: "auto",
        }}
        alt="UMWA"
        className="mb-3"
    />
);

export const visionChoiceLogo = (
    <img
        src={visionchoice}
        style={{
            maxWidth: cardWidth * 0.2,
            maxHeight: cardHeight * 0.2,
            height: "auto",
            width: "auto",
        }}
        alt="VisionChoice"
        className="mb-3"
    />
);
