import React, { useState } from "react";
import { Tabs, Tab, Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Tenant } from "../../models/Tenant";
import { AppState } from "../../state/AppState";
import AppSettingsForm from "./AppSettingsForm";

export default function AppSettings() {
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const tenants = useSelector<AppState, Tenant[]>((s) =>
        s.tenantState.tenants.sort((a, b) => a.name.localeCompare(b.name))
    );
    const rootTenant = tenants.find((t) => t.name === "RootTenant");
    const [activeTenantId, setActiveTenantId] = useState<number>(0);

    if (isGlobalAdmin)
        return (
            <div>
                <Tabs className="mb-3">
                    <Tab eventKey="global" title="Global Settings">
                        <AppSettingsForm tenantId={rootTenant?.id || 1} />
                    </Tab>
                    <Tab eventKey="tenant" title="Partner Overrides">
                        <Row>
                            <Col sm={3}>
                                <Form.Select
                                    value={activeTenantId}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        setActiveTenantId(+e.target.value)
                                    }
                                    className="mb-3"
                                >
                                    <option value={0} disabled>
                                        Select a partner...
                                    </option>
                                    {tenants
                                        .filter((t) => t.name !== "RootTenant" && !t.parentTenantId)
                                        .map((t) => (
                                            <option key={`tenant-${t.id}`} value={t.id}>
                                                {t.name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        {activeTenantId > 0 && <AppSettingsForm tenantId={activeTenantId} />}
                    </Tab>
                </Tabs>
            </div>
        );

    // uncomment to allow partner admins to change their own settings
    // return (
    //     <div>
    //         <AppSettingsForm tenantId={decodedAccessToken.tenant_id} />
    //     </div>
    // );

    return null;
}
