import { Button, Col, Form, Row } from "react-bootstrap";
import LightSection from "../../page-sections/LightSection";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUsers } from "../../../contexts/users";
import { GroupAccount } from "../../../models/GroupAccount";
import DarkSection from "../../page-sections/DarkSection";
import DataTable from "../../grid/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { UserPermission, AccessPermissionTableData } from "../../../models/UserPermission";
import { Permission } from "../../../models/Permission";
import { useHistory } from "react-router-dom";
import { LoadingSpinner } from "../../loading/Loading";

export default function AccessPermission() {
    const history = useHistory();
    const [allGroupAccount, setAllGroupAccount] = useState<GroupAccount[]>([]);
    const [loadingGroupAccounts, setLoadingGroupAccounts] = useState<boolean>(false);
    const [userPermission, setUserPermission] = useState<UserPermission[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string>("All");
    const [selectedAccount, setSelectedAccount] = useState<string>("All");
    const params = useParams<{ userId: string }>();
    const { userState, getGroupAccounts, getUserPermissions, addUserPermission, deleteUserPermission } = useUsers();

    const getAllGroupAccounts = useCallback(async () => {
        setLoadingGroupAccounts(true);
        const groupAccounts = await getGroupAccounts();
        if (groupAccounts) {
            setAllGroupAccount(groupAccounts);
        }
        setLoadingGroupAccounts(false);
    }, [getGroupAccounts]);

    const getPermissions = useCallback(async () => {
        const userPermissions = await getUserPermissions(+params.userId);
        if (userPermissions) {
            setUserPermission(userPermissions);
        }
    }, [getUserPermissions, params.userId]);

    const groupAdminUser = useMemo(() => {
        return userState.users.find((u) => u.id === +params.userId);
    }, [params.userId, userState.users]);

    const groupOptionValues = useMemo(() => {
        let distinctGroupNumbers = [...new Set(allGroupAccount!.map((ga) => ga.groupNumber))];
        return ["All", ...distinctGroupNumbers];
    }, [allGroupAccount]);

    const accountOptionValues = useMemo(() => {
        let grpAccountsForSelectedGrp =
            selectedGroup === "All"
                ? allGroupAccount
                : allGroupAccount.filter((ga) => ga.groupNumber === selectedGroup);
        const distinctActsForSelectedGrp = [...new Set(grpAccountsForSelectedGrp.map((ga) => ga.prelistAccountName))];
        return ["All", ...distinctActsForSelectedGrp];
    }, [allGroupAccount, selectedGroup]);

    const data: AccessPermissionTableData[] = useMemo(() => {
        const filteredByGroup =
            selectedGroup === "All"
                ? allGroupAccount
                : allGroupAccount.filter((ga) => ga.groupNumber === selectedGroup);
        const filteredByGroupAccount =
            selectedAccount === "All"
                ? filteredByGroup
                : filteredByGroup.filter((fga) => fga.prelistAccountName === selectedAccount);

        return filteredByGroupAccount.map((ga) => {
            const matchingUserPermissions = userPermission.filter(
                (up) =>
                    ga.groupNumber === up.groupNumber &&
                    ga.accountNumber.toString() === up.accountNumber &&
                    up.userId === +params.userId
            );

            const accessPermissionData: AccessPermissionTableData = {
                userId: +params.userId,
                groupNumber: ga.groupNumber,
                accountNumber: ga.accountNumber.toString(),
                accountName: ga.prelistAccountName,
                permissionId: 0,
                access: false,
                enrollment: false,
            };

            matchingUserPermissions.forEach((matchingUserPermission) => {
                // Update access and enrollment
                if (matchingUserPermission.permissionId === Permission.Access) {
                    accessPermissionData.access = true;
                    accessPermissionData.permissionId = Permission.Access;
                }
                if (matchingUserPermission.permissionId === Permission.Enrollment) {
                    accessPermissionData.enrollment = true;
                    accessPermissionData.permissionId = Permission.Enrollment;
                }
            });
            return accessPermissionData;
        });
    }, [allGroupAccount, params.userId, selectedAccount, selectedGroup, userPermission]);

    useEffect(() => {
        if (!!params.userId) {
            getAllGroupAccounts();
            getPermissions();
        }
    }, [getAllGroupAccounts, getGroupAccounts, getPermissions, params.userId, userState.users]);

    const handlePermissionChange = useCallback(
        async (isAccessCheckbox: boolean, isChecked: boolean, row: any) => {
            const permissionId = isAccessCheckbox ? Permission.Access : Permission.Enrollment;
            if (isChecked) {
                const modifiedUserPermission: UserPermission = {
                    userId: +params.userId,
                    permissionId: permissionId,
                    accountNumber: row.accountNumber,
                    groupNumber: row.groupNumber,
                    accountName: row.accountName,
                };
                const addedPermission = await addUserPermission(modifiedUserPermission);
                if (addedPermission) {
                    getPermissions();
                }
            } else {
                const permissionToDelete = userPermission.find(
                    (up) =>
                        up.userId === +params.userId &&
                        up.groupNumber === row.groupNumber &&
                        up.accountNumber === row.accountNumber &&
                        up.permissionId === permissionId
                );
                if (permissionToDelete) {
                    if (await deleteUserPermission(permissionToDelete?.id!)) {
                        getPermissions();
                    }
                }
            }
        },
        [addUserPermission, deleteUserPermission, getPermissions, params.userId, userPermission]
    );

    const AccessCheckboxColumn = useCallback(
        ({ cell, row }: any) => {
            const cellValue = cell.getValue();
            return (
                <input
                    type="checkbox"
                    checked={cellValue}
                    onChange={() => handlePermissionChange(true, !cellValue, row.original)}
                />
            );
        },
        [handlePermissionChange]
    );

    const EnrollmentCheckboxColumn = useCallback(
        ({ cell, row }: any) => {
            const cellValue = cell.getValue();
            return (
                <input
                    type="checkbox"
                    checked={cellValue}
                    onChange={() => handlePermissionChange(false, !cellValue, row.original)}
                />
            );
        },
        [handlePermissionChange]
    );

    const columns = useMemo(
        (): ColumnDef<GroupAccount>[] => [
            {
                header: "Group Number",
                accessorKey: "groupNumber",
            },
            {
                header: "Account Number",
                accessorKey: "accountNumber",
            },
            {
                header: "Account Name",
                accessorKey: "accountName",
            },
            {
                header: "Access",
                accessorKey: "access",
                cell: AccessCheckboxColumn,
            },
            {
                header: "Enrollment",
                accessorKey: "enrollment",
                cell: EnrollmentCheckboxColumn,
            },
        ],
        [AccessCheckboxColumn, EnrollmentCheckboxColumn]
    );

    return (
        <>
            <LightSection>
                <Form>
                    <Col xl="6" className="mx-auto">
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control value={groupAdminUser?.firstName} disabled={true} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control value={groupAdminUser?.lastName} disabled={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>User Id</Form.Label>
                                    <Form.Control value={groupAdminUser?.userName} disabled={true} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control value={groupAdminUser?.emailAddress} disabled={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Group</Form.Label>
                                    <Form.Select
                                        value={selectedGroup}
                                        onChange={(e) => setSelectedGroup(e.target.value)}
                                    >
                                        {groupOptionValues.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Account</Form.Label>
                                    <Form.Select
                                        value={selectedAccount}
                                        onChange={(e) => setSelectedAccount(e.target.value)}
                                    >
                                        {accountOptionValues.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={"mb-4 justify-content-end"}>
                            <Col xs={"auto"}>
                                <Button variant="secondary" onClick={() => history.push("/admin-users")}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            </LightSection>
            <DarkSection>
                <Col xl="8" className="mx-auto">
                    {loadingGroupAccounts && <LoadingSpinner />}
                    {data.length > 0 && (
                        <DataTable data={data} columns={columns} paginated={data.length > 20} showGlobalFilter />
                    )}
                </Col>
            </DarkSection>
        </>
    );
}
