import { TenantState } from "../../state/TenantState";
import {
    TenantActions,
    TENANTS_FETCHED,
    CREATE_TENANT,
    EDIT_TENANT,
    DELETE_TENANT,
    PARENT_TENANT_FETCHED,
    CUSTOMER_MENU_ITEMS_FETCHED,
    CREATE_CUSTOMER_MENU_ITEM,
    EDIT_CUSTOMER_MENU_ITEM,
    DELETE_CUSTOMER_MENU_ITEM,
} from "../../actions/tenants/TenantActions";
import { replaceInCollection } from "./../ReplaceInCollection";

const initialState: TenantState = {
    tenants: [],
    customerMenuItems: [],
};

export function tenantReducer(state: TenantState = initialState, action: TenantActions): TenantState {
    switch (action.type) {
        case TENANTS_FETCHED: {
            return {
                ...state,
                tenants: action.tenants,
            };
        }
        case CUSTOMER_MENU_ITEMS_FETCHED: {
            return {
                ...state,
                customerMenuItems: [
                    ...state.customerMenuItems.filter(
                        (item) => !action.customerMenuItems.map((actionItem) => actionItem.id).includes(item.id)
                    ),
                    ...action.customerMenuItems,
                ],
            };
        }
        case CREATE_CUSTOMER_MENU_ITEM: {
            return {
                ...state,
                customerMenuItems: [...state.customerMenuItems, action.customerMenuItem],
            };
        }
        case EDIT_CUSTOMER_MENU_ITEM: {
            return {
                ...state,
                customerMenuItems: replaceInCollection(
                    state.customerMenuItems,
                    action.customerMenuItem,
                    action.newCustomerMenuItem,
                    (a, b) => a.id.toLocaleString().localeCompare(b.id.toLocaleString())
                ),
            };
        }
        case DELETE_CUSTOMER_MENU_ITEM:
            const originalActiveCustomerMenuItem = state.customerMenuItems.find(
                (item) => item.id === action.customerMenuItem.id
            );
            console.log(originalActiveCustomerMenuItem);
            return {
                ...state,
                customerMenuItems: replaceInCollection(
                    state.customerMenuItems,
                    originalActiveCustomerMenuItem!,
                    action.customerMenuItem
                ),
            };
        case PARENT_TENANT_FETCHED: {
            return {
                ...state,
                parentTenant: action.parentTenant,
            };
        }
        case CREATE_TENANT:
            return {
                ...state,
                tenants: [...state.tenants, action.tenant],
            };
        case DELETE_TENANT:
            const originalActiveTenant = state.tenants.find((t) => t.id === action.tenant.id);

            return {
                ...state,
                tenants: replaceInCollection(state.tenants, originalActiveTenant!, action.tenant),
            };
        case EDIT_TENANT:
            return {
                ...state,
                tenants: replaceInCollection(state.tenants, action.originalTenant, action.newTenant, (a, b) =>
                    a.name.localeCompare(b.name)
                ),
            };
        default:
            return state;
    }
}

// function removeOldTenantAndReplaceWithNew(tenants: Tenant[], originalTenant: Tenant, newTenant: Tenant): Tenant[] {
//     return replaceInCollection(tenants, originalTenant, newTenant, (a, b) => a.name.localeCompare(b.name));
// }
