import { AwsFile } from "../../models/AwsFile";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const CLEAR_IMAGE = "CLEAR_IMAGE";

export interface UploadImageAction {
    type: typeof UPLOAD_IMAGE;
    awsFile: AwsFile;
}

export interface ClearImageAction {
    type: typeof CLEAR_IMAGE;
}

export type UploadImageActions = UploadImageAction | ClearImageAction;
