import { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import { useBillingItems, useBillingStatement } from "../../hooks/billing";
import { formatBillingPeriod, getBillingPeriods, getBillingPeriod } from "../../helper-functions/billingPeriod";
import { BillingItem } from "../../models/Billing";
import DataTable from "../grid/DataTable";
import { LoadingSpinner } from "../loading/Loading";
import LightSection from "../page-sections/LightSection";
import { useMyGroupAccountOptions } from "../../hooks/groupAdmin";

export const Billing = () => {
    const {
        selectedAccount,
        setSelectedAccount,
        selectedGroup,
        setSelectedGroup,
        groupOptions,
        accountOptions,
    } = useMyGroupAccountOptions();

    const [billingPeriod, setBillingPeriod] = useState(getBillingPeriod());
    const [billingItem, setBillingItem] = useState<BillingItem | undefined>();

    const [objUrl, setObjUrl] = useState("");
    const [showModal, setShowModal] = useState(false);

    const { billingItems, loading: itemsLoading } = useBillingItems(billingPeriod);
    const {
        downloadBillingStatement,
        getBillingStatement,
        loading: statementLoading,
        downloading,
    } = useBillingStatement();

    const billingPeriods = useMemo(() => {
        return getBillingPeriods();
    }, [getBillingPeriods]);

    const tableData = useMemo(() => {
        return billingItems
            .filter((bi) => selectedGroup === "All" || bi.groupNumber === selectedGroup)
            .filter(
                (bi) =>
                    selectedAccount === "All" || bi.accountNumber.toString() === selectedAccount.split("-")[0].trim()
            );
    }, [billingItems, selectedGroup, selectedAccount]);

    const handleViewStatement = async (billingItem: BillingItem) => {
        setBillingItem(billingItem);
        const data = await getBillingStatement(billingItem);
        if (data) {
            setObjUrl(window.URL.createObjectURL(new Blob([data], { type: "application/pdf" })));
        }
        setShowModal(true);
    };

    const handleDownloadStatement = (billingItem: BillingItem) => {
        setBillingItem(billingItem);
        downloadBillingStatement(billingItem, billingPeriod);
    };

    const columns: ColumnDef<BillingItem>[] = [
        {
            header: "Invoice Number",
            accessorKey: "invoiceNumber",
        },
        {
            header: "Group",
            accessorKey: "groupNumber",
        },
        {
            header: "Account",
            accessorKey: "accountNumber",
        },
        {
            header: "Name",
            accessorKey: "statementName",
        },
        {
            header: "Invoice Date",
            accessorKey: "invoiceDate",
        },
        {
            header: "Dist",
            cell: ({ row: { original } }) => {
                const { pdf, print, excel, fax } = original;
                return (
                    <div className="d-flex justify-content-evenly">
                        {pdf && <i className="fas fa-file-pdf text-danger" />}
                        {print && <i className="fas fa-print text-primary" />}
                        {excel && <i className="fas fa-file-excel text-success" />}
                        {fax && <i className="fas fa-fax" />}
                    </div>
                );
            },
        },
        {
            header: "Premium",
            accessorKey: "invoiceAmount",
        },
        {
            header: "Printed Date",
            accessorKey: "finalizeDate",
        },
        {
            header: "",
            id: "actions",
            cell: ({ row: { original } }) => {
                const isBillingItem = billingItem?.id === original.id;
                const isViewing = isBillingItem && statementLoading;
                const isDownloading = isBillingItem && downloading;
                return (
                    <div className="d-flex justify-content-evenly">
                        <Button
                            size="sm"
                            title="Open in new tab"
                            onClick={() => handleViewStatement(original)}
                            disabled={isViewing}
                        >
                            {isViewing ? <LoadingSpinner size="sm" /> : <i className="far fa-eye" />}
                        </Button>
                        <Button
                            size="sm"
                            title="Download"
                            onClick={() => handleDownloadStatement(original)}
                            disabled={isDownloading}
                        >
                            {downloading && billingItem?.id === original.id ? (
                                <LoadingSpinner size="sm" />
                            ) : (
                                <i className="fas fa-file-download" />
                            )}
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <LightSection>
            <Row>
                <Col xl={3} md={12} className="mb-3">
                    <Form.Group>
                        <Form.Label>Billing Period</Form.Label>
                        <div className="d-flex justify-content-around align-items-center">
                            <Form.Select
                                className="w-75"
                                value={billingPeriod}
                                onChange={(e) => setBillingPeriod(Number(e.target.value))}
                            >
                                {billingPeriods.map((period) => (
                                    <option key={period} value={period}>
                                        {formatBillingPeriod(period)}
                                    </option>
                                ))}
                            </Form.Select>
                            <div className="w-25">{itemsLoading && <LoadingSpinner size="sm" />}</div>
                        </div>
                    </Form.Group>
                </Col>
                <Col xl={3} md={12} className="mb-3">
                    <Form.Group>
                        <Form.Label>Group</Form.Label>
                        <Form.Select
                            value={selectedGroup}
                            onChange={(e) => {
                                setSelectedGroup(e.target.value);
                                setSelectedAccount("All");
                            }}
                        >
                            {groupOptions.map((group) => (
                                <option key={group} value={group}>
                                    {group}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xl={3} md={12} className="mb-3">
                    <Form.Group>
                        <Form.Label>Account</Form.Label>
                        <Form.Select value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
                            {accountOptions.map((account) => (
                                <option key={account} value={account}>
                                    {account}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable showGlobalFilter columns={columns} data={tableData} />
                </Col>
            </Row>
            <Modal centered backdrop="static" size="xl" show={showModal}>
                <Modal.Body>
                    <iframe
                        id={billingItem?.statementName}
                        src={objUrl}
                        width="100%"
                        height={window.innerHeight * 0.75 + "px"}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setShowModal(false);
                            window.URL.revokeObjectURL(objUrl);
                            setObjUrl("");
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </LightSection>
    );
};
