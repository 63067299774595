import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMembers } from "../../contexts/members";
import { useUsers } from "../../contexts/users";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { UserTypes } from "../../models/UserTypes";
import { AppState } from "../../state/AppState";

export const PretreatmentEstimate = () => {
    const { hasCoverage, isMember, pretreatmentMessage } = useMembers();
    const { userType } = useUsers();

    const { tenant_id } = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const { currentTenant } = useCurrentTenant(tenant_id);

    if (
        (isMember && hasCoverage("dental")) ||
        (!isMember && userType === UserTypes.Provider && currentTenant?.providerType == "D")
    ) {
        return (
            <>
                <Row className="my-2">
                    <Col>
                        <h5 className="pretreatment-msg form-label">{pretreatmentMessage}</h5>
                    </Col>
                </Row>
            </>
        );
    }
    return null;
};
