import { useHistory } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { SsoService } from "../../models/SsoService";
import { useDispatch } from "react-redux";
import { LOGGED_IN, LoggedInAction } from "../../actions/AuthenticateActions";
import jwt_decode from "jwt-decode";

export default function SignInOidc() {
    const ssoService: SsoService = useMemo(() => new SsoService(), []);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const signInAsync = async () => {
            const user = await ssoService.signInRedirectCallback();
            const decodedAccessToken = jwt_decode(user.access_token) as any;
            dispatch<LoggedInAction>({
                accessToken: user.access_token,
                refreshToken: user.refresh_token ?? "",
                type: LOGGED_IN,
                decodedAccessToken: {
                    ...decodedAccessToken,
                    tenant_id: +decodedAccessToken.tenant_id,
                    userId: parseInt((decodedAccessToken.user_id as any) as string),
                    reports: decodedAccessToken.reports as string,
                },
            });
            history.push("/");
        };
        signInAsync();
    }, [history, dispatch, ssoService]);

    return <></>;
}
