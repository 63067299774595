import { createContext } from "react";
import { PlanAllotment } from "../../models/PlanAllotment";
import { AccumulatorNumbers } from "../../models/Accumulator";

export type IPlanAllotmentContext = {
    planAllotments: PlanAllotment[];
    findAllotmentByAccumulator: (accumulatorNumber: AccumulatorNumbers) => PlanAllotment | undefined;
};

const defaultValue: IPlanAllotmentContext = {
    planAllotments: [],
    findAllotmentByAccumulator: (accumulatorNumber) => undefined,
};

export const PlanAllotmentContext = createContext<IPlanAllotmentContext>(defaultValue);
