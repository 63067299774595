/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getParentTenant, getTenants } from "../actions/tenants/TenantActionCreators";
import { AppState } from "../state/AppState";

export const useCurrentTenant = (tenantId: number) => {
    const dispatch = useDispatch();
    const currentTenant = useSelector((state: AppState) => state.tenantState.tenants).find((t) => t.id === tenantId);
    const parentTenant = useSelector((state: AppState) => state.tenantState.parentTenant);

    useEffect(() => {
        if (!currentTenant) {
            dispatch(getTenants());
        }
        if (currentTenant?.parentTenantId && !parentTenant) {
            dispatch(getParentTenant(currentTenant.parentTenantId));
        }
    }, [currentTenant]);

    const currentIsPartnerTenant = !currentTenant?.parentTenantId;

    return {
        currentTenant,
        parentTenant,
        loading: currentIsPartnerTenant ? !currentTenant : !parentTenant && !currentTenant,
    };
};
