/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import _ from "lodash";
import {
    Field,
    FieldArray,
    Form,
    Formik,
    FormikContextType,
    FormikHelpers,
    FormikProps,
    useFormikContext,
} from "formik";
import DynamicEditableTable from "./DynamicEditableTable";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import KeysysInput from "../form/input/KeysysInput";
import { IconPicker } from "react-fa-icon-picker";
import "./formBuilder.scss";

export interface FormControlOption {
    value: any;
    text: string;
    key: any;
}

export interface ApiViewFields {
    key: string;
    label: string;
    type:
        | "input"
        | "textArea"
        | "datePicker"
        | "radio"
        | "checkbox"
        | "dropdown"
        | "file"
        | "array"
        | "table"
        | "iconSelector";
    isDynamic?: boolean;
    hidden?: boolean;
    fields?: ApiViewFields[];
    controlStyle?: React.CSSProperties;
    readonly?: boolean;
    options?: FormControlOption[];
    value?: string;
    group?: string;
    placeholder?: string;
    canAddValuesToOptions?: boolean;
    onAddValueToOptions?: (value: string) => void;
    onChange?: (value: any, formContext?: FormikContextType<any>) => void;
    dynamicData?: boolean;
    arrayChunk?: number;
}

export interface FormBuilderProps<T> {
    meta: ApiViewFields[];
    initialValues?: T;
    chunkNumber?: 1 | 2 | 3 | 4;
    formLayout?: "list" | "normal";
    children?: any;
}

export default function FormBuilder(props: FormBuilderProps<any>) {
    const { meta, children, chunkNumber, formLayout } = props;

    const formCtx = useFormikContext<any>();

    const onChangeField = (control: ApiViewFields, value: any) => {
        console.log(value);
        if (control.onChange) {
            control.onChange(value, formCtx);
        }
        formCtx.getFieldHelpers(control.key).setValue(value);
    };

    const getControlGroupObject = (control: ApiViewFields) => {
        const object: any = {};
        control.fields?.forEach((c) => {
            object[c.key] = "";
        });
        return object;
    };

    const getTableArrayElements = (control: ApiViewFields) => {
        return <DynamicEditableTable getControl={getControl} control={control} values={formCtx.values[control.key]} />;
    };

    const getArrayElements = (control: ApiViewFields) => {
        return (
            <div className="elements-array">
                <FieldArray
                    name={control.key}
                    render={(arrayHelpers) =>
                        formCtx.values[control.key].length === 0 ? (
                            <div style={{ display: "flex" }} className="justify-content-md-center">
                                <Button
                                    className="finosec-button-info"
                                    onClick={() => arrayHelpers.push(getControlGroupObject(control))}
                                >
                                    Add {control.label}
                                </Button>
                            </div>
                        ) : (
                            _.chunk(formCtx.values[control.key], control.arrayChunk || chunkNumber || 1).map(
                                (child: any, i: number) => (
                                    <Row key={`array-row${i}`} style={{ marginBottom: "10px" }}>
                                        {child.map((_v: any, index: any) => (
                                            <Col
                                                lg={getLg(control.arrayChunk)}
                                                xs={12}
                                                key={`array-column-${index}-${_v.key}`}
                                                className="grouped-elements"
                                            >
                                                {getGroupingElements(
                                                    control,
                                                    `${control.key}[${
                                                        index + i * (control.arrayChunk || chunkNumber || 0)
                                                    }]`
                                                )}{" "}
                                                <div className="field-wrapper remove-button">
                                                    <Button
                                                        className="finosec-button-info"
                                                        onClick={() =>
                                                            arrayHelpers.remove(
                                                                index + i * (control.arrayChunk || chunkNumber || 0)
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-minus"></i>
                                                    </Button>
                                                </div>
                                                <div className="field-wrapper add-button">
                                                    <Button
                                                        className="finosec-button-info"
                                                        onClick={() =>
                                                            arrayHelpers.push(getControlGroupObject(control))
                                                        }
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                )
                            )
                        )
                    }
                ></FieldArray>
            </div>
        );
    };

    const getGroupingElements = (control: ApiViewFields, leadingKey?: string) => {
        return control.fields?.map(
            (c, index) =>
                !c.hidden && (
                    <div
                        key={`group-${c.key}-${index}-${leadingKey}`}
                        className="field-wrapper"
                        style={c.controlStyle || {}}
                    >
                        {getControl({
                            ...c,
                            key: leadingKey ? `${leadingKey}.${c.key}` : c.key,
                        })}
                    </div>
                )
        );
    };

    function getInputElement(control: ApiViewFields, isPercent = false) {
        return (
            <>
                {formLayout !== "list" && control.label && <label>{control.label}</label>}
                {formLayout === "list" && control.label && <span>{control.label}</span>}
                <Field
                    id={control.key}
                    name={control.key}
                    readOnly={control.readonly}
                    className="form-control"
                    type="text"
                    as="input"
                    placeholder={control.placeholder}
                />
            </>
        );
    }

    function getRadioElement(control: ApiViewFields) {
        return (
            <>
                <label htmlFor={control.key}>{control.label}</label>
                <br />
                {control.options!.map((option, index) => (
                    <React.Fragment key={option.key}>
                        <label>
                            <Field type="radio" id={option.key} name={control.key} value={option.value} />
                            {option.text}
                        </label>
                        <br />
                    </React.Fragment>
                ))}
            </>
        );
    }

    function getCheckboxElement(control: ApiViewFields) {
        return (
            <>
                <label>
                    <Field id={control.key} type="checkbox" name={control.key} value={control.value} />
                    {control.label}
                </label>
            </>
        );
    }

    const getIconSelector = (control: ApiViewFields) => {
        // onChange={(e: any) => onChangeField(control, e.target.value)}
        let value: any = formCtx.getFieldMeta(control.key).value;
        return (
            <div style={{ marginRight: "5px" }} className="icon-selector">
                <label>{control.label}</label>
                <Field
                    className="form-control"
                    id={control.key}
                    component={() => (
                        <IconPicker value={value} hideSearch={false} onChange={(e: any) => onChangeField(control, e)} />
                    )}
                />
            </div>
        );
    };

    const getAddValuesToOptionsModal = (control: ApiViewFields, showModal: boolean, hideModal: () => void) => {
        const onSubmit = (
            value: string,
            formHelper: FormikHelpers<{
                name: string;
            }>
        ) => {
            control.onAddValueToOptions && control.onAddValueToOptions(value);
            formHelper.resetForm();
            hideModal();
        };

        const onClose = (formHelpers: FormikProps<any>) => {
            formHelpers.resetForm();
            hideModal();
        };
        return (
            <Formik initialValues={{ name: "" }} onSubmit={(values, formHelper) => onSubmit(values.name, formHelper)}>
                {(formProps: FormikProps<any>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addValueToOptions"
                            show={showModal}
                            title={`Add a ${control.key}`}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={true}
                            onCloseButtonClick={() => onClose(formProps)}
                            onOkButtonClick={formProps.submitForm}
                            dynamicHeight={true}
                        >
                            <Form id="addValueToOptionsForm">
                                <Card>
                                    <Card.Body>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"option-name"}
                                                    placeholder={`Enter new ${control.key}`}
                                                    fieldName={"name"}
                                                    label={`${control.key}`}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        );
    };

    function GetDropDownElement(props: { control: ApiViewFields }) {
        const { control } = props;
        const [showModal, setShowModal] = useState(false);
        return (
            <>
                {control.label && <label>{control.label}</label>}
                <div className="grouped-elements">
                    <Field
                        onChange={(e: any) => onChangeField(control, e.target.value)}
                        className="form-control"
                        as="select"
                        name={control.key}
                        disabled={control.readonly}
                    >
                        <option value={""}>Select an Option</option>
                        {control.options?.map((option, index) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </Field>
                    {control.canAddValuesToOptions && (
                        <>
                            <Button className="finosec-button-info" onClick={() => setShowModal(true)}>
                                <i className="fa fa-plus"></i>
                            </Button>
                            {getAddValuesToOptionsModal(control, showModal, () => setShowModal(false))}
                        </>
                    )}
                </div>
            </>
        );
    }

    function getControl(control: ApiViewFields) {
        switch (control.type) {
            case "input":
                return getInputElement(control);
            case "radio":
                return getRadioElement(control);
            case "checkbox":
                return getCheckboxElement(control);
            case "dropdown":
                return <GetDropDownElement control={control} />;
            case "array":
                return getArrayElements(control);
            case "iconSelector":
                return getIconSelector(control);
            case "table":
                return getTableArrayElements(control);
        }
    }

    const getLg = (chunk?: number) => {
        switch (chunk || chunkNumber) {
            case 1:
                return 12;
            case 2:
                return 6;
            case 3:
                return 4;
            case 4:
                return 3;
            default:
                return 9;
        }
    };

    const colProps = {
        lg: getLg(),
        xs: 12,
    };
    const groupedMeta = _.groupBy(
        meta.filter((m) => !m.hidden),
        (m) => m.group
    );
    const hiddenMeta = meta.filter((m) => m.hidden);
    return (
        <>
            {hiddenMeta.map((hm) => (
                <div key={hm.key} style={{ display: "none" }}>
                    {getControl(hm)}
                </div>
            ))}
            {Object.keys(groupedMeta).map((key) => (
                <div key={key}>
                    {key && key !== "undefined" && (
                        <fieldset>
                            <legend>{key}</legend>
                        </fieldset>
                    )}
                    {_.chunk(groupedMeta[key], chunkNumber || 1).map((child, index) => (
                        <Row
                            key={`${key}-${index}`}
                            className={`mb-4 ${chunkNumber === 1 || !chunkNumber ? "justify-content-md-center" : ""}`}
                        >
                            {child.map((control, i) => (
                                <Col
                                    key={`${key}-${index}-${i}`}
                                    lg={control.type === "array" || control.type === "table" ? 12 : colProps.lg}
                                    xs={colProps.xs}
                                    style={{}}
                                >
                                    {getControl(control)}
                                </Col>
                            ))}
                        </Row>
                    ))}
                </div>
            ))}
            <Row className={"mb-4 justify-content-md-center"}>{children}</Row>
        </>
    );
}
