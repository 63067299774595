export const getFormattedDateFromDbValue = (value: string): string => {
    if (value.length !== 8) return value;
    if (value === "00000000") return "";

    try {
        const year = parseInt(value.slice(0, 4));
        const month = parseInt(value.slice(4, 6)) - 1;
        const day = parseInt(value.slice(6));
        return new Date(year, month, day).toLocaleDateString();
    } catch {
        return value;
    }
};

export const getDateFromDbValue = (value: string): Date | undefined => {
    try {
        if (value === "00000000") return undefined;

        const year = parseInt(value.slice(0, 4));
        const month = parseInt(value.slice(4, 6)) - 1;
        const day = parseInt(value.slice(6));
        return new Date(year, month, day);
    } catch {
        return undefined;
    }
};
