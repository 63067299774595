import { SSN_MAX_LENGTH } from "../constants/ssn";

/**
 * @returns The value formatted as a SSN: 999-99-9999
 */
export const formatSSN = (v?: string) => {
    if (!v) {
        return "";
    }
    if (typeof v !== "string") {
        v = String(v);
    }
    v = v.slice(0, SSN_MAX_LENGTH).replace(/-/g, "");
    if (v.length <= 3) {
        return v;
    }
    if (v.length > 3 && v.length <= 5) {
        return `${v.slice(0, 3)}-${v.slice(3)}`;
    }
    return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
};

/**
 * @returns The value as formatted and masked SSN: ***-**-9999
 */
export const maskSSN = (v?: string) => {
    if (!v) {
        return "";
    }
    v = formatSSN(v);
    const masked = v.slice(0, 7).replace(/[0-9]/g, "*");
    return masked + v.slice(7);
};
