import * as React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { KeysysDatetimeProps } from "./KeysysDatetimeProps";
import ErrorMessageText from "../error-message/ErrorMessageText";
import DatePicker from "react-datepicker";
// import "./KeysysDatetime.css";

export default function KeysysDatetime(props: KeysysDatetimeProps) {
    const getSafeDateValue = (input: any) => {
        if (!input) {
            return undefined;
        }

        const inputAsDate = new Date(input);
        return isNaN(inputAsDate as any) ? undefined : inputAsDate;
    };

    function setFieldValue(change: Date | null) {
        props.formProps.setFieldValue(props.fieldName, getSafeDateValue(change));
    }

    return (
        <div>
            <Field name={props.fieldName}>
                {({ field }: FieldProps<any>) => (
                    <div>
                        <label htmlFor={props.id} className="form-label">
                            {props.label}
                            {props.required && <span className="text-danger"> *</span>}
                        </label>
                        <div>
                            <DatePicker
                                className={
                                    props.formProps.errors[props.fieldName] && props.formProps.touched[props.fieldName]
                                        ? "form-control invalid"
                                        : "form-control"
                                }
                                onChange={setFieldValue}
                                selected={getSafeDateValue(field.value)}
                                disabled={!!props.disabled ? props.disabled : false}
                                placeholderText={props.placeholder}
                                showYearDropdown
                                showTimeSelect={!!props.showTimePicker ? props.showTimePicker : false}
                                dateFormat={!!props.showTimePicker ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy"}
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                isClearable={props.clearable}
                            />
                        </div>
                    </div>
                )}
            </Field>
            <ErrorMessage name={props.fieldName}>{(msg) => <ErrorMessageText message={msg} />}</ErrorMessage>
        </div>
    );
}
