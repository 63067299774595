import { useState, useEffect } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMembers } from "../../contexts/members";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { searchMembersByIdRequest, searchMembersByNameRequest } from "../../models/MemberSearch";
import { AppState } from "../../state/AppState";
import { LoadingSpinner } from "../loading/Loading";
import LightSection from "../page-sections/LightSection";
import DarkSection from "../page-sections/DarkSection";
import MemberDetails from "./MemberDetails";

export default function CoverageInquiry() {
    const { searchByName, searchById } = useMembers();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant } = useCurrentTenant(decodedAccessToken.tenant_id);

    const [searchMethod, setSearchMethod] = useState<string>("name");
    const [lastNameInput, setLastNameInput] = useState<string>("");
    const [firstNameInput, setFirstNameInput] = useState<string>("");
    const [dobInput, setDobInput] = useState<string>("");
    const [groupInput, setGroupInput] = useState<string>("");
    const [memberIdInput, setMemberIdInput] = useState<string>("");
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);

    useEffect(() => {
        setHasLoaded(false);
        setHasSubmitted(false);
    }, [searchMethod]);

    const handleSearchByName = async () => {
        if (!dobInput || !lastNameInput) {
            return;
        }
        const request: searchMembersByNameRequest = {
            firstname: firstNameInput,
            lastname: lastNameInput,
            birthDate: dobInput,
            providerType: currentTenant?.providerType,
        };
        await searchByName(request);
        setHasLoaded(true);
    };

    const handleSearchById = async () => {
        if (!groupInput || !memberIdInput) {
            return;
        }
        const request: searchMembersByIdRequest = {
            groupNumber: groupInput,
            participantIdNumber: memberIdInput,
            providerType: currentTenant?.providerType,
        };
        await searchById(request);
        setHasLoaded(true);
    };

    const handleRetrieve = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setHasLoaded(false);
        setHasSubmitted(true);
        if (searchMethod === "name") {
            await handleSearchByName();
        } else if (searchMethod === "id") {
            await handleSearchById();
        }
        setIsLoading(false);
    };

    return (
        <>
            <LightSection>
                <Form>
                    <Row>
                        <Col xl={3} md={12} className="mb-3">
                            <Form.Group controlId="method">
                                <Form.Label>Search for coverage by</Form.Label>
                                <Form.Select value={searchMethod} onChange={(e) => setSearchMethod(e.target.value)}>
                                    <option value="name">Primary Insured</option>
                                    <option value="id">Member ID</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {searchMethod === "name" && (
                            <>
                                <Col xl={3} md={4} className="mb-3">
                                    <Form.Group controlId="last">
                                        <Form.Label>
                                            Last Name <span style={{ color: "red" }}>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            value={lastNameInput}
                                            onChange={(e) => setLastNameInput(e.target.value)}
                                            isInvalid={hasSubmitted && !lastNameInput}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Last name is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xl={3} md={4} className="mb-3">
                                    <Form.Group controlId="first">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            value={firstNameInput}
                                            onChange={(e) => setFirstNameInput(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xl={3} md={4} className="mb-3">
                                    <Form.Group controlId="dob">
                                        <Form.Label>
                                            Date of Birth <span style={{ color: "red" }}>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={dobInput}
                                            onChange={(e) => setDobInput(e.target.value)}
                                            isInvalid={hasSubmitted && !dobInput}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Date of birth is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                        {searchMethod === "id" && (
                            <>
                                <Col xl={3} md={4} className="mb-3">
                                    <Form.Group controlId="group">
                                        <Form.Label>
                                            Group Number <span style={{ color: "red" }}>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            value={groupInput}
                                            onChange={(e) => setGroupInput(e.target.value)}
                                            isInvalid={hasSubmitted && !groupInput}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Group number is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xl={3} md={4} className="mb-3">
                                    <Form.Group controlId="memberId">
                                        <Form.Label>
                                            Member ID <span style={{ color: "red" }}>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            value={memberIdInput}
                                            onChange={(e) => setMemberIdInput(e.target.value)}
                                            isInvalid={hasSubmitted && !memberIdInput}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Member ID is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row>
                        <Col className="text-end mt-3">
                            <Button variant="primary" type="submit" onClick={handleRetrieve}>
                                Retrieve
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </LightSection>
            <DarkSection>
                {isLoading && <LoadingSpinner />}
                {!isLoading && hasSubmitted && hasLoaded && <MemberDetails />}
                <div className="d-flex justify-content-start">
                    <p className="lead mb-0 southland-text-primary">
                        <span className="bold">Questions about Provider Relations? </span>
                        <small>
                            <a href="tel:2054510444" className="southland-text-primary text-decoration-none fw-bold">
                                (205) 343-1221
                            </a>
                        </small>
                    </p>
                </div>
            </DarkSection>
        </>
    );
}
