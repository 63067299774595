import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useClaims } from "../../contexts/claims";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import {
    SearchClaimsByClaimNumberRequest,
    SearchClaimsByIdRequest,
    SearchClaimsByNameRequest,
} from "../../models/ClaimSearch";
import { AppState } from "../../state/AppState";
import { LoadingSpinner } from "../loading/Loading";
import LightSection from "../page-sections/LightSection";
import DarkSection from "../page-sections/DarkSection";
import Claims from "./Claims";

export default function ClaimInquiry() {
    const { searchByName, searchByClaimNumber, searchByMemberId, memberId, setMemberId } = useClaims();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant } = useCurrentTenant(decodedAccessToken.tenant_id);

    const [searchMethod, setSearchMethod] = useState<string>("memberId");
    const [memberIdInput, setMemberIdInput] = useState<string>("");
    const [groupInput, setGroupInput] = useState<string>("");
    const [accountInput, setAccountInput] = useState<string>("");
    const [lastNameInput, setLastNameInput] = useState<string>("");
    const [firstNameInput, setFirstNameInput] = useState<string>("");
    const [claimNumberInput, setClaimNumberInput] = useState<string>("");
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);

    useEffect(() => {
        setHasLoaded(false);
        setHasSubmitted(false);
    }, [searchMethod]);

    useEffect(() => {
        if (memberId) {
            setSearchMethod("memberId");
            setMemberIdInput(memberId);
            handleRetrieve(null);
        }
    }, [memberId]);

    const handleSearchByName = async () => {
        if (!lastNameInput) {
            return;
        }
        const request: SearchClaimsByNameRequest = {
            searchType: "Member Name",
            firstName: firstNameInput,
            lastName: lastNameInput,
            groupNumber: groupInput,
            accountNumber: accountInput,
            providerType: currentTenant?.providerType,
        };
        await searchByName(request);
        setHasLoaded(true);
    };

    const handleSearchByMemberId = async () => {
        if (!memberIdInput && !memberId) {
            return;
        }
        const request: SearchClaimsByIdRequest = {
            searchType: "Member ID",
            participantIdNumber: memberIdInput || memberId || "",
            providerType: currentTenant?.providerType,
        };
        await searchByMemberId(request);
        setHasLoaded(true);
        setMemberId("");
    };

    const handleSearchByClaimNumber = async () => {
        if (!claimNumberInput) {
            return;
        }
        const request: SearchClaimsByClaimNumberRequest = {
            searchType: "Claim Number",
            claimNumber: claimNumberInput,
            providerType: currentTenant?.providerType,
        };
        await searchByClaimNumber(request);
        setHasLoaded(true);
    };

    const handleRetrieve = async (e: any) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);
        setHasLoaded(false);
        setHasSubmitted(true);
        if (searchMethod === "memberName") {
            await handleSearchByName();
        } else if (searchMethod === "memberId") {
            await handleSearchByMemberId();
        } else if (searchMethod === "claimNumber") {
            await handleSearchByClaimNumber();
        }
        setIsLoading(false);
    };

    return (
        <>
            <DarkSection>
                <Form>
                    <Row>
                        <Col xl={3} className="mb-3">
                            <Form.Group controlId="method">
                                <Form.Label>Filter Coverage By</Form.Label>
                                <Form.Select value={searchMethod} onChange={(e) => setSearchMethod(e.target.value)}>
                                    <option value="memberId">Member ID</option>
                                    <option value="memberName">Member Name</option>
                                    <option value="claimNumber">Claim Number</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {searchMethod === "memberId" && (
                            <Col xl={3} className="mb-3">
                                <Form.Group controlId="memberId">
                                    <Form.Label>
                                        Member ID <span style={{ color: "red" }}>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        value={memberIdInput}
                                        onChange={(e) => setMemberIdInput(e.target.value)}
                                        isInvalid={hasSubmitted && !memberIdInput}
                                    />
                                    <Form.Control.Feedback type="invalid">Member ID is required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        )}
                        {searchMethod === "memberName" && (
                            <>
                                <Col xl={2} className="mb-3">
                                    <Form.Group controlId="group">
                                        <Form.Label>Group</Form.Label>
                                        <Form.Control
                                            value={groupInput}
                                            onChange={(e) => setGroupInput(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mb-3">
                                    <Form.Group controlId="account">
                                        <Form.Label>Account</Form.Label>
                                        <Form.Control
                                            value={accountInput}
                                            onChange={(e) => setAccountInput(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mb-3">
                                    <Form.Group controlId="last">
                                        <Form.Label>
                                            Last Name <span style={{ color: "red" }}>*</span>
                                        </Form.Label>
                                        <Form.Control
                                            value={lastNameInput}
                                            onChange={(e) => setLastNameInput(e.target.value)}
                                            isInvalid={hasSubmitted && !lastNameInput}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Last name is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mb-3">
                                    <Form.Group controlId="first">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            value={firstNameInput}
                                            onChange={(e) => setFirstNameInput(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                        {searchMethod === "claimNumber" && (
                            <Col xl={3} className="mb-3">
                                <Form.Group controlId="claimNumber">
                                    <Form.Label>
                                        Claim Number <span style={{ color: "red" }}>*</span>
                                    </Form.Label>
                                    <Form.Control
                                        value={claimNumberInput}
                                        onChange={(e) => setClaimNumberInput(e.target.value)}
                                        isInvalid={hasSubmitted && !claimNumberInput}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Claim number is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        )}
                        <Col xl={1} className="mb-3">
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <Button variant="primary" type="submit" onClick={handleRetrieve}>
                                    Retrieve
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </DarkSection>
            {(isLoading || hasLoaded) && (
                <LightSection>
                    {isLoading && <LoadingSpinner />}
                    {!isLoading && hasSubmitted && hasLoaded && <Claims />}
                </LightSection>
            )}
        </>
    );
}
