import {
    FinosecSystemStatisticReportsAction,
    FINOSEC_SYSTEM_STATISTIC_REPORTS_FETCHED,
} from "../../actions/finosecSystemStatistic/FinosecSystemStatisticAction";
import { FinosecSystemStatisticReportsState } from "../../state/FinosecSystemStatisticState";

const initialState: FinosecSystemStatisticReportsState = {
    finosecSystemStatisticsReports: [],
};

export function finosecSystemStatisticReportsReducer(
    state: FinosecSystemStatisticReportsState = initialState,
    action: FinosecSystemStatisticReportsAction
): FinosecSystemStatisticReportsState {
    switch (action.type) {
        case FINOSEC_SYSTEM_STATISTIC_REPORTS_FETCHED:
            return {
                ...state,
                finosecSystemStatisticsReports: action.finosecSystemStatisticReports,
            };

        default:
            return state;
    }
}
