import { Badge, Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { AppConfiguration } from "../../../models/AppConfiguration";
import { useHistory } from "react-router-dom";

const GroupAdminConfigurations = () => {
    const initFormAppConfigurations: AppConfiguration = useMemo(() => {
        return {
            bannerMessage: "",
            notificationEmail: "",
        };
    }, []);

    const [appConfigurations, setAppConfigurations] = useState<AppConfiguration>();
    const [formAppConfigurations, setFormAppConfigurations] = useState<AppConfiguration>(initFormAppConfigurations);
    const [dataFetched, setDataFetched] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const history = useHistory();
    const { get, post } = useFetch();

    const handleSave = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        const isValid = form.checkValidity();
        if (isValid) {
            if (!(appConfigurations === formAppConfigurations)) {
                const response = await post<AppConfiguration>("app-configurations", formAppConfigurations);
                if (response) {
                    setAppConfigurations(response);
                    setIsValidated(false);
                    history.push("/admin-users");
                }
            }
        } else {
            setIsValidated(true);
        }
    };

    useEffect(() => {
        const populateAppConfigurations = async () => {
            const response = await get<AppConfiguration>("app-configurations");
            setAppConfigurations(response ? response : undefined);
            setDataFetched(true);
        };
        populateAppConfigurations().catch((e) => console.error(e));
    }, [get]);

    useEffect(() => {
        setFormAppConfigurations(appConfigurations ?? initFormAppConfigurations);
    }, [appConfigurations, initFormAppConfigurations]);

    const InfoBadge = (props: { message: string }) => {
        const { message } = props;
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{message}</Tooltip>}
            >
                <Badge pill bg="secondary" className={"ms-2"}>
                    ?
                </Badge>
            </OverlayTrigger>
        );
    };

    const handleCancel = () => {
        history.push("/admin-users");
    };

    return (
        <Container>
            <Row className={"justify-content-center mt-5"}>
                <Col lg={"6"}>
                    <Form noValidate onSubmit={handleSave} validated={isValidated}>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="banner-message">
                                    Banner Message
                                    <InfoBadge
                                        message={
                                            "This is the message that will be presented to Group Admins when they log in. It is typically used to communicate important information or updates."
                                        }
                                    />
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        id="banner-message"
                                        placeholder="Enter message..."
                                        aria-label="Banner Message"
                                        value={formAppConfigurations?.bannerMessage}
                                        onChange={(e) =>
                                            setFormAppConfigurations((prev) => ({
                                                ...prev,
                                                bannerMessage: e.target.value,
                                            }))
                                        }
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className={"justify-content-end align-items-center"}>
                            <Col xs={"auto"}>
                                <Button
                                    variant="primary"
                                    disabled={!dataFetched || appConfigurations === formAppConfigurations}
                                    type="submit"
                                    className={"ms-2"}
                                >
                                    Save
                                </Button>
                            </Col>
                            <Col xs={"auto"}>
                                <Button variant="secondary" className={"ms-2"} onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
export default GroupAdminConfigurations;
