import React, { createContext } from "react";
import { MemberEnrollmentUpdateModel, NewMemberFormModel } from "../../components/group-admin/NewMemberFormModel";
import { EnrollmentDetail, PlanCodeDescription, RateLevel } from "../../models/Enrollment";

export type IMemberEnrollmentContext = {
    newMember: NewMemberFormModel | undefined;
    setNewMember: React.Dispatch<React.SetStateAction<NewMemberFormModel | undefined>>;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    steps: string[];
    nextClicked: boolean;
    setNextClicked: React.Dispatch<React.SetStateAction<boolean>>;
    backClicked: boolean;
    setBackClicked: React.Dispatch<React.SetStateAction<boolean>>;
    validateDependents: boolean;
    setValidateDependents: React.Dispatch<React.SetStateAction<boolean>>;
    validateCoverage: boolean;
    setValidateCoverage: React.Dispatch<React.SetStateAction<boolean>>;
    resetFlags: () => void;
    discardForm: () => void;
    filename: string;
    setFilename: React.Dispatch<React.SetStateAction<string>>;
    uploadSuccess: boolean | undefined;
    setUploadSuccess: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    isUpdating: boolean;
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
    updatedMember: MemberEnrollmentUpdateModel | undefined;
    setUpdatedMember: React.Dispatch<React.SetStateAction<MemberEnrollmentUpdateModel | undefined>>;
    enrollmentDetail: EnrollmentDetail | undefined;
    setEnrollmentDetail: React.Dispatch<React.SetStateAction<EnrollmentDetail | undefined>>;
    coverageFormData: {
        planCodeDescriptions: PlanCodeDescription[];
        rateLevels: RateLevel[];
    };
    dbMember: NewMemberFormModel | undefined;
    canProceedWithoutDependent: boolean;
    setCanProceedWithoutDependent: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultValue: IMemberEnrollmentContext = {
    newMember: undefined,
    setNewMember: () => {},
    activeStep: 0,
    setActiveStep: () => {},
    steps: [],
    nextClicked: false,
    setNextClicked: () => {},
    backClicked: false,
    setBackClicked: () => {},
    validateDependents: false,
    setValidateDependents: () => {},
    validateCoverage: false,
    setValidateCoverage: () => {},
    resetFlags: () => {},
    discardForm: () => {},
    filename: "",
    setFilename: () => {},
    uploadSuccess: undefined,
    setUploadSuccess: () => {},
    isUpdating: false,
    setIsUpdating: () => {},
    updatedMember: undefined,
    setUpdatedMember: () => {},
    enrollmentDetail: undefined,
    setEnrollmentDetail: () => {},
    coverageFormData: {
        planCodeDescriptions: [],
        rateLevels: [],
    },
    dbMember: undefined,
    canProceedWithoutDependent: true,
    setCanProceedWithoutDependent: () => {},
};
export const MemberEnrollmentContext = createContext<IMemberEnrollmentContext>(defaultValue);
