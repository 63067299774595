import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { PlanAllotment } from "../../models/PlanAllotment";
import { IPlanAllotmentContext, PlanAllotmentContext } from "./PlanAllotmentContext";
import { useMembers } from "../members";
import { useUsers } from "../users";
import { UserTypes } from "../../models/UserTypes";
import { AccumulatorNumbers } from "../../models/Accumulator";

export const PlanAllotmentProvider = (props: PropsWithChildren<any>) => {
    const { getWithQuery } = useFetch();
    const { member, myInsuranceInfo } = useMembers();
    const {
        userType,
        userState: { loggedInUser },
    } = useUsers();

    const [planAllotments, setPlanAllotments] = useState<PlanAllotment[]>([]);

    const getPlanAllotments = useCallback(
        async (groupNumber = "", planCodes: string[] = []) => {
            let result: PlanAllotment[] = [];
            for (const planCode of planCodes) {
                const allotments = await getWithQuery<PlanAllotment[]>("planallotments", { groupNumber, planCode });
                if (allotments) {
                    result = [...result, ...allotments];
                }
            }
            setPlanAllotments(result);
        },
        [getWithQuery]
    );

    useEffect(() => {
        if (loggedInUser && userType === UserTypes.Member && myInsuranceInfo) {
            getPlanAllotments(
                loggedInUser.groupNumber,
                myInsuranceInfo.coverageInformation.map((cov) => cov.productPlan)
            );
        }
    }, [myInsuranceInfo, loggedInUser, userType]);

    useEffect(() => {
        if (member) {
            getPlanAllotments(member.groupNumber, [member.planCode]);
        }
    }, [getPlanAllotments, member]);

    const findAllotmentByAccumulator = useCallback(
        (accumulatorNumber: AccumulatorNumbers) => {
            return planAllotments.find((allot) => allot.accumulatorNumber === accumulatorNumber);
        },
        [planAllotments]
    );

    const value = useMemo(
        (): IPlanAllotmentContext => ({
            planAllotments,
            findAllotmentByAccumulator,
        }),
        [planAllotments, findAllotmentByAccumulator]
    );

    return <PlanAllotmentContext.Provider value={value} {...props} />;
};

export const usePlanAllotments = () => {
    const context = useContext(PlanAllotmentContext);
    if (context === undefined) {
        throw new Error("usePlanAllotments must be used within a PlanAllotmentProvider");
    }
    return context;
};
