import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { authenticationReducer } from "../reducers/AuthenticationReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { headerReducer } from "../reducers/HeaderReducer";
import { modalReducer } from "../reducers/ModalReducer";
import { servicesContainer } from "../services/IOC/ServicesContainer";
import { tabReducer } from "../reducers/TabReducer";
import { toastReducer } from "../reducers/ToastReducer";
import { tenantReducer } from "../reducers/tenant/TenantReducer";
import { reportReducer } from "../reducers/report/ReportReducer";
import { reportAttributeReducer } from "../reducers/reportAttribute/ReportAttributeReducer";
import { subReportReducer } from "../reducers/subReport/SubReportReducer";
import { subReportAttributeReducer } from "../reducers/subReportAttribute/SubReportAttributeReducer";
import { awsFileReducer } from "../reducers/AwsFileReducer";
import { finosecSystemReducer } from "../reducers/finosecSystem/FinosecSystemReducer";
import { finosecSystemStatisticReducer } from "../reducers/finosecSystemStatistic/FinosecSystemStatisticReducer";
import { finosecSystemStatisticReportsReducer } from "../reducers/finosecSystemStatistic/FinosecSystemStatisticReportsReducer";
import { customerImportReducer } from "../reducers/customerImport/CustomerImportReducer";
import { customerImportFileReducer } from "../reducers/customerImportFile/CustomerImportFileReducer";
import { customerReportReducer } from "../reducers/customerReport/CustomerReportReducer";
import { customerDocumentReducer } from "../reducers/customerDocument/CustomerDocumentReducer";
import { fileStatusReducer } from "../reducers/fileStatus/FileStatusReducer";
import { customerDocumentStatisticsReducer } from "../reducers/customerDocument/CustomerDocumentStatisticsReducer";

const mapTransformer = (config) => {
    return createTransform(
        (map) => JSON.stringify(Array.from(map)),
        (arrayString) => new Map(JSON.parse(arrayString)),
        config
    );
};

const persistConfig = {
    key: "root",
    storage: storage,
    stateReconciler: autoMergeLevel2, // need to investigate this more
    transforms: [mapTransformer({ whitelist: ["roleState", "userState", "modalState", "tenantState"] })],
    // we can whitelist or blacklist substates
};

const combinedReducer = combineReducers({
    authenticationState: authenticationReducer,
    awsFileState: awsFileReducer,
    headerState: headerReducer,
    modalState: modalReducer,
    reportState: reportReducer,
    reportAttributeState: reportAttributeReducer,
    tabState: tabReducer,
    tenantState: tenantReducer,
    toastState: toastReducer,
    subReportState: subReportReducer,
    subReportAttributeState: subReportAttributeReducer,
    finosecSystemState: finosecSystemReducer,
    finosecSystemStatisticState: finosecSystemStatisticReducer,
    finosecSystemStatisticReportsState: finosecSystemStatisticReportsReducer,
    customerImportState: customerImportReducer,
    customerImportFileState: customerImportFileReducer,
    customerReportState: customerReportReducer,
    customerDocumentState: customerDocumentReducer,
    customerDocumentStatisticsState: customerDocumentStatisticsReducer,
    fileStatusState: fileStatusReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        state = undefined;
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(applyMiddleware(thunk.withExtraArgument(servicesContainer)))
);

export const persistor = persistStore(store);
