import { useCallback, useRef, useState } from "react";
import { Alert, Button, Col, Modal, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useMembers } from "../../contexts/members";
import { getFormattedDateFromDbValue } from "../../helper-functions/getFormattedDateFromDbValue";
import { LoadingSpinner } from "../loading/Loading";
import IdCard from "./IdCard";

export default function MemberDetails() {
    const {
        member,
        memberDependents,
        getMemberPDF,
        hasPdfError,
        isGettingPdf,
        getMemberBenefits,
        isLoading,
    } = useMembers();
    const [showIdModal, setShowIdModal] = useState<boolean>(false);

    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            let newContent: HTMLDivElement | null = componentRef?.current?.cloneNode(true) as HTMLDivElement;
            newContent?.setAttribute("style", "background-color: white; height: 100%; transform: scale(0.7);");
            return newContent;
        },
    });

    const handleGetIdCard = useCallback(() => {
        setShowIdModal(true);
        getMemberBenefits("", "", "");
    }, [getMemberBenefits]);

    if (member)
        return (
            <>
                <Row>
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Member ID</th>
                                    <th>Member Name</th>
                                    <th>Date of Birth</th>
                                    <th>Group</th>
                                    <th>Account</th>
                                    <th>Member Effective Date</th>
                                    <th>Member Termination Date</th>
                                    <th>Plan Detail</th>
                                    <th>ID Card</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[member, ...memberDependents].map((currentMember, i) => (
                                    <tr key={`member-${currentMember.dependentSequenceNumber}-${i}`}>
                                        <td>{currentMember.participantIDNumber}</td>
                                        <td>
                                            <Link to="/coverage-inquiry/coverage-summary">
                                                {currentMember.firstName} {currentMember.middleName}{" "}
                                                {currentMember.lastName}
                                            </Link>
                                        </td>
                                        <td>{new Date(currentMember.birthDate).toLocaleDateString()}</td>
                                        <td>{currentMember.groupNumber}</td>
                                        <td>
                                            {currentMember.accountNumber} - {currentMember.planCode}
                                        </td>
                                        <td>
                                            {getFormattedDateFromDbValue(currentMember.memberProductLinkEffectiveDate)}
                                        </td>
                                        <td>{getFormattedDateFromDbValue(currentMember.memberProductLinkTermDate)}</td>
                                        <td>
                                            <Button
                                                size="sm"
                                                onClick={() => getMemberPDF(currentMember)}
                                                disabled={isGettingPdf}
                                            >
                                                <i className="fas fa-file-pdf" />
                                            </Button>
                                        </td>
                                        <td>
                                            <Button size="sm" onClick={handleGetIdCard} disabled={isLoading}>
                                                <i className="fas fa-id-card" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {isGettingPdf && (
                            <Col xl={3} className="mx-auto">
                                <Alert variant="success" className="text-center">
                                    Downloading PDF...
                                    <LoadingSpinner />
                                </Alert>
                            </Col>
                        )}
                    </Col>
                </Row>
                {hasPdfError && (
                    <Alert variant="danger">
                        There was a problem retrieving the plan details. For more assistance, please call Customer
                        Service at{" "}
                        <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                            (205) 451-0444
                        </a>
                        .
                    </Alert>
                )}
                <Modal show={showIdModal} onHide={() => setShowIdModal(false)} centered>
                    <Modal.Header className="p-2" closeButton>
                        ID Card
                    </Modal.Header>
                    <Modal.Body>
                        <IdCard ref={componentRef} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowIdModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handlePrint}>
                            Print
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );

    return (
        <p>
            Search returned no results. Before denying coverage, please call Customer Service at{" "}
            <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                (205) 451-0444
            </a>
            .
        </p>
    );
}
