import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../state/AppState";
import { store } from "../store";
import { ERROR } from "../constants/ToastConstants";
import { showToast } from "../actions/ToastActionCreators";
import { KeysysToastProps } from "../components/toast/KeysysToastProps";
import { API_BASE_URL } from "../config/config";
import { Operation } from "rfc6902";
import { loginFail } from "../actions/AuthenticationActionCreators";
import { saveFile } from "../helper-functions";
import axios from "axios";

type FileRequestOptions = {
    path: string;
    queryParams?: any;
    fileName?: string;
    contentType?: string;
};

interface IUseFetch {
    get: <T>(resourcePath: string) => Promise<T | void>;
    getFile: (options: FileRequestOptions) => Promise<any>;
    downloadFile: (options: FileRequestOptions) => Promise<void>;
    getFileWithPostMethod: (
        path: string,
        body: any,
        fileName: string,
        contentType: string,
        throwOnError: boolean
    ) => Promise<void>;
    getWithQuery: <T>(resourcePath: string, queryParams: any, getAll?: boolean) => Promise<T | void>;
    post: <T>(resourcePath: string, body: T, onError?: (error: any) => void) => Promise<T | void>;
    postUnique: <TBody, TResponse>(resourcePath: string, body: TBody) => Promise<TResponse | void>;
    put: <T>(resourcePath: string, body: T, onError?: (error: any) => void) => Promise<T | void>;
    patch: <T>(resourcePath: string, body: Operation[]) => Promise<T | void>;
    del: (resourcePath: string) => Promise<boolean | void>;
    postFormData<T>(path: string, body: FormData): Promise<T | undefined>;
}

enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
}

interface ErrorResponse {
    statusCode: number;
    message: string;
    error?: any;
}

const getDefaultOptions = (method: Method, accessToken: string, body?: any): RequestInit => {
    return {
        method: method,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        body: body ? JSON.stringify(body) : null,
    };
};

const fetchRequest = async (resource: string, method: Method, accessToken: string, body?: any): Promise<Response> => {
    const response = await fetch(API_BASE_URL + resource, getDefaultOptions(method, accessToken, body));
    if (!response.ok) {
        const errorRes: ErrorResponse = {
            statusCode: response.status,
            message: response.statusText,
            error: (await response.json()).Error,
        };
        throw errorRes;
    }

    return response;
};

const isContentTypeJson = (response: Response): boolean => {
    const contentType = response.headers.get("content-type");
    return !!contentType && contentType.includes("json");
};

const resolveContent = async <T>(response: Response): Promise<T | Response> => {
    return isContentTypeJson(response) ? await response.json().then((content: T) => content) : response;
};

const isContentT = <T>(content: Response | T): content is T => {
    if (content && content instanceof Response) {
        return false;
    }
    return true;
};

const handleError = (error: any, isAuthenticated: boolean) => {
    const toastProps: KeysysToastProps = {
        name: ERROR,
        theme: "danger",
        titleInHeader: "Error",
        body: error?.message || "There was a problem processing that request.",
        delay: 15000,
    };
    if (error.statusCode === 401 && isAuthenticated && !error.message.includes("current user does not have acccess")) {
        toastProps.body = "Your session has expired. Please login again.";
        store.dispatch(loginFail());
    }
    store.dispatch(showToast(toastProps));
};

const handleSuccess = (message: string) => {
    const toastProps: KeysysToastProps = {
        name: "Success",
        theme: "success",
        titleInHeader: "Success!",
        body: message,
    };
    store.dispatch(showToast(toastProps));
};

export const useFetch = (): IUseFetch => {
    const { accessToken, isAuthenticated } = useSelector((state: AppState) => state.authenticationState);

    const get = useCallback(
        async <T>(path: string): Promise<T | void> => {
            try {
                const response = await fetchRequest(path, Method.GET, accessToken);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    return content;
                }
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const getFile = useCallback(
        async ({ path, queryParams }: FileRequestOptions): Promise<any> => {
            try {
                const { data } = await axios.get(path, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: queryParams,
                    baseURL: API_BASE_URL,
                    responseType: "blob",
                });
                return data;
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const downloadFile = useCallback(
        async (options: FileRequestOptions): Promise<void> => {
            const { fileName, contentType } = options;
            try {
                const data = await getFile(options);
                saveFile(fileName ?? "Download", contentType ?? "application/pdf", data);
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated, getFile]
    );

    const getFileWithPostMethod = useCallback(
        async (
            path: string,
            body: any,
            fileName: string = "Download",
            contentType: string = "application/pdf",
            throwOnError: boolean = false
        ): Promise<void> => {
            if (throwOnError) {
                const { data } = await axios.post(path, body, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    baseURL: API_BASE_URL,
                    responseType: "blob",
                });
                saveFile(fileName, contentType, data);
            } else {
                try {
                    const { data } = await axios.post(path, body, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        baseURL: API_BASE_URL,
                        responseType: "blob",
                    });
                    saveFile(fileName, contentType, data);
                } catch (error) {
                    handleError(error, isAuthenticated);
                }
            }
        },
        [accessToken, isAuthenticated]
    );

    const getWithQuery = useCallback(
        async <T>(path: string, queryParams: any, getAll: boolean = false): Promise<T | void> => {
            try {
                const pathWithQuery = path + `?${new URLSearchParams(queryParams)}`;
                const response = await fetchRequest(pathWithQuery, Method.GET, accessToken);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    return content;
                }
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const post = useCallback(
        async <T>(path: string, body: T, onError?: (error: any) => void): Promise<T | void> => {
            try {
                const response = await fetchRequest(path, Method.POST, accessToken, body);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    handleSuccess("Created successfully");
                    return content;
                }
            } catch (error) {
                onError ? onError(error) : handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const postUnique = useCallback(
        async <TBody, TResponse>(path: string, body: TBody): Promise<TResponse | void> => {
            try {
                const response = await fetchRequest(path, Method.POST, accessToken, body);
                const content = await resolveContent<TResponse>(response);
                if (isContentT(content)) {
                    handleSuccess("Your request was successful");
                    return content;
                }
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const put = useCallback(
        async <T>(path: string, body: T, onError?: (error: any) => void): Promise<T | void> => {
            try {
                const response = await fetchRequest(path, Method.PUT, accessToken, body);
                console.log(response);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    handleSuccess("Updated successfully");
                    return content;
                }
            } catch (error) {
                onError ? onError(error) : handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const patch = useCallback(
        async <T>(path: string, body: Operation[]): Promise<T | void> => {
            try {
                const response = await fetchRequest(path, Method.PATCH, accessToken, body);
                const content = await resolveContent<T>(response);
                if (isContentT(content)) {
                    handleSuccess("Updated successfully");
                    return content;
                }
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const del = useCallback(
        async (path: string): Promise<boolean | void> => {
            try {
                const response = await fetchRequest(path, Method.DELETE, accessToken);
                if (response) {
                    handleSuccess("Deleted successfully");
                    return true;
                }
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const postFormData = useCallback(
        async <T>(path: string, body: FormData): Promise<T | undefined> => {
            try {
                const { data } = await axios.post(path, body, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    baseURL: API_BASE_URL,
                });
                return data;
            } catch (error) {
                handleError(error, isAuthenticated);
            }
        },
        [accessToken, isAuthenticated]
    );

    const value = useMemo(
        () => ({
            get,
            getFile,
            downloadFile,
            getFileWithPostMethod,
            getWithQuery,
            post,
            postUnique,
            put,
            patch,
            del,
            postFormData,
        }),
        [
            get,
            getFile,
            downloadFile,
            getFileWithPostMethod,
            getWithQuery,
            post,
            postUnique,
            put,
            patch,
            del,
            postFormData,
        ]
    );

    return value;
};
