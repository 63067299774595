import { User, UserManager, UserManagerSettings } from "oidc-client";
import { SSO_POST_LOGOUT_URL, SSO_REDIRECT_URL, SSO_URL } from "../config/config";

export class SsoService {
    private config: UserManagerSettings = {
        authority: SSO_URL,
        automaticSilentRenew: true,
        client_id: "CanopyIdentity",
        redirect_uri: SSO_REDIRECT_URL,
        response_type: "code",
        scope: "CanopyIdentityApi",
        post_logout_redirect_uri: SSO_POST_LOGOUT_URL,
    };
    private readonly userManager: UserManager;

    constructor(userManager?: UserManager) {
        this.userManager = userManager ?? new UserManager(this.config);
    }

    public signIn(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public signInRedirectCallback(): Promise<User> {
        return this.userManager.signinRedirectCallback();
    }

    public async signOut(): Promise<void> {
        await this.userManager.signoutRedirect();
    }
}
