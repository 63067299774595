import { createContext, useCallback, useMemo, useContext, useState, useEffect } from "react";
import { AppSettings } from "../../models/AppSettings";
import { useFetch } from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { Operable } from "../../models/Operable";
import { createPatch } from "rfc6902";

interface IAppContext {
    customerName: string;
    partnerName: string;
    logoUri: string;
    siteName: string;
    menuBackgroundColor: string;
    menuSelectedColor: string;
    createAppSettings: (settings: AppSettings) => Promise<AppSettings | void>;
    editAppSettings: (settings: Operable<AppSettings>) => Promise<AppSettings | void>;
}

const AppContext = createContext<IAppContext>({} as IAppContext);

export const AppProvider = ({ children }: any) => {
    const [appSettings, setAppSettings] = useState<AppSettings>({} as AppSettings);
    const { get, post, patch } = useFetch();
    const { isAuthenticated, decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const customerName = appSettings.customerName || "Customer";
    const partnerName = appSettings.partnerName || "Partner";
    const logoUri = appSettings.logoUri || "";
    const siteName = appSettings.siteName || "SOUTHLAND";
    const menuBackgroundColor = appSettings.menuBackgroundColor || "#074483";
    const menuSelectedColor = appSettings.menuSelectedColor || "#001325";

    const getAppSettings = useCallback(async () => {
        if (isAuthenticated && decodedAccessToken.userId) {
            const response = await get<AppSettings>("appsettings");
            if (response) {
                setAppSettings(response);
            }
        }
    }, [get, decodedAccessToken.userId, isAuthenticated]);

    useEffect(() => {
        getAppSettings();
    }, [getAppSettings]);

    const createAppSettings = useCallback(
        async (settings: AppSettings): Promise<AppSettings | void> => {
            return await post<AppSettings>("appsettings", settings);
        },
        [post]
    );

    const editAppSettings = useCallback(
        async (settings: Operable<AppSettings>): Promise<AppSettings | void> => {
            const operations = createPatch(settings.original, settings.updated);
            const response = await patch<AppSettings>(`appsettings/${settings.original.id}`, operations);
            if (response && response.tenantId === decodedAccessToken.tenant_id) {
                setAppSettings(response);
            }
            return response;
        },
        [patch, decodedAccessToken]
    );

    const value = useMemo(
        () => ({
            customerName,
            partnerName,
            logoUri,
            siteName,
            menuBackgroundColor,
            menuSelectedColor,
            createAppSettings,
            editAppSettings,
        }),
        [
            customerName,
            partnerName,
            logoUri,
            siteName,
            menuBackgroundColor,
            menuSelectedColor,
            createAppSettings,
            editAppSettings,
        ]
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error("useApp must be used within an AppProvider");
    }
    return context;
};
