import React, { useState } from "react";
import { Button, Col, Row, Table, Collapse, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useClaims } from "../../contexts/claims";
import { useMembers } from "../../contexts/members";
import { EnrollmentInformation } from "../../models/MyInsuranceInfo";
import { useUsers } from "../../contexts/users";
import { UserTypes } from "../../models/UserTypes";

export default function EnrollmentInformationView() {
    const history = useHistory();
    const { myInsuranceInfo, isMember } = useMembers();
    const { setClaimsFilter, setMemberId } = useClaims();
    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const { userType } = useUsers();

    const toggleRowExpand = (row: string) => {
        setExpandedRows((current) => {
            if (current.includes(row)) {
                return current.filter((x) => x !== row);
            } else {
                return [...current, row];
            }
        });
    };

    const handleViewClaims = (data: EnrollmentInformation) => {
        if (isMember) {
            setClaimsFilter(`${data.firstName} ${data.lastName}`);
            history.push("/my-claims");
        } else {
            setMemberId(data.participantIdNumber);
            history.push("/claim-inquiry");
        }
    };

    const getMemberName = (data: EnrollmentInformation): string => {
        let result = data.firstName;
        if (data.middleName) {
            result += ` ${data.middleName}`;
        }
        result += ` ${data.lastName}`;
        return result;
    };

    if (myInsuranceInfo)
        return (
            <>
                <Row>
                    <Col>
                        <h5 className="border border-bottom-0 p-3 mb-0 form-label">Enrollment Information</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Relationship</th>
                                    <th>DOB</th>
                                    <th>Gender</th>
                                    <th>Government ID</th>
                                    {userType === UserTypes.Provider && <th>Claims</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {myInsuranceInfo.enrollmentInformation.map((x: EnrollmentInformation, i: number) => (
                                    <React.Fragment key={`ei-${x.participantIdNumber}-${i}`}>
                                        <tr>
                                            <td>
                                                <Button size="sm" onClick={() => toggleRowExpand(`ei-${i}`)}>
                                                    <i
                                                        className={
                                                            expandedRows.includes(`ei-${i}`)
                                                                ? `fas fa-minus`
                                                                : `fas fa-plus`
                                                        }
                                                    />
                                                </Button>
                                            </td>
                                            <td>{getMemberName(x)}</td>
                                            <td>{x.relationship}</td>
                                            <td>{x.birthDate ? new Date(x.birthDate).toLocaleDateString() : ""}</td>
                                            <td>{x.gender}</td>
                                            <td>{x.governmentId}</td>
                                            {userType === UserTypes.Provider && (
                                                <td>
                                                    <Button variant="primary" onClick={() => handleViewClaims(x)}>
                                                        View Claims
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                        <Collapse in={expandedRows.includes(`ei-${i}`)}>
                                            <tr>
                                                <td />
                                                <td colSpan={6}>
                                                    <Row>
                                                        <Col xl={3}>
                                                            <Form.Group>
                                                                <Form.Label>Member ID</Form.Label>
                                                                <Form.Control value={x.participantIdNumber} readOnly />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        );

    return (
        <p>
            Search returned no results. Before denying coverage, please call Customer Service at{" "}
            <a href="tel:2054510444" style={{ color: "#000", textDecoration: "none" }}>
                (205) 451-0444
            </a>
            .
        </p>
    );
}
