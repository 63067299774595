/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { useDispatch } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import { User } from "../../models/User";
import { Role } from "../../models/Role";
import { Identifiable } from "../../models/Identifiable";
import EditButtonRender from "../grid/EditButtonRender";
import { getTenants } from "../../actions/tenants/TenantActionCreators";
import DeleteButtonRender from "../grid/DeleteButtonRender";
import RestoreButtonRender from "../grid/RestoreButtonRender";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import UserForm from "./forms/UserForm";
import { useUsers } from "../../contexts/users";
import { useRoles } from "../../contexts/roles";
import { useApp } from "../../contexts/app";
import LightSection from "../page-sections/LightSection";
import { getSanitizedProviderName } from "../../helper-functions";

interface UserWithFullDetails extends Identifiable {
    fullName: string;
    email: string;
    tenant: string;
    partnerTenant: string;
    customerTenant: string;
    role: string;
    status: string;
}

export default function Users() {
    const { userState, getUsers, selectUser, clearSelectedUser, restoreUser, deleteUser } = useUsers();
    const { roleState, getRoles } = useRoles();
    const { partnerName, customerName } = useApp();
    const dispatch = useDispatch();
    const [shouldShowInactiveUsers, setShouldshowInactiveUsers] = useState<boolean>(false);
    const [showUserFormModal, setShowUserFormModal] = useState<boolean>(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false);
    const [userFormType, setUserFormType] = useState<"add" | "edit">("add");

    const getRole = useCallback((roles: Role[], id: number) => {
        return roles.find((r) => r.id === id)?.name;
    }, []);

    const getUsersWithFullDetails = useCallback(() => {
        let details: UserWithFullDetails[];
        details = userState.users.map(
            (user: User) =>
                ({
                    id: user.id,
                    fullName: `${user.lastName}, ${user.firstName}`,
                    email: user.emailAddress,
                    tenant: user.tenantName,
                    partnerTenant: user.partnerTenantName,
                    customerTenant: user.customerTenantName,
                    role: getRole(roleState.roles, user.roleId),
                    status: user.isActive ? "Active" : "Inactive",
                } as UserWithFullDetails)
        );
        return shouldShowInactiveUsers ? details : details.filter((u) => u.status === "Active");
    }, [userState.users, roleState.roles, getRole, shouldShowInactiveUsers]);

    const usersWithFullDetails = getUsersWithFullDetails();

    useEffect(() => {
        if (userState.users.length < 1) {
            getUsers();
        }
    }, [getUsers, userState.users]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    useEffect(() => {
        dispatch(getTenants());
    }, []);

    const showAddUserModal = useCallback(() => {
        clearSelectedUser();
        setUserFormType("add");
        setShowUserFormModal(true);
    }, [clearSelectedUser]);

    const handleDeleteUser = useCallback(() => {
        if (userState.selectedUser) {
            deleteUser(userState.selectedUser);
            setShowConfirmDeleteModal(false);
        }
    }, [deleteUser, userState.selectedUser]);

    const handleRestoreUser = useCallback(() => {
        if (userState.selectedUser) {
            restoreUser(userState.selectedUser);
            setShowConfirmRestoreModal(false);
        }
    }, [userState.selectedUser, restoreUser]);

    const showEditUserModal = useCallback(
        (params: User) => {
            const user = userState.users.find((user: User) => user.id === params.id)!;
            selectUser(user);
            setUserFormType("edit");
            setShowUserFormModal(true);
        },
        [userState.users, selectUser]
    );

    const showDeleteCallback = useCallback(
        (params: number) => {
            const user = userState.users.find((user: User) => user.id === params)!;
            selectUser(user);
            setShowConfirmDeleteModal(true);
        },
        [userState.users, selectUser]
    );

    const showRestoreCallback = useCallback(
        (params: number) => {
            const user = userState.users.find((user: User) => user.id === params)!;
            selectUser(user);
            setShowConfirmRestoreModal(true);
        },
        [userState.users, selectUser]
    );

    const columnDefs: ColDef[] = [
        {
            headerName: "Full Name",
            field: "fullName",
        },
        {
            headerName: "Email",
            field: "email",
        },
        {
            headerName: `${partnerName} Tenant`,
            field: "partnerTenant",
            cellRenderer: ({ value }) => getSanitizedProviderName(value),
        },
        {
            headerName: `${customerName} Tenant`,
            field: "customerTenant",
            cellRenderer: ({ value }) => getSanitizedProviderName(value),
        },
        {
            headerName: "Role",
            field: "role",
        },
        {
            headerName: "Status",
            field: "status",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "User",
                showEdit: showEditUserModal,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit User",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: DeleteButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "User",
                    showDelete: showDeleteCallback,
                    isActive: data?.data?.status === "Active",
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Deactivate User",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: RestoreButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "User",
                    showRestore: showRestoreCallback,
                    isActive: data?.data?.status === "Active",
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Activate User",
        },
    ];

    return (
        <LightSection>
            <div>
                <Row className={"justify-content-end"}>
                    <Col lg={2}>
                        <label onClick={() => setShouldshowInactiveUsers((x) => !x)}>
                            <i
                                className={!shouldShowInactiveUsers ? "far fa-circle" : "fas fa-check-circle"}
                                style={{
                                    fontSize: "1.2rem",
                                    color: !shouldShowInactiveUsers ? "gray" : "green",
                                }}
                            />
                            &nbsp;&nbsp;&nbsp; Show Inactive Users
                        </label>
                    </Col>
                    <Col lg={1} className={"add-user-button-wrapper"}>
                        <Button
                            className={"add-user-button finosec-button-info"}
                            onClick={showAddUserModal}
                            data-testid={"add-user-button"}
                        >
                            Add User
                        </Button>
                    </Col>
                </Row>
                <Row className={"users-grid-container"}>
                    <Col className={"h-100 p-0"}>
                        <div className="ag-theme-alpine">
                            <KeysysGrid getRows={() => usersWithFullDetails} columnDefs={columnDefs} />
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                {showUserFormModal && (
                    <UserForm
                        type={userFormType}
                        user={userState.selectedUser}
                        onClose={() => setShowUserFormModal(false)}
                    />
                )}
            </div>
            <div>
                <ConfirmationModal
                    id="confirmDeleteUserModal"
                    show={showConfirmDeleteModal}
                    title="Deactivate User?"
                    message={"Do you really want to deactivate this user?"}
                    onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                    onOkButtonClick={handleDeleteUser}
                    theme={"danger"}
                />
            </div>
            <div>
                <ConfirmationModal
                    id="confirmRestoreUserModal"
                    show={showConfirmRestoreModal}
                    title="Activate User?"
                    message={"Do you really want to activate this user?"}
                    onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                    onOkButtonClick={handleRestoreUser}
                    theme={"danger"}
                />
            </div>
        </LightSection>
    );
}
