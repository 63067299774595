import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { Accumulator, AccumulatorRequest, MemberAccumulator } from "../../models/Accumulator";
import { UserTypes } from "../../models/UserTypes";
import { useMembers } from "../members";
import { useUsers } from "../users";
import { IAccumulatorContext, AccumulatorContext } from "./AccumulatorContext";

export const AccumulatorProvider = (props: PropsWithChildren<any>) => {
    const { getWithQuery, postUnique } = useFetch();

    const { member } = useMembers();

    const {
        userType,
        userState: { loggedInUser },
    } = useUsers();

    const [accumulators, setAccumulators] = useState<Accumulator[]>([]);
    const [memberAccumulators, setMemberAccumulators] = useState<MemberAccumulator[]>([]);

    const getAccumulators = useCallback(
        async (groupNumber: string, participantIDNumber: string) => {
            const accumulators = await getWithQuery<Accumulator[]>("accumulators", {
                groupNumber,
                participantIDNumber,
            });
            if (accumulators) {
                setAccumulators(accumulators);
            }
        },
        [getWithQuery]
    );

    const getMemberAccumulators = useCallback(
        async (participantIDNumber = "", groupNumber = "", accountNumber = 0) => {
            const response = await postUnique<AccumulatorRequest, MemberAccumulator[]>("accumulators", {
                participantIDNumber,
                groupNumber,
                accountNumber,
            });
            if (response) {
                setMemberAccumulators(response);
            }
        },
        [postUnique]
    );

    useEffect(() => {
        if (loggedInUser && userType === UserTypes.Member) {
            getMemberAccumulators(
                loggedInUser.participantIdNumber,
                loggedInUser.groupNumber,
                loggedInUser.accountNumber
            );
        }
    }, [getMemberAccumulators, loggedInUser, userType]);

    useEffect(() => {
        if (member) {
            getMemberAccumulators(member.participantIDNumber, member.groupNumber, member.accountNumber);
        }
    }, [getMemberAccumulators, member]);

    const value = useMemo(
        (): IAccumulatorContext => ({
            accumulators,
            getAccumulators,
            memberAccumulators,
        }),
        [accumulators, getAccumulators, getMemberAccumulators, memberAccumulators]
    );

    return <AccumulatorContext.Provider value={value} {...props} />;
};

export const useAccumulators = () => {
    const context = useContext(AccumulatorContext);
    if (context === undefined) {
        throw new Error("useAccumulators must be used within a AccumulatorProvider");
    }
    return context;
};
