import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CoverageFormModel } from "./CoverageFormModel";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import { PlanCodeDescription, RateLevel } from "../../models/Enrollment";
import { CoverageForm } from "./CoverageForm";
import React from "react";

type CoverageFormState = {
    rateLevel: RateLevel;
    plans: PlanCodeDescription[];
    values: CoverageFormModel;
    valid: boolean;
    disabled: boolean;
};

export const Coverage = () => {
    const {
        activeStep,
        setActiveStep,
        backClicked,
        nextClicked,
        validateCoverage,
        newMember,
        setNewMember,
        isUpdating,
        setUpdatedMember,
        coverageFormData: { planCodeDescriptions, rateLevels },
        dbMember,
    } = useMemberEnrollments();

    const [formState, setFormState] = useState<Record<string, CoverageFormState>>(() => {
        if (!planCodeDescriptions.length || !rateLevels.length) return {};
        const temp: Record<string, CoverageFormState> = {};

        rateLevels.forEach((r) => {
            let values: CoverageFormModel = {
                plan: "",
                coverage: "",
                effectiveDate: null,
            };

            if (newMember?.coverage) {
                const existingValues = newMember.coverage[r.productType];
                if (existingValues) {
                    values = existingValues;
                }
            }

            temp[r.productType] = {
                rateLevel: r,
                plans: planCodeDescriptions.filter((p) => p.productType === r.productType),
                values,
                valid: false,
                disabled: !values.plan,
            };
        });

        return temp;
    });

    const formStateEntries = useMemo(() => {
        return Object.entries(formState);
    }, [formState]);

    const handleUpdatedFields = useCallback(
        (key: string, values: CoverageFormModel) => {
            const updatedFields: Partial<CoverageFormModel> = {};
            const coverageInDbMember = dbMember?.coverage?.[key];
            if (coverageInDbMember) {
                Object.keys(values).forEach((key) => {
                    if (key === "effectiveDate" || key === "terminationDate") {
                        if (values[key]?.toDateString() !== coverageInDbMember[key]?.toDateString()) {
                            updatedFields[key] = values[key];
                        }
                    } else {
                        if (values[key] !== coverageInDbMember[key]) {
                            updatedFields[key] = values[key];
                        }
                    }
                });
            }
            setUpdatedMember((prev) => ({
                ...prev,
                coverage: {
                    ...prev?.coverage,
                    [key]: updatedFields,
                },
            }));
        },
        [dbMember?.coverage, setUpdatedMember]
    );

    const handleValidForm = useCallback(
        (key: string, values: CoverageFormModel) => {
            if (isUpdating) {
                handleUpdatedFields(key, values);
            }

            // update valid and values in formState
            setFormState((prev) => {
                const update = {
                    [key]: {
                        ...prev[key],
                        valid: true,
                        values,
                    },
                };
                return {
                    ...prev,
                    ...update,
                };
            });
            // update member model with coverage
            setNewMember((prev) => {
                if (!prev) return prev;
                return {
                    ...prev,
                    coverage: {
                        ...prev.coverage,
                        [key]: values,
                    },
                };
            });
        },
        [handleUpdatedFields, isUpdating, setNewMember]
    );

    const handleDisableForm = useCallback((key: string) => {
        let formWasDisabled = false;
        setFormState((prev) => {
            formWasDisabled = !prev[key].disabled;
            const update = {
                [key]: {
                    ...prev[key],
                    disabled: !prev[key].disabled,
                },
            };
            return {
                ...prev,
                ...update,
            };
        });
        setNewMember((prev) => {
            if (!prev || !prev.coverage || !formWasDisabled) return prev;
            const temp: Record<string, CoverageFormModel> = {};
            // return all coverage entries except this one
            for (const entry of Object.entries(prev.coverage)) {
                if (entry[0] === key) {
                    continue;
                }
                temp[entry[0]] = entry[1];
            }
            return {
                ...prev,
                coverage: temp,
            };
        });
    }, []);

    useEffect(() => {
        if (
            !backClicked &&
            nextClicked &&
            activeStep === 2 &&
            validateCoverage &&
            !formStateEntries.filter(([_, { valid, disabled }]) => !valid && !disabled).length
        ) {
            setActiveStep((prev) => prev + 1);
        }
    }, [formStateEntries, backClicked, nextClicked, activeStep, validateCoverage, setActiveStep]);

    return (
        <>
            <Row>
                {formStateEntries.map(([key, { plans, rateLevel, values, disabled }], i) => (
                    <Col key={`form-${key}-${i}`} xl={6} md={12} className="mb-2">
                        <CoverageForm
                            title={key}
                            plans={plans}
                            rateLevel={rateLevel}
                            initialValues={values}
                            shouldSubmit={validateCoverage}
                            onSubmit={handleValidForm}
                            disabled={disabled}
                            onDisable={handleDisableForm}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
