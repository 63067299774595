import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { AppState } from "../state/AppState";
import { getUserRoutes } from "../helper-functions/getUserRoutes";
import { getUserRedirects } from "../helper-functions/getUserRedirects";
import { UserTypes } from "../models/UserTypes";
import { User } from "../models/User";
import React from "react";

const useAppRoutes = (userType: UserTypes, loggedInUser: User | undefined) => {
    const { decodedAccessToken, isAuthenticated } = useSelector((state: AppState) => state.authenticationState);

    const isAuthenticatedUser = isAuthenticated && decodedAccessToken?.tenant_name;

    const renderedRoutes = isAuthenticatedUser
        ? getUserRoutes(userType, loggedInUser)
              .filter((route) => route.condition)
              .map((route) => (
                  <Route key={route.path} path={route.path} exact>
                      {route.component && <route.component />}
                  </Route>
              ))
        : null;

    const renderedRedirects = isAuthenticatedUser
        ? getUserRedirects(userType, loggedInUser)
              .filter((redirect) => redirect.condition)
              .map((redirect) => <Redirect key={redirect.to} from={redirect.from} to={redirect.to} />)
        : null;

    return { renderedRoutes, renderedRedirects };
};

export default useAppRoutes;
