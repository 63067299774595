import { Col, Row } from "react-bootstrap";
import dentanet from "../../../images/id-cards/DentaNet.png";
import dentemax from "../../../images/id-cards/dentemax.png";
import visanet from "../../../images/id-cards/VisaNet.png";
import { getFormattedDateFromDbValue } from "../../../helper-functions/getFormattedDateFromDbValue";
import { cardHeight, ProductCategories, CardTemplateProps, getFullName } from "../id-card-helpers";
import { canopyLogo } from "../id-card-logos";

export default function CanopyIdCard({ memberBenefits }: CardTemplateProps) {
    const dentalCoverage = memberBenefits.find((d) => d.productCategory === ProductCategories.DENTAL);
    const visionCoverage = memberBenefits.find((d) => d.productCategory === ProductCategories.VISION);
    const administeredBy = memberBenefits[0]?.groupNumber.trim().endsWith("5000")
        ? "JAD Administrative Services"
        : "Southland Benefit, LLC";
    return (
        <div className="id-card">
            <div className="front" style={{ fontSize: "14px" }}>
                <Row>
                    <Col className="text-center">{canopyLogo}</Col>
                </Row>
                <Row>
                    <Col className="text-left">
                        <strong>Primary Insured Name</strong>
                        <br />
                        {getFullName(memberBenefits[0])}
                        <br />
                        <strong>Group Name</strong>
                        <br />
                        {memberBenefits[0].groupName}
                        <br />
                        <strong>Type of Coverage</strong>
                        <br />
                        {!!dentalCoverage && (
                            <>
                                Dental
                                <br />
                            </>
                        )}
                        {!!visionCoverage && <>Vision</>}
                    </Col>
                    <Col>
                        <strong>Contract Number</strong>
                        <br />
                        {memberBenefits[0]?.participantIDNumber || ""}
                        <br />
                        <strong>Group Number</strong>
                        <br />
                        {memberBenefits[0]?.groupNumber || ""}
                        <br />
                        <strong>Effective Date</strong>
                        <br />
                        {!!dentalCoverage && (
                            <>
                                {getFormattedDateFromDbValue(dentalCoverage.memberProductEffectiveDate)}
                                <br />
                            </>
                        )}
                        {!!visionCoverage && (
                            <>{getFormattedDateFromDbValue(visionCoverage.memberProductEffectiveDate)}</>
                        )}
                    </Col>
                </Row>
            </div>
            <div className="back" style={{ fontSize: "10px" }}>
                <p>
                    Plan Administered by:
                    <br />
                    <strong>
                        Canopy Insurance
                        <br />
                        P.O. Box 3020
                        <br />
                        Tuscaloosa, Alabama 35403
                    </strong>
                </p>
                <p>
                    Claims for services provided to the Primary Insured named on the front side of this card, or any
                    eligible dependents, should be sent directly to Canopy Insurance. The Primary Insured named on the
                    front of this card and any dependents named on an application are entitled to benefits as provided
                    in the contract.
                </p>
                <p>
                    For information about your benefits or eligibility information, visit www.canopyinsurancecorp.com/
                    or call 205-451.0444.
                </p>
                <p>
                    Administered by {administeredBy}. Underwritten by Canopy Insurance.
                    <span className="d-flex justify-content-between">
                        {dentalCoverage ? (
                            <>
                                <img
                                    src={dentanet}
                                    alt="DentaNet logo"
                                    style={{
                                        maxHeight: cardHeight * 0.15,
                                        height: "auto",
                                        width: "auto",
                                    }}
                                />
                                <img
                                    src={dentemax}
                                    alt="DenteMax logo"
                                    style={{
                                        maxHeight: cardHeight * 0.15,
                                        height: "auto",
                                        width: "auto",
                                    }}
                                />
                            </>
                        ) : (
                            <span />
                        )}
                        {visionCoverage ? (
                            <img
                                src={visanet}
                                alt="VisaNet logo"
                                style={{
                                    maxHeight: cardHeight * 0.15,
                                    height: "auto",
                                    width: "auto",
                                }}
                            />
                        ) : (
                            <span />
                        )}
                    </span>
                </p>
            </div>
        </div>
    );
}
