export enum UserActionType {
    USERS_FETCHED = "USERS_FETCHED",
    INACTIVE_USERS_FETCHED = "INACTIVE_USERS_FETCHED",
    USER_CREATED = "USER_CREATED",
    USER_DELETED = "USER_DELETED",
    USER_EDITED = "USER_EDITED",
    USER_RESTORED = "USER_RESTORED",
    SELECT_USER = "SELECT_USER",
    CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER",
    SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER",
}
