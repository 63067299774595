import { FinosecSystemStatistic, FinosecSystemStatisticReports } from "../../models/FinosecSystemStatistic";

export const FINOSEC_SYSTEM_STATISTIC_FETCHED = "FINOSEC_SYSTEM_STATISTIC_FETCHED";
export const FINOSEC_SYSTEM_STATISTIC_REPORTS_FETCHED = "FINOSEC_SYSTEM_STATISTIC_REPORTS_FETCHED";

export interface FinosecSystemStatisticFetchedAction {
    type: typeof FINOSEC_SYSTEM_STATISTIC_FETCHED;
    finosecSystemStatistic: FinosecSystemStatistic;
}

export interface FinosecSystemStatisticReportsFetchedAction {
    type: typeof FINOSEC_SYSTEM_STATISTIC_REPORTS_FETCHED;
    finosecSystemStatisticReports: FinosecSystemStatisticReports[];
}

export type FinosecSystemStatisticAction = FinosecSystemStatisticFetchedAction;
export type FinosecSystemStatisticReportsAction = FinosecSystemStatisticReportsFetchedAction;
