import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { VerticallyCenteredModalProps } from "./VerticallyCenteredModalProps";

export default function VerticallyCenteredModal(props: VerticallyCenteredModalProps) {
    let actionButtonStyle = {};
    if (props.actionButtonsVertical) {
        actionButtonStyle = {
            width: "100%",
        };
    }

    const colorMap: any = {
        danger: {
            background: "#f8d7da",
            buttonHeader: "red",
            line: "red",
        },
    };
    const type = props.type || "defualt";
    const colorScheme = colorMap[type];

    return (
        <Modal
            id={props.id}
            size={props.size || "xl"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            backdrop={"static"}
        >
            <Modal.Header style={colorScheme ? { backgroundColor: colorScheme.buttonHeader } : {}}>
                <Modal.Title id="contained-modal-title-vcenter modal-title">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className={!props.dynamicHeight ? "fixed-height-body" : ""}
                style={colorScheme ? { backgroundColor: colorScheme.background } : {}}
            >
                {props.children}
            </Modal.Body>
            <Modal.Footer
                style={
                    colorScheme
                        ? { borderTop: `1px solid ${colorScheme.line}`, backgroundColor: colorScheme.background }
                        : {}
                }
            >
                {/* <ButtonToolbar className="w-100"> */}
                {!!props.showDeleteButton && props.showDeleteButton && (
                    <Button
                        variant={"danger"}
                        size={"lg"}
                        onClick={props.onDeleteButtonClick}
                        style={actionButtonStyle}
                        // style={{ backgroundColor: "#6b7172", color: "white" }}
                    >
                        {props.deleteButtonText}
                    </Button>
                )}
                {!!props.showRestoreButton && props.showRestoreButton && (
                    <Button
                        variant={"primary"}
                        size={"lg"}
                        onClick={props.onRestoreButtonClick}
                        style={actionButtonStyle}
                    >
                        {props.restoreButtonText}
                    </Button>
                )}
                {props.okButtonComponent}
                {!!props.showSaveButton && props.showSaveButton && !props.okButtonComponent && (
                    <Button
                        size={"lg"}
                        onClick={props.onOkButtonClick}
                        disabled={!!props.disableUpdate ? props.disableUpdate : false}
                        className="finosec-button-info"
                        style={{
                            ...actionButtonStyle,
                            backgroundColor: colorScheme ? colorScheme.buttonHeader : "#204ea0",
                        }}
                    >
                        {props.okButtonText}
                    </Button>
                )}
                {/* &nbsp; */}
                <Button
                    variant={"dark"}
                    size={"lg"}
                    onClick={props.onCloseButtonClick}
                    style={{ backgroundColor: "#6b7172", ...actionButtonStyle }}
                >
                    {props.closeButtonText}
                </Button>
                {/* </ButtonToolbar> */}
            </Modal.Footer>
        </Modal>
    );
}
