import { PersonalInformationFormModel } from "./PersonalInformationFormModel";
import { Form as BootstrapForm, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useMemberEnrollments } from "../../contexts/memberEnrollments";
import { states } from "../../models/States";
import { useEffect, useMemo, useRef } from "react";
import React from "react";
import { Form, Formik, FormikProps } from "formik";
import KeysysInput from "../form/input/KeysysInput";
import KeysysSelect from "../form/select/KeysysSelect";
import KeysysDatetime from "../form/datetime/KeysysDatetime";
import { SSNField } from "../form/input/SSNInput";

export default function PersonalInformation() {
    const {
        newMember,
        nextClicked,
        setNextClicked,
        activeStep,
        setActiveStep,
        setNewMember,
        backClicked,
        setValidateDependents,
        isUpdating,
        setUpdatedMember,
        dbMember,
    } = useMemberEnrollments();

    const initPersonalInfo = {
        firstName: "",
        lastName: "",
        gender: "",
        maritalStatus: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        emailAddress: "",
        dob: undefined,
        smoker: "",
        terminationDate: undefined,
        effectiveDate: undefined,
        location: "",
        governmentIdentifier: "",
    };

    const personalInfo = newMember?.personalInformation ?? initPersonalInfo;

    const ref = useRef<FormikProps<any>>(null);
    const genderOptions = useMemo(() => {
        return [
            { value: "Female", label: "Female" },
            { value: "Male", label: "Male" },
        ];
    }, []);
    const yesNoOptions = useMemo(() => {
        return [
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
        ];
    }, []);
    const maritalStatusOptions = useMemo(() => {
        return [
            { value: "Single", label: "Single" },
            { value: "Married", label: "Married" },
            { value: "Widow/Widower", label: "Widow/Widower" },
            { value: "Divorced", label: "Divorced" },
            { value: "Legally Separated", label: "Legally Separated" },
        ];
    }, []);

    const phoneRegExp = useMemo(() => {
        return /^\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
    }, []);
    const zipRegExp = useMemo(() => {
        return /^\d{5}(?:[-\s]\d{4})?$/;
    }, []);
    const ssnRegExp = useMemo(() => {
        return /^\d{3}-?\d{2}-?\d{4}$/;
    }, []);

    const personalInfoSchema = Yup.object<PersonalInformationFormModel>().shape({
        firstName: Yup.string().required("First Name is Required"),
        lastName: Yup.string().required("Last Name is Required"),
        dob: Yup.date().nullable().required("Date of Birth is Required"),
        effectiveDate: Yup.date().nullable().required("Effective Date is Required"),
        emailAddress: Yup.string().nullable().email("Invalid email format"),
        phone: Yup.string().nullable().matches(phoneRegExp, "Invalid Phone Number"),
        streetAddress: Yup.string().required("Street Address is Required"),
        city: Yup.string().required("City is Required"),
        state: Yup.string().required("State is Required"),
        zip: Yup.string().required("Zip is Required").matches(zipRegExp, "Invalid Zip Code"),
        gender: Yup.string().required("Gender is Required"),
        maritalStatus: Yup.string().required("Marital Status is Required"),
        governmentIdentifier: Yup.string().required("SSN is required").matches(ssnRegExp, "Invalid SSN format"),
    });

    const handleValidation = async (values: PersonalInformationFormModel) => {
        if (ref.current?.isValid) {
            if (isUpdating) {
                const updatedFields: Partial<PersonalInformationFormModel> = {};
                Object.keys(values).forEach((key) => {
                    if (key === "dob" || key === "effectiveDate" || key === "terminationDate") {
                        if (values[key]?.toDateString() !== dbMember?.personalInformation?.[key]?.toDateString()) {
                            updatedFields[key] = values[key];
                        }
                    } else {
                        if (values[key] !== dbMember?.personalInformation?.[key]) {
                            updatedFields[key] = values[key];
                        }
                    }
                });
                setUpdatedMember((prev) => ({
                    ...prev,
                    personalInformation: updatedFields,
                }));
            }
            setNewMember((prev) => {
                if (!prev) return prev;
                const newValue = {
                    ...prev,
                    personalInformation: values,
                };
                sessionStorage.setItem("newMember", JSON.stringify(newValue));
                return newValue;
            });

            if (activeStep === 0) {
                setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
            }
            setValidateDependents(false);
        }
    };

    useEffect(() => {
        const triggerSubmit = async () => {
            await ref.current?.submitForm();
            if (!ref.current?.isValid) {
                setNextClicked(false);
            }
        };
        if (!backClicked && nextClicked && activeStep === 0) {
            triggerSubmit();
        }
    }, [
        activeStep,
        backClicked,
        nextClicked,
        personalInfo,
        setActiveStep,
        setNewMember,
        setValidateDependents,
        ref,
        setNextClicked,
    ]);

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <BootstrapForm.Label>Group</BootstrapForm.Label>
                    <p>{newMember?.group}</p>
                </Col>
                <Col>
                    <BootstrapForm.Label>Account</BootstrapForm.Label>
                    <p>{newMember?.account}</p>
                </Col>
            </Row>
            <Formik
                innerRef={ref}
                initialValues={personalInfo}
                validationSchema={personalInfoSchema}
                onSubmit={(values) => handleValidation(values)}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<PersonalInformationFormModel>) => {
                    return (
                        <Form id="Personal-info-form">
                            <Row className="mb-3">
                                <Col>
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"firstName"}
                                        placeholder={""}
                                        fieldName={"firstName"}
                                        label={"First Name"}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"lastName"}
                                        placeholder={""}
                                        fieldName={"lastName"}
                                        label={"Last Name"}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <SSNField
                                        formProps={formProps}
                                        id={"governmentIdentifier"}
                                        placeholder={""}
                                        fieldName={"governmentIdentifier"}
                                        label={"Social Security Number"}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <KeysysDatetime
                                        formProps={formProps}
                                        id="dob"
                                        placeholder="Select a date"
                                        fieldName="dob"
                                        label="Date of Birth"
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <KeysysSelect
                                        formProps={formProps}
                                        id="gender"
                                        placeholder="Select"
                                        fieldName="gender"
                                        label="Gender"
                                        options={genderOptions}
                                        multiple={false}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <KeysysSelect
                                        formProps={formProps}
                                        id="maritalStatus"
                                        placeholder="Select"
                                        fieldName="maritalStatus"
                                        label="Marital Status"
                                        options={maritalStatusOptions}
                                        multiple={false}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="6">
                                    <KeysysSelect
                                        formProps={formProps}
                                        id="smoker"
                                        placeholder="Select"
                                        fieldName="smoker"
                                        label="Smoker"
                                        options={yesNoOptions}
                                        multiple={false}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"streetAddress"}
                                        placeholder={""}
                                        fieldName={"streetAddress"}
                                        label={"Street Address"}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="6">
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"city"}
                                        placeholder={""}
                                        fieldName={"city"}
                                        label={"City"}
                                        required
                                    />
                                </Col>
                                <Col xs="3">
                                    <KeysysSelect
                                        formProps={formProps}
                                        id="state"
                                        placeholder="Select"
                                        fieldName="state"
                                        label="State"
                                        options={states}
                                        multiple={false}
                                        required
                                    />
                                </Col>
                                <Col xs="3">
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"zip"}
                                        placeholder={""}
                                        fieldName={"zip"}
                                        label={"Zip"}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"phone"}
                                        placeholder={""}
                                        fieldName={"phone"}
                                        label={"Phone"}
                                    />
                                </Col>
                                <Col>
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"emailAddress"}
                                        placeholder={""}
                                        fieldName={"emailAddress"}
                                        label={"Email Address"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="6">
                                    <KeysysInput
                                        formProps={formProps}
                                        id={"location"}
                                        placeholder={""}
                                        fieldName={"location"}
                                        label={"Location"}
                                    />
                                </Col>
                                <Col xs="3">
                                    <KeysysDatetime
                                        formProps={formProps}
                                        id="effectiveDate"
                                        placeholder="Select a date"
                                        fieldName="effectiveDate"
                                        label="Effective Date"
                                        required
                                    />
                                </Col>
                                <Col xs="3">
                                    <KeysysDatetime
                                        formProps={formProps}
                                        id="terminationDate"
                                        placeholder="Select a date"
                                        fieldName="terminationDate"
                                        label="Termination Date"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}
