import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ICustomerImportService } from "./ICustomerImportService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { CustomerImport } from "../../models/CustomerImport";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import {
    ADD_CUSTOMER_IMPORT_SUCCESS,
    DEACTIVATE_CUSTOMER_IMPORT_SUCCESS,
    EDIT_CUSTOMER_IMPORT_SUCCESS,
} from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";

@injectable()
export class CustomerImportService implements ICustomerImportService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createCustomerImport(customerImport: CustomerImport): Promise<void | CustomerImport> {
        return this.httpClient
            .post<CustomerImport>("/customer-import", customerImport)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_CUSTOMER_IMPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerImport "${t.id}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a customerImport. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editCustomerImport(
        customerImport: CustomerImport,
        newCustomerImport: CustomerImport
    ): Promise<void | CustomerImport> {
        const operations = createPatch(customerImport, newCustomerImport);
        return this.httpClient
            .patch<CustomerImport>(`/customer-import/${customerImport.id}`, operations)
            .then((patchedCustomerImport) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_CUSTOMER_IMPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Customer Import "${patchedCustomerImport.id}" completed successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedCustomerImport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing customerImport "${customerImport.id}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteCustomerImport(customerImport: CustomerImport): Promise<void | CustomerImport> {
        return this.httpClient
            .delete(`/customer-import/${customerImport.id}`)
            .then(() => {
                const returnCustomerImport = { ...customerImport, isActive: !customerImport.isActive };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_CUSTOMER_IMPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerImport "${customerImport.id}" ${
                        customerImport.isActive ? " deactivated" : " reactivated"
                    } successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnCustomerImport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the customerImport. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImports(): Promise<void | CustomerImport[]> {
        return this.httpClient
            .get<CustomerImport[]>(`/customer-import`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customerImports. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportById(id: number): Promise<void | CustomerImport> {
        return this.httpClient
            .get<CustomerImport>(`/customer-import/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customerImport. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportsByTenantId(tenantId: number): Promise<void | CustomerImport[]> {
        return this.httpClient
            .get<CustomerImport[]>(`/customer-import/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customerImports Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
