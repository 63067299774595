import { useCallback, useState } from "react";

export const useStorage = <T>(key: string, value: T, useSession = false) => {
    const getStorage = useCallback(() => {
        return useSession ? window.sessionStorage : window.localStorage;
    }, [useSession]);

    const storageAvailable = useCallback(() => {
        let storage: Storage | null = null;
        try {
            storage = getStorage();
            const x = "__storage_test__";
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === "QuotaExceededError" ||
                    // Firefox
                    e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage &&
                storage.length !== 0
            );
        }
    }, [getStorage]);

    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === "undefined" || !storageAvailable()) return value;

        try {
            const item = getStorage().getItem(key);
            return item ? JSON.parse(item) : value;
        } catch (error) {
            console.error(error);
            return value;
        }
    });

    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                const valueToStore = value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                if (typeof window !== "undefined") {
                    if (valueToStore === undefined) {
                        getStorage().removeItem(key);
                    } else {
                        getStorage().setItem(key, JSON.stringify(valueToStore));
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        [storedValue, getStorage]
    );

    return [storedValue, setValue] as const;
};
