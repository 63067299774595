import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getDateStringForFileName } from "../../helper-functions/getDateStringForFileName";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { useFetch } from "../../hooks/useFetch";
import { Claim } from "../../models/Claim";
import {
    MemberClaimsRequest,
    SearchClaimsByClaimNumberRequest,
    SearchClaimsByIdRequest,
    SearchClaimsByNameRequest,
} from "../../models/ClaimSearch";
import { AppState } from "../../state/AppState";

interface IClaimContext {
    claims: Claim[];
    memberId: string | null;
    setMemberId: (memberId: string | null) => void;
    isGettingPdf: boolean;
    hasPdfError: boolean;
    setHasPdfError: any;
    isGettingClaims: boolean;
    claimsFilter: string;
    setClaimsFilter: any;
    getEobPdf: (claim: Claim) => void;
    searchByName: (request: SearchClaimsByNameRequest) => void;
    searchByMemberId: (request: SearchClaimsByIdRequest) => void;
    searchByClaimNumber: (request: SearchClaimsByClaimNumberRequest) => void;
    getMemberClaims: (request: MemberClaimsRequest) => void;
}

const ClaimContext = createContext<IClaimContext>({} as IClaimContext);

export const ClaimsProvider = ({ children }: any) => {
    const { postUnique, getFileWithPostMethod, get } = useFetch();
    const [claims, setClaims] = useState<Claim[]>([]);
    const [memberId, setMemberId] = useState<string | null>(null);
    const [claimsFilter, setClaimsFilter] = useState<string>("");
    const [isGettingPdf, setIsGettingPdf] = useState<boolean>(false);
    const [isGettingClaims, setIsGettingClaims] = useState<boolean>(false);
    const [hasPdfError, setHasPdfError] = useState<boolean>(false);

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant } = useCurrentTenant(decodedAccessToken.tenant_id);

    const getPaymentDate = (data: Claim): Date => {
        return new Date(data.paymentPaidDateYear, data.paymentPaidDateMonth - 1, data.paymentPaidDateDay);
    };

    const getEobPdf = useCallback(
        async (claim: Claim) => {
            setHasPdfError(false);
            setIsGettingPdf(true);
            try {
                await getFileWithPostMethod(
                    `providers/eob-pdf`,
                    {
                        participantIDNumber: claim.participantIDNumber,
                        claimNumber: claim.claimNumber,
                        paymentDate: getPaymentDate(claim).toLocaleDateString(),
                        taxId: currentTenant?.taxId,
                    },
                    `EOB ${claim.claimNumber} ${getDateStringForFileName(getPaymentDate(claim))}`,
                    "application/pdf",
                    true
                );
            } catch {
                setHasPdfError(true);
            }
            setIsGettingPdf(false);
        },
        [getFileWithPostMethod, currentTenant]
    );

    const getMemberClaims = useCallback(
        async (request: MemberClaimsRequest) => {
            setIsGettingClaims(true);
            setClaims([]);
            let apiPath = `members/claims?participantIdNumber=${request.participantIdNumber}`;
            if (request.startDate) {
                apiPath += `&startDate=${request.startDate}`;
            }
            if (request.endDate) {
                apiPath += `&endDate=${request.endDate}`;
            }
            const claims = await get<Claim[]>(apiPath);
            if (claims) {
                setClaims(claims);
            }
            setIsGettingClaims(false);
        },
        [get]
    );

    const searchByName = useCallback(
        async (request: SearchClaimsByNameRequest) => {
            setIsGettingClaims(true);
            setClaims([]);
            const claims = await postUnique<SearchClaimsByNameRequest, Claim[]>("providers/claims", request);
            if (claims) {
                setClaims(claims);
            }
            setIsGettingClaims(false);
        },
        [postUnique]
    );

    const searchByMemberId = useCallback(
        async (request: SearchClaimsByIdRequest) => {
            setIsGettingClaims(true);
            setClaims([]);
            const claims = await postUnique<SearchClaimsByIdRequest, Claim[]>("providers/claims", request);
            if (claims) {
                setClaims(claims);
            }
            setIsGettingClaims(false);
        },
        [postUnique]
    );

    const searchByClaimNumber = useCallback(
        async (request: SearchClaimsByClaimNumberRequest) => {
            setIsGettingClaims(true);
            setClaims([]);
            const claims = await postUnique<SearchClaimsByClaimNumberRequest, Claim[]>("providers/claims", request);
            if (claims) {
                setClaims(claims);
            }
            setIsGettingClaims(false);
        },
        [postUnique]
    );

    const value = useMemo(
        () => ({
            claims,
            isGettingPdf,
            hasPdfError,
            setHasPdfError,
            memberId,
            setMemberId,
            isGettingClaims,
            claimsFilter,
            setClaimsFilter,
            getEobPdf,
            searchByName,
            searchByMemberId,
            searchByClaimNumber,
            getMemberClaims,
        }),
        [
            claims,
            isGettingPdf,
            memberId,
            setMemberId,
            hasPdfError,
            setHasPdfError,
            isGettingClaims,
            claimsFilter,
            setClaimsFilter,
            getEobPdf,
            searchByName,
            searchByMemberId,
            searchByClaimNumber,
            getMemberClaims,
        ]
    );

    return <ClaimContext.Provider value={value}>{children}</ClaimContext.Provider>;
};

export const useClaims = () => {
    const context = useContext(ClaimContext);
    if (context === undefined) {
        throw new Error("useClaims must be used within a ClaimProvider");
    }
    return context;
};
