import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ICustomerDocumentService } from "./ICustomerDocumentService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { CustomerDocument, CustomerDocumentStatistics } from "../../models/CustomerDocument";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_REPORT_SUCCESS, DEACTIVATE_REPORT_SUCCESS, EDIT_REPORT_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { AppState } from "../../state/AppState";
import { Token } from "../../models/Token";
import { IProgramReference } from "../../models/ProgramReference";

@injectable()
export class CustomerDocumentService implements ICustomerDocumentService {
    private token: Token = {
        access_token: "",
        refresh_token: "",
    };
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {
        this.store.subscribe(() => {
            const state = this.store.getState() as AppState;
            this.token.access_token = state.authenticationState.accessToken;
            this.token.refresh_token = state.authenticationState.refreshToken;
        });
    }

    createCustomerDocument(customerDocument: FormData): Promise<void | CustomerDocument> {
        return axios
            .post("/customer-document", customerDocument, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${this.token.access_token}`,
                },
                baseURL: API_BASE_URL,
            })
            .then((document: any) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerDocument "${document?.data?.id}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return document?.data;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a customerDocument. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editCustomerDocument(
        customerDocument: CustomerDocument,
        newCustomerDocument: CustomerDocument
    ): Promise<void | CustomerDocument> {
        const operations = createPatch(customerDocument, newCustomerDocument);
        return this.httpClient
            .patch<CustomerDocument>(`/customer-document/${customerDocument.id}`, operations)
            .then((patchedCustomerDocument) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedCustomerDocument.documentDescription}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedCustomerDocument;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing customerDocument "${customerDocument.documentDescription}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteCustomerDocument(customerDocument: CustomerDocument): Promise<void | CustomerDocument> {
        return this.httpClient
            .delete(`/customer-document/${customerDocument.id}`)
            .then(() => {
                const returnCustomerDocument = { ...customerDocument, isActive: !customerDocument.isActive };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerDocument "${customerDocument.documentDescription}" ${
                        customerDocument.isActive ? " deactivated" : " reactivated"
                    } successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnCustomerDocument;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the customerDocument. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerDocuments(): Promise<void | CustomerDocument[]> {
        return this.httpClient
            .get<CustomerDocument[]>(`/customer-document`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customerDocuments. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerDocumentById(id: number): Promise<void | CustomerDocument> {
        return this.httpClient
            .get<CustomerDocument>(`/customer-document/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customerDocument. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getProgramReferences(): Promise<void | IProgramReference[]> {
        return this.httpClient
            .get<IProgramReference[]>("/program-reference")
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving program references. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    downloadCustomerDocument(fileId: string, fileName: string): Promise<void> {
        return axios
            .get(`/customer-document/get-file?fileId=${fileId}`, {
                headers: {
                    Authorization: `Bearer ${this.token.access_token}`,
                },
                baseURL: API_BASE_URL,
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while downloading the file. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
