import { CustomerReport } from "../../models/CustomerReport";
import { ICustomerReportReview } from "../../models/CustomerReportReview";

export const CUSTOMER_REPORTS_FETCHED = "CUSTOMER_REPORTS_FETCHED";
export const EDIT_CUSTOMER_REPORT_SUCCESS = "EDIT_CUSTOMER_REPORT_SUCCESS";

export const CUSTOMER_REPORTS_REVIEW_FETCHED = "CUSTOMER_REPORT_REVIEWS_FETCHED";
export const ALL_CUSTOMER_REPORTS_REVIEW_FETCHED = "ALL_CUSTOMER_REPORT_REVIEWS_FETCHED";
export const ADD_CUSTOMER_REPORT_REVIEW_SUCCESS = "ADD_CUSTOMER_REPORT_REVIEW_SUCCESS";
export const EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS = "EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS";

export interface CustomerReportsFetchedAction {
    type: typeof CUSTOMER_REPORTS_FETCHED;
    customerReports: CustomerReport[];
}

export interface EditCustomerReportAction {
    type: typeof EDIT_CUSTOMER_REPORT_SUCCESS;
    customerReport: CustomerReport;
    newCustomerReport: CustomerReport;
}

export interface CustomerReportReviewsFetchedAction {
    type: typeof CUSTOMER_REPORTS_REVIEW_FETCHED;
    customerReportReviews: ICustomerReportReview[];
}

export interface AllCustomerReportReviewsFetchedAction {
    type: typeof ALL_CUSTOMER_REPORTS_REVIEW_FETCHED;
    customerReportReviews: ICustomerReportReview[];
}

export interface EditCustomerReportReviewAction {
    type: typeof EDIT_CUSTOMER_REPORT_REVIEW_SUCCESS;
    customerReportReview: ICustomerReportReview;
    newCustomerReportReview: ICustomerReportReview;
}

export interface AddCustomerReportReviewAction {
    type: typeof ADD_CUSTOMER_REPORT_REVIEW_SUCCESS;
    customerReportReview: ICustomerReportReview;
}

export type CustomerReportActions =
    | CustomerReportsFetchedAction
    | CustomerReportReviewsFetchedAction
    | AllCustomerReportReviewsFetchedAction
    | EditCustomerReportReviewAction
    | AddCustomerReportReviewAction
    | EditCustomerReportAction;
